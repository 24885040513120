import React from 'react';
import SubHeader from "../../Header/SubHeader";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {allOrdersSelector} from "../../../state/selectors/globalSelectors";
import {Card, Col, Row, Form} from "react-bootstrap";
import {mainCardBodyStyle, marketsCardHeaderStyle, marketsCardStyle} from "../../../styles/contentStyles";
import {activeMarketAtom, showHistoryOrdersAtom, viewOrdersCountAtom} from "../../../state/atoms/contentAtoms";
import {ALL_ORDERS, BORROW, DEFAULT_VIEW_ORDERS_COUNT, ROLE, STATUS, SUPPLY} from "../../../constants";
import StandardButton from "../../global/StandardButton";
import OrdersMarket from "./OrdersMarket";

const OrdersBook = () => {
    const [activeMarket, setActiveMarket] = useRecoilState(activeMarketAtom)
    const [showHistoryOrders, setShowHistoryOrders] = useRecoilState(showHistoryOrdersAtom)
    const allOrders = useRecoilValue(allOrdersSelector)
    const setViewOrdersCount = useSetRecoilState(viewOrdersCountAtom)

    const filterOrders = () => {
        let filteredOrders = []
        switch (activeMarket) {
            case ALL_ORDERS:
                filteredOrders = allOrders
                break
            case SUPPLY:
                filteredOrders = allOrders.filter(order => order.role === ROLE.SUPPLY)
                break
            case BORROW:
                filteredOrders = allOrders.filter(order => order.role === ROLE.BORROW)
                break
            default:
                console.log("Filter Default")
                break
        }
        if (showHistoryOrders){
            return filteredOrders
        }else {
            return filteredOrders.filter(order => order.status === STATUS.OPEN)
        }

    }
    const getMarketTexts = () => {
        switch (activeMarket) {
            case SUPPLY:
                return {
                    market: "Supply Market",
                    description: "The escrow loan has already been added to the protocol."
                }
            case BORROW:
                return {
                    market: "Borrow Market",
                    description: "The escrow collateral has already been added  to the protocol."
                }
            case ALL_ORDERS:
                return {
                    market: "Market",
                    description: "All Orders."
                }
            default:
                break
        }
    }

    const renderDesktopHeader = () => {
      return(
          <div>
              <Row>
                  <Col xl={6} lg={5} md={7} sm={6} xs={12}>
                      <Row>
                          <Col>
                              {getMarketTexts().market}
                          </Col>
                      </Row>
                      <Row>
                          <Col style={{fontSize: 12, color: "#9597a1", paddingTop: 10}}>
                              {getMarketTexts().description}
                          </Col>
                      </Row>
                  </Col>
                  <Col xl={6} lg={7} md={5} sm={6} xs={12}>
                      <Row>
                          <Col>
                              <Row>
                                  <Col lg={9} style={{fontSize: 16, paddingTop: 8, paddingRight: 0}} className="text-end">History</Col>
                                  <Col lg={3} style={{padding: 0, marginTop: 6}}>
                                      <Form>
                                          <Form.Check
                                          reverse
                                          type="checkbox"
                                          id="switch"
                                          checked={showHistoryOrders}
                                          onChange={() => {
                                              setViewOrdersCount(DEFAULT_VIEW_ORDERS_COUNT)
                                              setShowHistoryOrders(!showHistoryOrders)
                                          }}
                                      /></Form>
                                  </Col>
                              </Row>

                          </Col>
                          <Col><StandardButton
                              className="text-truncate"
                              onClick={() => setActiveMarket(SUPPLY)}
                              text="Supply"
                              active={activeMarket === SUPPLY}
                          /></Col>
                          <Col><StandardButton
                              className="text-truncate"
                              onClick={() => setActiveMarket(BORROW)}
                              text="Borrow"
                              active={activeMarket === BORROW}
                          /></Col>
                          <Col><StandardButton
                              className="text-truncate"
                              onClick={() => setActiveMarket(ALL_ORDERS)}
                              text="All"
                              active={activeMarket === ALL_ORDERS}
                          /></Col>
                      </Row>
                  </Col>
              </Row>
          </div>
      )
    }
    const renderMobileHeader = () => {
      return(
          <div>
                <Row>
                    <Col>
                        <Row>
                            <Col style={{fontSize: 17}}>
                                <div className="market-text pt-2">{getMarketTexts().market}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="text-end">
                        <Row>
                            <Col md={10} sm={9} xs={8} style={{fontSize: 16, paddingTop: 8, paddingRight: 0}}>
                                <div className="market-text">History</div></Col>
                            <Col md={2} sm={3} xs={4} style={{paddingLeft: 30}}>
                                <Form><Form.Check
                                    reverse
                                    type="checkbox"
                                    id="switch-m"
                                    checked={showHistoryOrders}
                                    onChange={() => {
                                        setViewOrdersCount(DEFAULT_VIEW_ORDERS_COUNT)
                                        setShowHistoryOrders(!showHistoryOrders)
                                    }}
                                /></Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
              <Row>
                  <Col style={{fontSize: 8, color: "#9597a1", paddingTop: 10}}>
                      {getMarketTexts().description}
                  </Col>
              </Row>
              <br/>
                <Row>
                    <Col >
                        <Row>
                            <Col><StandardButton
                                className="text-truncate"
                                onClick={() => setActiveMarket(SUPPLY)}
                                text="Supply"
                                active={activeMarket === SUPPLY}
                                width={"100%"}
                            /></Col>
                            <Col><StandardButton
                                className="text-truncate"
                                onClick={() => setActiveMarket(BORROW)}
                                text="Borrow"
                                active={activeMarket === BORROW}
                                width={"100%"}
                            /></Col>
                            <div className="market-br"></div>
                            <Col><StandardButton
                                className="text-truncate"
                                onClick={() => setActiveMarket(ALL_ORDERS)}
                                text="All"
                                active={activeMarket === ALL_ORDERS}
                                width={"100%"}
                            /></Col>
                        </Row>
                    </Col>
                </Row>
          </div>
      )
    }

    return (
        <div>
            <SubHeader/>
            <Card style={marketsCardStyle}>
                <Card.Header style={marketsCardHeaderStyle}>
                    <div className="d-none d-lg-block">
                        {renderDesktopHeader()}
                    </div>
                    <div className="d-lg-none">
                        {renderMobileHeader()}
                    </div>
                </Card.Header>
                <Card.Body
                    style={mainCardBodyStyle}
                >
                    <OrdersMarket filterOrders={filterOrders()}/>
                </Card.Body>
            </Card>
        </div>
    );
};

export default OrdersBook;