export const modalHeaderStyle = {
    backgroundColor: "#1f304a",
    borderRadius: "20px",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 20,
    paddingBottom: 20
}
export const modalBodyStyle = {
    border: "1px solid #9597a1",
    borderRadius: 20,
    backgroundColor: "#1f304a"
}