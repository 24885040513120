import React from 'react';
import {useRecoilState} from "recoil";
import {ordersInfoModalAtom} from "../../../state/atoms/contentAtoms";
import {Modal} from "react-bootstrap";
import supplyInfoMobile from "../../../assets/info/arbi/supply-info-mobile.svg"
import borrowInfoMobile from "../../../assets/info/arbi/borrow-info-mobile.svg"
import supplyInfo from "../../../assets/info/arbi/supply-info.png"
import borrowInfo from "../../../assets/info/arbi/borrow-info.png"
import close from "../../../assets/svg/close.svg";

const InfoModal = () => {

    const [showInfoModal, setShowInfoModal] = useRecoilState(ordersInfoModalAtom)
    return (
        <Modal
            style={{paddingTop: 12}}
            variant="dark"
            size="lg"
            show={(!!showInfoModal)}
            onHide={() => setShowInfoModal(false)}
            enforceFocus={false}
        >
            <Modal.Body>
                <div
                    style={{position: "relative"}}
                >
                    {showInfoModal === 1
                        ?
                        <div>
                            <img src={supplyInfo} alt="supplyInfo" width={770} className="d-none d-lg-block"/>
                            <img src={supplyInfoMobile} alt="supplyInfo" style={{width: "100%"}} className="d-lg-none"/>
                        </div>
                        :
                        <div>
                            <img src={borrowInfo} alt="borrowInfo" width={770} className="d-none d-lg-block"/>
                            <img src={borrowInfoMobile} alt="borrowInfo" style={{width: "100%"}} className="d-lg-none"/>
                        </div>
                    }
                    <img src={close} width={25} height={25} alt="close"
                         style={{marginTop: -10, cursor: "pointer", position: "absolute", top: 10, right: 10}}
                         onClick={() => setShowInfoModal(false)}/>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default InfoModal;