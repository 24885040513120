import {BigNumber, utils} from "ethers";
import {makeHumanAmount} from "./utilities";
import {MS_PER_DAY, TWOPAW_DECIMALS} from "./constants";

export class Token {
    address = String
    name = String
    symbol = String
    decimals = Number
    img = String
    price = BigNumber

    constructor(
        address,
        name,
        symbol,
        decimals,
        img,
        price
    ) {
        this.address = address
        this.name = name
        this.symbol = symbol
        this.decimals = decimals
        this.img = img
        this.price = price
    }
    getHumanTokenPrice(){
        if (this.price.eq(BigNumber.from("0"))){
            return undefined
        }
        return makeHumanAmount(utils.formatUnits(this.price, 8)) // TODO delete decimals hardcode
    }
}

export class Duration {
    repayStarts = BigNumber
    repayEnds = BigNumber
    constructor(repayStarts, repayEnds) {
        this.repayStarts = repayStarts
        this.repayEnds = repayEnds
    }
    getJSRepayStartsTimestamp(){
        return Number(utils.formatUnits(this.repayStarts, 0)) * 1000
    }
    getJSRepayEndsTimestamp(){
        return Number(utils.formatUnits(this.repayEnds, 0)) * 1000
    }
    getLocaleRepayStarts(){
        return new Date(this.getJSRepayStartsTimestamp()).toLocaleString()
    }
    getLocaleRepayEnds(){
        return new Date(this.getJSRepayEndsTimestamp()).toLocaleString()
    }
    getExpiredDays(timestamp){
        const now = new Date()
        const expired = new Date(timestamp)
        const diffTime = expired - now
        const diffDays = Math.ceil(diffTime / MS_PER_DAY)
        if (diffTime < 0) {
            return "expired"
        } else if (diffDays === 0) {
            return "today"
        } else if (diffDays === 1) {
            return "1 day"
        } else {
            return diffDays.toString() + " days"
        }
    }
    getDeltaDays(start, end){
        const diffTime = end - start
        const diffDays = Math.ceil(diffTime / MS_PER_DAY)
        if (diffTime < 0) {
            return "expired"
        } else if (diffDays === 0) {
            return "today"
        } else if (diffDays === 1) {
            return "1 day"
        } else {
            return diffDays.toString() + " days"
        }
    }
    getExpiredRepayStarts(){
        return this.getExpiredDays(this.getJSRepayStartsTimestamp())
    }
    getExpiredRepayEnds(){
        return this.getExpiredDays(this.getJSRepayEndsTimestamp())
    }
}

export class Order {
    id = Number
    lender = String
    borrower = String
    loanToken = Token
    loanAmount = BigNumber
    collateralToken = Token
    collateralAmount = BigNumber
    lenderFee = BigNumber
    duration = Duration
    rewardAmount = BigNumber
    NFTId = BigNumber
    role = Number
    status = Number
    openTimestamp = BigNumber

    constructor(
        id,
        lender,
        borrower,
        loanToken,
        loanAmount,
        collateralToken,
        collateralAmount,
        lenderFee,
        duration,
        rewardAmount,
        NFTId,
        role,
        status,
        openTimestamp
    ) {
        this.id = Number(utils.formatUnits(id, 0))
        this.lender = utils.getAddress(lender)
        this.borrower = utils.getAddress(borrower)
        this.loanToken = loanToken
        this.loanAmount = loanAmount
        this.collateralToken = collateralToken
        this.collateralAmount = collateralAmount
        this.lenderFee = lenderFee
        this.duration = duration
        this.rewardAmount = rewardAmount
        this.NFTId = NFTId
        this.role = role
        this.status = status
        this.openTimestamp = openTimestamp
    }
    getLoanUSDEquals(){
        return this.loanAmount.mul(this.loanToken.price).div(BigNumber.from("10").pow(BigNumber.from("8")))
    }
    getFormatLoanUSDEquals(){
        return utils.formatUnits(this.getLoanUSDEquals(), this.loanToken.decimals)
    }
    getCollateralUSDEquals(){
        return this.collateralAmount.mul(this.collateralToken.price).div(BigNumber.from("10").pow(BigNumber.from("8")))
    }
    getFormatCollateralUSDEquals(){
        return utils.formatUnits(this.getCollateralUSDEquals(), this.collateralToken.decimals)
    }
    getFloatLoanAmount(){
        return parseFloat(utils.formatUnits(this.loanAmount, this.loanToken.decimals))
    }
    getFormatLoanAmount(){
        return utils.formatUnits(this.loanAmount, this.loanToken.decimals)
    }
    getFormatLenderFeeAmount(){
        return utils.formatUnits(this.lenderFee, this.loanToken.decimals)
    }
    getFormatCollateralAmount(){
        return utils.formatUnits(this.collateralAmount, this.collateralToken.decimals)
    }
    getFloatLenderFee(){
        return parseFloat(utils.formatUnits(this.lenderFee, this.loanToken.decimals))
    }
    getFloatRewardAmount(){
        return parseFloat(utils.formatUnits(this.rewardAmount, TWOPAW_DECIMALS))
    }
    getFloatLenderFeePercent(){
        return this.getFloatLenderFee() * 100 / this.getFloatLoanAmount()
    }
    getFloatLenderFeeEquals(){
        return parseFloat(this.getFormatLoanUSDEquals()) *  this.getFloatLenderFeePercent() / 100
    }
    getCollateralPercent(){
        return Math.round(parseFloat(this.getFormatCollateralUSDEquals()) * 100 / parseFloat(this.getFormatLoanUSDEquals())) || undefined
    }
}