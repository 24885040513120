import {
    ARBITRUM_CHAIN_ID,
    ARBITRUM_PROVIDER,
    BSC_CHAIN_ID, BSC_PROVIDER,
    MAINNET_CHAIN_ID,
    MAINNET_PROVIDER,
    MS_PER_DAY
} from "./constants";
import arbitrumTokens from "./tokens/arbitrum.json"
import arbitrumAddresses from "./addresses/arbitrum.json"
import mainnetTokens from "./tokens/mainnet.json"
import mainnetAddresses from "./addresses/mainnet.json"
import bscTokens from "./tokens/bsc.json"
import bscAddresses from "./addresses/bsc.json"
import {BigNumber, constants, utils} from "ethers";
import {
    errorNotify,
    insufficientBalanceNotify, insufficientFundsNotify,
    successNotify,
    transactionRejectedNotify, wrongNonceNotify
} from "./toasts/toastsMessageses";
import {getMaxRewardDays} from "./contracts/methods/protocolMethods";


export const getChainIdText = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return "Ethereum"
        case ARBITRUM_CHAIN_ID:
            return "Arbitrum"
        case BSC_CHAIN_ID:
            return "BSC"
        default:
            return "Unknown"
    }
}
export const getTokens = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return mainnetTokens
        case ARBITRUM_CHAIN_ID:
            return arbitrumTokens
        case BSC_CHAIN_ID:
            return bscTokens
        default:
            return undefined
    }
}
export const getAddresses = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return mainnetAddresses
        case ARBITRUM_CHAIN_ID:
            return arbitrumAddresses
        case BSC_CHAIN_ID:
            return bscAddresses
        default:
            return {
                "protocolAddress": constants.AddressZero,
                "NFTAddress": constants.AddressZero,
                "governanceAddress": constants.AddressZero,
                "tokenAddress": constants.AddressZero,
                "routerAddress": constants.AddressZero,
                "factoryAddress": constants.AddressZero,
                "oracleAddress": constants.AddressZero
            }


    }
}
export const getScanner = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return "etherscan.io"
        case ARBITRUM_CHAIN_ID:
            return "arbiscan.io"
        case BSC_CHAIN_ID:
            return "bscscan.com"
        default:
            return undefined
    }
}
export const getProvider = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return MAINNET_PROVIDER
        case ARBITRUM_CHAIN_ID:
            return ARBITRUM_PROVIDER
        case BSC_CHAIN_ID:
            return BSC_PROVIDER
        default:
            return undefined
    }
}
export const getWETHAddress = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return utils.getAddress("0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2")
        case ARBITRUM_CHAIN_ID:
            return utils.getAddress("0x82aF49447D8a07e3bd95BD0d56f35241523fBab1")
        case BSC_CHAIN_ID:
            return utils.getAddress("0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c")
        default:
            return undefined
    }
}
export const makeHumanAmount = (amountString) => {
    if (amountString.includes(".")) {
        const slicedString = amountString.split(".")
        const index = slicedString[1].search("[1-9]")
        if (slicedString[0].length >= 7){
            if (slicedString[0][slicedString[0].length - 6] === "0"){
                return slicedString[0].slice(0, -6) + "M"
            }
            return slicedString[0].slice(0, -6) + "." + slicedString[0].slice(-6, -5) + "M"
        }
        if (slicedString[0].length >= 5){
            if (slicedString[0][slicedString[0].length - 3] === "0"){
                return slicedString[0].slice(0, -3) + "K"
            }
            return slicedString[0].slice(0, -3) + "." + slicedString[0].slice(-3, -2) + "K"
        }
        if (index !== -1) {
            if(index > 8){
                return slicedString[0] + "." + slicedString[1].slice(0, 2) + "..."
            }
            return slicedString[0] + "." + slicedString[1].slice(0, index + 2)
        } else return slicedString[0]

    } else {
        if (amountString.length >= 7){
            if (amountString[amountString.length - 6] === "0"){
                return amountString.slice(0, -6) + "M"
            }
            return amountString.slice(0, -6) + "." + amountString.slice(-6, -5) + "M"
        }
        if (amountString.length >= 5){
            if (amountString[amountString.length - 3] === "0"){
                return amountString.slice(0, -3) + "K"
            }
            return amountString.slice(0, -3) + "." + amountString.slice(-3, -2) + "K"
        }
        return amountString
    }
}
export const makeTWOPAWHumanAmount = (amountString) => {
    const amount = Number(parseFloat(amountString).toFixed(0))
    if (amount > 99999){
        const divAmount = Math.floor(amount / 1000)
        return divAmount.toString() + "K"
    }else return Math.floor(amountString)
}
export const formatAmount = (bigNumber, decimals) => {
    return utils.formatUnits(bigNumber, decimals)
}
export const getTokensArray = (data) => {
    return Array.from(new Set(data.map((obj) => {
        return [
            obj.loanToken,
            obj.collateralToken,
        ]
    }).flat(1)))
}
export const isTWOPAWTokenAddress = (address, chaiId) => {
    const addresses = getAddresses(chaiId)
    if (addresses){
        return address === addresses.tokenAddress
    }
    return false
}
export const processTransaction = async (tx) => {
    const receipt = await tx.wait()
    if (receipt.status === 1) {
        successNotify()
        return true
    } else {
        errorNotify()
        return false
    }
}
export const catchMetamaskErrors = (error) => {
    if (error.data) {
        const message = error.data.message
        if (message.endsWith("transfer amount exceeds balance")) {
            insufficientBalanceNotify()
        } else {
            errorNotify()
        }
    } else {
        if (typeof error.code === "string") {
            switch (error.code) {
                case "ACTION_REJECTED":
                    transactionRejectedNotify()
                    break
                case "INSUFFICIENT_FUNDS":
                    insufficientFundsNotify()
                    break
                default:
                    errorNotify()
                    break
            }
        } else if (typeof error.code === "number") {
            switch (error.code) {
                case -32603:
                    wrongNonceNotify()
                    break
                default:
                    errorNotify()
                    break
            }
        }
    }
}

export const toUpperFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}
export const calculateRewardAmountMainnet = (startRepayTimestamp, denominator, tokenDecimals, bigAmount, maxRewardDays) => {
    const daysAmount = Math.floor((startRepayTimestamp - Date.now()) / MS_PER_DAY)
    let addDays = BigNumber.from("0")
    if (daysAmount >= 20 && daysAmount !== 0) {
        if (!denominator.eq(BigNumber.from("0"))) {
            addDays = bigAmount.div(denominator)
            console.log(addDays)
            console.log(maxRewardDays)
            addDays = addDays.gt(maxRewardDays) ? maxRewardDays : addDays
        }
        const bigRewardAmount = BigNumber.from(daysAmount.toString()).add(addDays).pow(BigNumber.from("2"))
        const bigRewardAmountDecimals = bigRewardAmount.mul(BigNumber.from("10").pow(tokenDecimals))

        // console.log("startRepayTimestamp", startRepayTimestamp)
        // console.log("denominator", denominator.toString())
        // console.log("tokenDecimals", tokenDecimals)
        // console.log("bigAmount", utils.formatUnits(bigAmount, tokenDecimals))
        // console.log("daysAmount", daysAmount)
        // console.log("addDays", utils.formatUnits(addDays, 0))

        return utils.formatUnits(bigRewardAmountDecimals, tokenDecimals)
    } else {
        return "0"
    }
}
export const calculateRewardAmountArbitrum = (startRepayTimestamp, loanTokenSettings, bigAmount, bigFeeAmount, protocolSettings) => {
    const daysAmount = utils.parseUnits(Math.floor((startRepayTimestamp - Date.now()) / MS_PER_DAY).toString(), 0)
    const daysAmountMultiplier = loanTokenSettings.daysAmountMultiplier
    const addDaysMultiplier = loanTokenSettings.addDaysMultiplier
    const denominator = loanTokenSettings.denominator
    let addDays
    let rewardAmount
    if (!denominator.eq(BigNumber.from("0"))){
        addDays = bigAmount.div(denominator)
        addDays = addDays.gt(protocolSettings.maxRewardDays) ? protocolSettings.maxRewardDays : addDays
    }else {
        addDays = BigNumber.from("0")
    }
    if (addDays.gte(BigNumber.from("1"))){
        if (bigFeeAmount.eq(BigNumber.from("0"))){
            return "0"
        }else {
            const partOfAmount = bigAmount.div(bigFeeAmount)
            if (partOfAmount.lt(BigNumber.from("50"))){
                return "0"
            }else if (
                partOfAmount.gte(protocolSettings.minPartOfLoanAmount) &&
                partOfAmount.lte(protocolSettings.maxPartOfLoanAmount)
            ){
                rewardAmount = daysAmount.mul(daysAmountMultiplier).add(addDays.mul(addDaysMultiplier))
                return utils.formatUnits(rewardAmount, 0)
            }else if (partOfAmount.gt(protocolSettings.maxPartOfLoanAmount)){
                return "0"
            }else {
                rewardAmount = daysAmount.mul(daysAmountMultiplier)
                return utils.formatUnits(rewardAmount, 0)
            }
        }
    }else {
        return "0"
    }
}
export const getLockedCountHolderNFT = (holderNFTs) => {
    let lockedNFTsCount = 0
    for (let i = 0; i < holderNFTs.length; i++) {
        if (holderNFTs[i].isLocked){
            lockedNFTsCount ++
        }
    }
    return lockedNFTsCount
}
export const getUnlockedHolderNFTId = (holderNFTs) => {
    let unlockedHolderNFTId = constants.MaxUint256
    for (let i = 0; i < holderNFTs.length; i++) {
        if (!holderNFTs[i].isLocked){
            return holderNFTs[i].id
        }
    }
    return unlockedHolderNFTId
}

