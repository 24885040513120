import React from 'react';
import {Col, Modal, Row, Table} from "react-bootstrap";
import {useRecoilState, useRecoilValue} from "recoil";
import {protocolSettingsAtom} from "../../../state/atoms/globalAtoms";
import {useEthers} from "@usedapp/core";
import {orderModalHeaderStyle} from "../../../styles/contentStyles";
import {toUpperFirstLetter} from "../../../utilities";
import close from "../../../assets/svg/close.svg";
import {ARBITRUM_CHAIN_ID, BSC_CHAIN_ID, MAINNET_CHAIN_ID} from "../../../constants";
import {tokenomicsInfoModalAtom} from "../../../state/atoms/contentAtoms";

const TokenomicInfoModal = () => {
    const [showTokenomicInfoModal, setShowTokenomicInfoModal] = useRecoilState(tokenomicsInfoModalAtom)
    const protocolSettings = useRecoilValue(protocolSettingsAtom)

    const {chainId} = useEthers()
    const renderHeader = () => {
        return (
            <div>
                <div className="d-none d-lg-block" style={orderModalHeaderStyle}>
                    <Row>
                        <Col
                            className="text-start">Tokenomic</Col>
                        <Col className="text-end">
                            <img src={close} width={25} height={25} alt="close"
                                 style={{marginTop: 0, cursor: "pointer", position: "absolute", top: 10, right: 14}}
                                 onClick={() => setShowTokenomicInfoModal(false)}/>
                        </Col>
                    </Row>
                </div>
                <div className="d-lg-none " style={{...orderModalHeaderStyle, fontSize: 15}}>
                    <Row>
                        <Col
                            className="text-start header-text">Tokenomic</Col>
                        <Col className="text-end">
                            <img src={close} width={25} height={25} alt="close"
                                 style={{marginTop: 0, cursor: "pointer", position: "absolute", top: 10, right: 14}}
                                 onClick={() => setShowTokenomicInfoModal(false)}/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
    const renderMainnetTokenomicInfo = () => {
        return(
            <div>

            </div>
        )
    }
    const renderBSCTokenomicText = () => {
        return(
            <Row>
                <Col>
                    <p>Total supply: 100 million tokens.</p>
                    <p>TWOPAW BSC is a deflationary token. It will be used by TWOPAW ecosystem applications.</p>
                    <p>TWOPAW has a 5% burning tax, so adjusting your slippage tolerance to around 7% is suggested when buying/selling to ensure your successful transactions.</p>
                    <p>Drop 15%
                        <br/>LP 10%
                        <br/>Reward protocol 75%.
                        <br/>Tx: 2% Burn
                        <br/>Tx: 3% Back reward protocol</p>
                    <p>NFTDAO holders have the right to all protocol earnings after 2000 NFTDAO are sold. Only DAO can add new denominations and tokens or change them.</p>
                    <p>You will be able to place orders and earn rewards starting from 2 minutes</p>
                    <p>The rewards system will be paid out if the lender fee does not exceed 0%.</p>
                    <p>You can buy NFTs not only for BNB, but also for the number of created orders (default: 500).
                        The NFT supply is unlimited.</p>
                    <p>How rewards are paid: In the case of (repay), a reward is paid out if there are sufficient free tokens on the protocol at the time of (repay).</p>

                </Col>
            </Row>
        )
    }
    const renderArbitrumTokenomicText = () => {
        return (
            <Row>
                <Col>
                    <p>Total supply: 100 million tokens.</p>
                    <p>NFTDAO holders have the right to all protocol earnings after 2000 NFTDAO are sold.
                        Only DAO can add new denominations and tokens or change them.</p>

                    <p>Protocol takes 0.5% of loan amount is taken as a fee if taken order.</p>

                    <p>You will be able to place orders and earn rewards starting from 2 minutes.
                        <br/>The rewards system will be new and will be paid out if the lender fee does
                        not exceed 0%.</p>

                    <p>The rewards system will be new and will be paid out if the lender fee does not exceed 0%.</p>

                    <p>You can sell your NFTs at a price of 30k TWOPAW, but only if you create 5 orders for 1 NFT.</p>
                    <p>You can buy NFTs not only for tokens, but also for the number of created orders (default: 500). <br/>The NFT supply is unlimited.</p>
                    <p>How rewards are paid: In the case of (repay), a reward is paid out if there are sufficient free tokens on the protocol at the time of (repay).</p>


                    <p>TAX SWAP Arbitrum One Network:
                        <br/>At the start of sales will then be reduced
                        <br/>Buys less  14999 TWOPAW are taxed at 0%
                        <br/>Buys over 14999 TWOPAW are taxed at 5%
                        <br/>Sells less  14999 TWOPAW are taxed at 5%
                        <br/>Sells over 14999 TWOPAW are taxed at 10%</p>
                </Col>
            </Row>
        )
    }
    const renderArbitrumTokenomicTable = (size) => {
        return (
            <div>
                <Row>
                    <Col className="text-center">
                        {size === "desktop"
                        ?<h5>Supported Tokens to Reward and its Denominator</h5>
                        :<p style={{fontSize: "3vw"}}>Supported Tokens to Reward and its Denominator</p>}
                        <Row>
                            <Col>
                                <Table bordered hover variant="dark" className="text-center">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Address</th>
                                        <th>Denominator</th>
                                        <th>NFT Time Lock</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/*{rewardedTokens.map((tokenSettings, index) => {*/}
                                    {/*    let token = storageTokens.find(item => item.address === tokenSettings.tokenAddress)*/}
                                    {/*    let decimals = 18*/}

                                    {/*    if (!token) {*/}
                                    {/*        token = {*/}
                                    {/*            "address": tokenSettings.tokenAddress,*/}
                                    {/*            "img": "https://twopaws.io/token-icons/default.png"*/}
                                    {/*        }*/}
                                    {/*        if (tokenSettings.tokenAddress === "0x7fAfA283e40f8a06336064a8C1fb00a5F595A4EA") {*/}
                                    {/*            token = {*/}
                                    {/*                "address": tokenSettings.tokenAddress,*/}
                                    {/*                "img": "https://twopaws.io/token-icons/twopaws512x512.svg"*/}
                                    {/*            }*/}
                                    {/*        }*/}
                                    {/*    } else {*/}
                                    {/*        if (token.address === "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9" ||*/}
                                    {/*            token.address === "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8"*/}
                                    {/*        ) {*/}
                                    {/*            decimals = 6*/}
                                    {/*        }else if (token.address === "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f"){*/}
                                    {/*            decimals = 8*/}
                                    {/*        }*/}
                                    {/*    }*/}
                                    {/*    return (*/}
                                    {/*        <tr key={index}>*/}
                                    {/*            <td><img src={token.img} height={30} alt="token-icon"/></td>*/}
                                    {/*            <td style={{paddingTop: 12}}>{token.address}</td>*/}
                                    {/*            <td style={{paddingTop: 12}}>{makeHumanAmount(utils.formatUnits(tokenSettings.denominator, decimals))}</td>*/}
                                    {/*        </tr>*/}
                                    {/*    )*/}
                                    {/*})}*/}
                                    </tbody>
                                </Table>

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>
        )
    }
    const renderArbitrumTokenomicInfo = () => {
        return(
            <div>
                <div className="d-none d-lg-block">
                    {showTokenomicInfoModal === 1
                    ? renderArbitrumTokenomicText()
                    :<></>}
                    {/*{renderArbitrumTokenomicTable("desktop")}*/}
                </div>
                <div className="d-lg-none" style={{fontSize: "2.6vw"}}>
                    {showTokenomicInfoModal === 1
                        ? renderArbitrumTokenomicText()
                        :<></>}
                    {/*{renderArbitrumTokenomicTable("mobile")}*/}
                </div>
            </div>
        )
    }
    const renderBSCTokenomicInfo = () => {
        return(
            <div>
                <div className="d-none d-lg-block">
                    {showTokenomicInfoModal === 1
                        ? renderBSCTokenomicText()
                        :<></>}
                    {/*{renderArbitrumTokenomicTable("desktop")}*/}
                </div>
                <div className="d-lg-none" style={{fontSize: "2.6vw"}}>
                    {showTokenomicInfoModal === 1
                        ? renderBSCTokenomicText()
                        :<></>}
                    {/*{renderArbitrumTokenomicTable("mobile")}*/}
                </div>
            </div>
        )
    }
    const renderTokenomicInfo = () => {
        switch (chainId) {
            case MAINNET_CHAIN_ID:
                return renderMainnetTokenomicInfo()
            case ARBITRUM_CHAIN_ID:
                return renderArbitrumTokenomicInfo()
            case BSC_CHAIN_ID:
                return renderBSCTokenomicInfo()
            default:
                return <div></div>
        }
    }

    return (
        <Modal
            style={{paddingTop: 12}}
            variant="dark"
            size="lg"
            show={(!!showTokenomicInfoModal)}
            onHide={() => setShowTokenomicInfoModal(null)}
            enforceFocus={false}>
            <Modal.Body style={{backgroundColor: "#1f304a", borderRadius: "20px"}}>
                <div style={orderModalHeaderStyle}>
                    <Row>
                        {renderHeader()}
                    </Row>
                </div>
                {renderTokenomicInfo()}
            </Modal.Body>
        </Modal>
    );
};

export default TokenomicInfoModal;