export const TableHeaderStyle = {
    height: "120px",
    padding: "35px 35px 35px 35px"
}
export const mobileHeaderStyle = {
    // height: "120px",
    padding: "10px 10px 10px 5px"
}
export const logoStyle = {
    width: 100,
    height: 100,

    cursor: "pointer"
}
export const headerTextStyle = {
    // fontWeight: "bold",
    fontSize: "36px"
}