import {atom} from "recoil";
import {DEFAULT_VIEW_ORDERS_COUNT, SUPPLY} from "../../constants";

export const ordersInfoModalAtom = atom({
    key: "infoModalAtom",
    default: null
})
export const tokenomicsInfoModalAtom = atom({
    key: "tokenomicsInfoModalAtom",
    default: null
})
export const rewardedOrderInfoModalAtom = atom({
    key: "rewardedOrderInfoModalAtom",
    default: null
})
export const activeMarketAtom = atom({
    key: "activeMarketAtom",
    default: SUPPLY
})
export const showHistoryOrdersAtom = atom({
    key: "showHistoryOrdersAtom",
    default: false
})

export const marketItemIndexAtom = atom({
    key: "marketItemIndexAtom",
    default: null
})

export const viewOrdersCountAtom = atom({
    key: "viewOrdersCountAtom",
    default: DEFAULT_VIEW_ORDERS_COUNT
})

export const chosenLoanFilterTokenAtom = atom({
    key: "chosenLoanFilterTokenAtom",
    default: null
})
export const chosenCollateralFilterTokenAtom = atom({
    key: "collateralFilterTokenAtom",
    default: null
})
export const currentOrderAtom = atom({
    key: "currentOrderAtom",
    default: null
})
export const showNewLoanModalAtom = atom({
    key: "showNewLoanModalAtom",
    default: false
})
export const newLoanChooseAtom = atom({
    key: "newLoanChooseAtom",
    default: SUPPLY
})


