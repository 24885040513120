import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";

const DesktopDatetimePicker = (props) => {
    const now = new Date()
    const [startRepayYear, setStartRepayYear] = useState(props.initialDate.getFullYear())
    const [startRepayMonth, setStartRepayMonth] = useState(props.initialDate.getMonth() + 1)
    const [startRepayDay, setStartRepayDay] = useState(props.initialDate.getDate())
    const [startRepayHours, setStartRepayHours] = useState(props.initialDate.getHours())
    const [startRepayMinutes, setStartRepayMinutes] = useState(props.initialDate.getMinutes())
    const [currentTimestamp, setCurrentTimestamp] = useState(
        props.initialDate.getTime()
    )
    useEffect(() => {
        setStartRepayYear(props.initialDate.getFullYear())
        setStartRepayMonth(props.initialDate.getMonth() + 1)
        setStartRepayDay(props.initialDate.getDate())
        setStartRepayHours(props.initialDate.getHours())
        setStartRepayMinutes(props.initialDate.getMinutes())
        }, [props.initialDate])
    const makeTimestamp = (year, month, days, hours, minutes) => {
        try {
            const date = new Date(year, month, days, hours, minutes, 0)
            const timestamp = date.getTime()
            setCurrentTimestamp(timestamp)
            props.setDate(date)
        }catch (e) {
            console.log("error")
            console.log(e)
        }
    }
    const changeValuesHandler = (event) => {
        switch (event.target.id) {
            case "year":
                if (Number(event.target.value) >= now.getFullYear()){
                    setStartRepayYear(Number(event.target.value))
                    makeTimestamp(event.target.value, startRepayMonth, startRepayDay, startRepayHours, startRepayMinutes)
                }
                break
            case "month":
                if (event.target.value === ""){
                    setStartRepayMonth(event.target.value)
                }else {
                    if ((Number(event.target.value) > 0) && (Number(event.target.value) <= 12)){
                        setStartRepayMonth(Number(event.target.value))
                        makeTimestamp(startRepayYear, event.target.value - 1, startRepayDay, startRepayHours, startRepayMinutes)
                    }
                }
                break
            case "day":
                if (event.target.value === ""){
                    setStartRepayDay(event.target.value)
                }else {
                    if ((Number(event.target.value) > 0) && (Number(event.target.value) <= 31)){
                        setStartRepayDay(Number(event.target.value))
                        makeTimestamp(startRepayYear, startRepayMonth - 1, event.target.value, startRepayHours, startRepayMinutes)
                    }
                }
                break
            case "hour":
                if (event.target.value === ""){
                    setStartRepayHours(event.target.value)
                }else {
                    if ((Number(event.target.value) >= 0) && (Number(event.target.value) < 24)){
                        setStartRepayHours(Number(event.target.value))
                        makeTimestamp(startRepayYear, startRepayMonth - 1, startRepayDay, event.target.value, startRepayMinutes)
                    }
                }
                break
            case "minutes":
                if (event.target.value === ""){
                    setStartRepayMinutes(event.target.value)
                }else {
                    if ((Number(event.target.value) >= 0) && (Number(event.target.value) <= 59)){
                        setStartRepayMinutes(Number(event.target.value))
                        makeTimestamp(startRepayYear, startRepayMonth - 1, startRepayDay, startRepayHours, event.target.value)
                    }
                }
                break
            default:
                break
        }
    }

    return (
        <div>
            <Row style={{width: "100%", paddingLeft: 10}}>
                <Col>{props.text} </Col>
                {/*<Col xs={7} className="text-end"*/}
                {/*     style={{fontSize: 12, paddingTop: 5, color: "#9597a1"}}>*/}
                {/*    {new Date(currentTimestamp).toLocaleString([], {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})}*/}
                {/*</Col>*/}
            </Row>
            <Row>
                <div style={{border: "1px solid #454F5B", paddingLeft: 35, height: 60, borderRadius: 12, width: "95%", margin: "0 auto"}}>

                    <Col style={{position: "relative"}}>
                        <div style={{left: 5,top: -3, position: "absolute", fontSize: 10, color: "#9597a1"}}>Year</div>
                        <div style={{top: 11, position: "absolute", width: 70}}>
                            <Form.Control
                                autoComplete="off"
                                id="year"
                                size="sm"
                                type="number"
                                className="new-amount"
                                value={startRepayYear}
                                placeholder="Year"
                                // onClick={() => props.clickOutsidePopovers()}
                                onChange={(event) => changeValuesHandler(event)}
                            />
                        </div>
                        <div style={{left: 85,top: -3, position: "absolute", fontSize: 10, color: "#9597a1"}}>Month</div>
                        <div style={{left: 80,top: 11, position: "absolute", width: 70}}>
                            <Form.Control
                                autoComplete="off"
                                id="month"
                                size="sm"
                                type="number"
                                className="new-amount"
                                value={startRepayMonth}
                                placeholder="Month"
                                // onClick={() => props.clickOutsidePopovers()}
                                onChange={(event) => changeValuesHandler(event)}/>
                        </div>
                        <div style={{left: 165,top: -3, position: "absolute", fontSize: 10, color: "#9597a1"}}>Day</div>
                        <div style={{left: 160,top: 11, position: "absolute", width: 70}}>
                            <Form.Control
                                autoComplete="off"
                                id="day"
                                size="sm"
                                type="number"
                                className="new-amount"
                                value={startRepayDay}
                                placeholder="Day"
                                // onClick={() => props.clickOutsidePopovers()}
                                onChange={(event) => changeValuesHandler(event)}/>
                        </div>
                        <div style={{left: 245,top: -3, position: "absolute", fontSize: 10, color: "#9597a1"}}>Hour</div>
                        <div style={{left: 240,top: 11, position: "absolute", width: 70}}>
                            <Form.Control
                                autoComplete="off"
                                id="hour"
                                size="sm"
                                type="number"
                                className="new-amount"
                                value={startRepayHours}
                                placeholder="Hour"
                                // onClick={() => props.clickOutsidePopovers()}
                                onChange={(event) => changeValuesHandler(event)}/>
                        </div>
                        <div style={{left: 325,top: -3, position: "absolute", fontSize: 10, color: "#9597a1"}}>Minutes</div>
                        <div style={{left: 313,top: 19, position: "absolute", width: 70, fontSize: 20}}>:</div>
                        <div style={{left: 320,top: 11, position: "absolute", width: 70}}>
                            <Form.Control
                                autoComplete="off"
                                id="minutes"
                                size="sm"
                                type="number"
                                className="new-amount"
                                value={startRepayMinutes}
                                placeholder="Minutes"
                                // onClick={() => props.clickOutsidePopovers()}
                                onChange={(event) => changeValuesHandler(event)}/>
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default DesktopDatetimePicker;