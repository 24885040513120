import React from 'react';
import {modalHeaderStyle} from "../../styles/modalStyles";
import {Col, Row} from "react-bootstrap";
import close from "../../assets/svg/close.svg"

const ModalHeader = (props) => {
    return (
        <div style={modalHeaderStyle}>
            <Row>
                <Col className="text-start">{props.text}</Col>
                <Col className="text-end">
                    <img src={close} width={25} height={25} alt="close"
                         style={{marginTop: -10, cursor: "pointer"}}
                         onClick={() => props.setShowModal(false)}/>
                </Col>
            </Row>
        </div>
    );
};

export default ModalHeader;