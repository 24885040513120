import React, {useState} from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import {useRecoilState} from "recoil";
import {connectModalAtom} from "../../../state/atoms/globalAtoms";
import WalletConnectProvider from '@walletconnect/web3-provider/dist/umd/index.min.js'
import {useEthers} from "@usedapp/core";
import {ARBITRUM_PROVIDER_URL, BSC_PROVIDER_URL, MAINNET_PROVIDER_URL} from "../../../constants";
import {modalBodyStyle} from "../../../styles/modalStyles";
import ModalHeader from "../ModalHeader";
import {getChainIdText} from "../../../utilities";
import metamaskIcon from "../../../assets/svg/MetaMask_Fox.svg"
import walletConnectIcon from "../../../assets/svg/walletConnectIcon.svg"

const ConnectModal = () => {
    const [metamaskHovered, setMetamaskHovered] = useState(false)
    const [walletConnectHovered, setWalletConnectHovered] = useState(false)
    const [showConnectModal, setShowConnectModal] = useRecoilState(connectModalAtom)
    const {chainId, activate, activateBrowserWallet} = useEthers()

    async function onConnect() {
        try {
            const provider = new WalletConnectProvider({
                rpc: {
                    1: MAINNET_PROVIDER_URL,
                    42161: ARBITRUM_PROVIDER_URL,
                    56: BSC_PROVIDER_URL
                },
            })
            await provider.enable()
            await activate(provider)
        } catch (error) {
            console.error(error)
        }
    }

    const walletConnect = async () => {
        await onConnect()
            .then(() => {
                setShowConnectModal(false)
            })
    }
    const metamaskConnect = async () => {
        activateBrowserWallet()
        setShowConnectModal(false)
    }

    return (
        <Modal
            size="md"
            show={showConnectModal}
            onHide={() => setShowConnectModal(false)}
            style={{marginTop: 200}}
        >
            <Modal.Body style={modalBodyStyle}>
                <ModalHeader text={getChainIdText(chainId)} setShowModal={setShowConnectModal}/>
                <Row>
                    <div style={{
                        backgroundColor: metamaskHovered ? "#182539" : "rgba(255, 255, 255, 0.05)",
                        width: "95%",
                        margin: "0 auto",
                        height: 70,
                        borderRadius: 16,
                        marginBottom: 5,
                        cursor: "pointer"
                    }}
                         onMouseEnter={() => setMetamaskHovered(true)}
                         onMouseLeave={() => setMetamaskHovered(false)}
                         onClick={metamaskConnect}
                    >
                        <Row>
                            <Col lg={2} xs={3} style={{paddingTop: 5}}>
                                <img src={metamaskIcon} width={60} alt="metamaskIcon"
                                />
                            </Col>
                            <Col lg={10} xs={9} style={{paddingTop: 22, fontSize: 22}}>Metamask</Col>
                        </Row>
                    </div>
                </Row>
                <br/>
                <Row>
                    <div style={{
                        backgroundColor: walletConnectHovered ? "#182539" : "rgba(255, 255, 255, 0.05)",
                        width: "95%",
                        margin: "0 auto",
                        height: 70,
                        borderRadius: 16,
                        marginBottom: 5,
                        cursor: "pointer"
                    }}
                         onMouseEnter={() => setWalletConnectHovered(true)}
                         onMouseLeave={() => setWalletConnectHovered(false)}
                         onClick={walletConnect}
                    >
                        <Row>
                            <Col lg={2} xs={3} style={{paddingTop: 12}}>
                                <img src={walletConnectIcon} width={60} alt="walletConnectIcon"
                                />
                            </Col>
                            <Col lg={10} xs={9} style={{paddingTop: 22, fontSize: 22}}>Wallet Connect</Col>
                        </Row>
                    </div>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ConnectModal;