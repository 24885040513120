import {BigNumber, constants, Contract} from "ethers";
import {getNFTInterface, getProtocolInterface} from "../interfaces/interfaces"
import {getAddresses, getProvider} from "../../utilities";
import {MAINNET_CHAIN_ID} from "../../constants";


export const getProtocolContractWithProvider = (chainId) => {
    return new Contract(getAddresses(chainId).protocolAddress, getProtocolInterface(chainId), getProvider(chainId))
}
const getProtocolContractWithSigner = (chainId, signer) => {
    return new Contract(getAddresses(chainId).protocolAddress, getProtocolInterface(chainId), signer)
}
export const getNFTContractWithProvider = (chainId) => {
    return new Contract(getAddresses(chainId).NFTAddress, getNFTInterface(chainId), getProvider(chainId))
}
export const getNFTContractWithSigner = (chainId, signer) => {
    return new Contract(getAddresses(chainId).NFTAddress, getNFTInterface(chainId), signer)
}

export const newOrder = async (args, signer, chainId) => {
    const contract = getProtocolContractWithSigner(chainId, signer)
    if (chainId === MAINNET_CHAIN_ID) {
        return await contract.newOrder(
            args[0],
            args[1],
            args[2],
            args[3],
            args[4],
            args[5],
            args[6],
            args[7],
        )
    } else {
        return await contract.newOrder(
            args[0],
            args[1],
            args[2],
            args[3],
            args[4],
            args[5],
            args[6],
        )
    }

}

export const getOrder = async (orderId, signer, chainId) => {
    const contract = getProtocolContractWithSigner(chainId, signer)
    return await contract.getOrder(orderId)
}

export const getAllOrders = async (chainId) => {
    const contract = getProtocolContractWithProvider(chainId)
    return await contract.getAllOrders()
}

export const repayOrder = async (orderId, signer, chainId) => {
    const contract = getProtocolContractWithSigner(chainId, signer)
    return await contract.repayOrder(orderId)
}
export const cancelOrder = async (orderId, signer, chainId) => {
    const contract = getProtocolContractWithSigner(chainId, signer)
    return await contract.cancelOrder(orderId)
}
export const liquidateOrder = async (orderId, signer, chainId) => {
    const contract = getProtocolContractWithSigner(chainId, signer)
    return await contract.liquidateOrder(orderId)
}
export const getSupportedTokens = async (chainId) => {
    const contract = getProtocolContractWithProvider(chainId)
    return await contract.getSupportedTokens()
}
export const getSupportedTokensWithSettings = async (chainId) => {
    const contract = getProtocolContractWithProvider(chainId)
    return await contract.getSupportedTokensWithSettings()
}
export const getSupportedTokenSettings = async (supportedToken, chainId) => {
    const contract = getProtocolContractWithProvider(chainId)
    return await contract.supportedTokensSettings(supportedToken)
}
export const getMaxRewardDays = async (chainId) => {
    const contract = getProtocolContractWithProvider(chainId)
    return await contract.maxRewardDays()
}
export const getDAOFeePercent = async (chainId) => {
    const contract = getProtocolContractWithProvider(chainId)
    return await contract.DAOFeePercent()
}
export const getMinPartOfLoanAmount = async (chainId) => {
    try {
        const contract = getProtocolContractWithProvider(chainId)
        return await contract.minPartOfLoanAmount()
    } catch (e) {
        return undefined
    }
}
export const getMaxPartOfLoanAmount = async (chainId) => {
    try {
        const contract = getProtocolContractWithProvider(chainId)
        return await contract.maxPartOfLoanAmount()
    } catch (e) {
        return undefined
    }
}
export const isApprovedForAll = async (owner, operator, chainId) => {
    const contract = getNFTContractWithProvider(chainId)
    return await contract.isApprovedForAll(owner, operator)
}
export const setApprovalForAll = async (operator, approved, signer, chainId) => {
    const contract = getNFTContractWithSigner(chainId, signer)
    return await contract.setApprovalForAll(operator, approved)
}
export const buyNFT = async (ids, signer, chainId) => {
    const contract = getProtocolContractWithSigner(chainId, signer)
    return contract.buyNFTForTokens(ids)
}
export const buyNFTForETH = async (ids, signer, chainId, value) => {
    console.log("buyNFTForETH")
    console.log("chainId", chainId)
    console.log("value", value)
    console.log("chainId", chainId)
    const contract = getProtocolContractWithSigner(chainId, signer)
    return contract.buyNFTForETH(ids, {
        value: value
    })
}

export const sellNFT = async (ids, signer, chainId) => {
    const contract = getProtocolContractWithSigner(chainId, signer)
    return await contract.sellNFT(ids)
}
export const getOwnerIds = async (idsCount, owner, chainId) => {
    const ownerIds = []
    const contract = getNFTContractWithProvider(chainId)
    for (let i = 0; i < idsCount; i++) {
        let id = await contract.tokenOfOwnerByIndex(owner, i)
        ownerIds.push(id)
    }
    return ownerIds
}

export const getLowestNotLockedNFTId = async (owner, signer, chainId) => { // provider
    // TODO time for arbitrum
    const PAWDAOContract = getNFTContractWithProvider(chainId)
    const twoPawsContract = getProtocolContractWithProvider(chainId)
    const ownerNFTBalance = await PAWDAOContract.balanceOf(owner)
    console.log(ownerNFTBalance)
    const ownersIds = await PAWDAOContract.getOwnerIds(ownerNFTBalance, owner)
    console.log(ownersIds)
    for (const ownersIdKey in ownersIds) {
        let isLocked = await twoPawsContract.lockedProtocolNFTs(ownersIds[ownersIdKey])
        if (!isLocked) {
            return ownersIds[ownersIdKey]
        }
    }
    return constants.MaxUint256
}
export const getNecessaryBalanceForReward = async (account, chainId) => {
    try {
        const contract = getProtocolContractWithProvider(chainId)
        return await contract.necessaryBalanceForReward(account)
    } catch (e) {
        return BigNumber.from("0")
    }

}

export const isDroppedAddress = async (address, chainId) => {
    const contract = getProtocolContractWithProvider(chainId)
    return contract.isDroppedAddresses(address)
}
