export const marketsCardStyle = {
    width: "100%",
    minHeight: "64vh",
    borderRadius: "20px",
    // backgroundColor: "#182539",
    backgroundColor: "#1F304A",

    // backgroundColor: "#4f2d60",
    // backgroundImage: "linear-gradient(to right, #141e30, #243b55)"
}
export const marketsCardHeaderStyle = {
    fontSize: "22px",
    minHeight: "64px",
    paddingTop: "15px",
}

export const mainCardBodyStyle = {
    height: "64vh",
    overflow: "auto",
}

export const marketsSorterDesktopStyle = {
    color: "#9597a1",
    height: "35px",
    padding: "0px 20px 20px 20px"
}
export const marketsItemStyle = {
    height: "80px",
    cursor: "pointer",
    paddingTop: 25,
    paddingLeft: 25,
    border: "1px solid #383944",
    backgroundColor: "#1C2C44",
    borderRadius: "10px",
    marginBottom: "10px",
    position: "relative"
}
export const orderModalHeaderStyle = {
    backgroundColor: "#1f304a",
    borderRadius: "20px",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 20,
    paddingBottom: 20
}

export const marketsItemHoveredStyle = {
    height: "80px",
    backgroundColor: "#182539",
    paddingTop: 25,
    paddingLeft: 25,
    cursor: "pointer",
    border: "1px solid #383944",
    marginBottom: "10px",
    borderRadius: "10px",
    // boxShadow: "5px 5px 5px #2f1e52"
    transitionDuration: "500ms",
    transform: "scale(1.02)",
    overflow: "hidden",
    position: "relative"

}
export const percentStyle = {
    border: "2px solid #fdc54b",
    width: 40,
    height: 40,
    borderRadius: "50%",
    fontSize: 11,
    paddingTop: 10,
    color: "white",
}