import React, {useEffect, useState} from 'react';
import {Col, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {useRecoilState} from "recoil";
import {currentOrderAtom} from "../../../state/atoms/contentAtoms";
import {orderModalHeaderStyle} from "../../../styles/contentStyles";
import close from "../../../assets/svg/close.svg";
import info from "../../../assets/svg/info-circle.svg";
import {MAINNET_CHAIN_ID, ROLE} from "../../../constants";
import {formatAmount, getAddresses, getScanner, makeHumanAmount} from "../../../utilities";
import {useEthers, useTokenBalance} from "@usedapp/core";
import {getAllowance, getBalanceOf} from "../../../contracts/methods/ERC20Methods";
import {BigNumber, ethers} from "ethers";
import NameOfField from "./NameOfField";
import ValueOfField from "./ValueOfField";
import Skeleton from "react-loading-skeleton";
import OrderActionButton from "./OrderActionButton";
import RewardInfo from "./RewardInfo";

const CurrentOrderModal = () => {
    const [currentOrder, setCurrentOrder] = useRecoilState(currentOrderAtom)
    const {account, chainId} = useEthers()
    const loanBalance = useTokenBalance(currentOrder ? currentOrder.loanToken.address : ethers.constants.AddressZero, account)
    const collateralBalance = useTokenBalance(currentOrder ? currentOrder.collateralToken.address : ethers.constants.AddressZero, account)
    const renderInfoTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            The protocol reward will be paid upon completion of the deal.
        </Tooltip>
    )
    const getHumanLoanBalanceWithSymbol = () => {
        const balance = loanBalance
            ? makeHumanAmount(formatAmount(loanBalance, currentOrder.loanToken.decimals))
            : "0"
        return balance + " " + currentOrder.loanToken.symbol
    }
    const getHumanCollateralBalanceWithSymbol = () => {
        const balance = collateralBalance
            ? makeHumanAmount(formatAmount(collateralBalance, currentOrder.collateralToken.decimals))
            : "0"
        return balance + " " + currentOrder.collateralToken.symbol
    }
    const calcBorrowerGet = () => {
        const loanAmount = parseFloat(currentOrder.getFormatLoanAmount())
        const lenderFeeAmount = parseFloat(currentOrder.getFormatLenderFeeAmount())
        const protocolFeeAmount = loanAmount * 0.5 / 100
        return loanAmount - (lenderFeeAmount + protocolFeeAmount)
    }
    const renderOrderInfo = () => {
        if (currentOrder) {
            let balance = "0"
            if (currentOrder.role === ROLE.SUPPLY) {
                if (currentOrder.lender === account || currentOrder.borrower === account) {
                    balance = getHumanLoanBalanceWithSymbol()
                } else {
                    balance = getHumanCollateralBalanceWithSymbol()
                }
            } else {
                if (currentOrder.lender === account || currentOrder.borrower === account) {
                    balance = getHumanLoanBalanceWithSymbol()
                } else {
                    balance = getHumanLoanBalanceWithSymbol()
                }
            }
            return (
                <div id="current-order-modal">
                    <Row>
                        <Col className="text-end">
                            <div style={{fontSize: 10, color: "#9597a1"}}>
                                Balance: {balance}
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row
                        onClick={() => window.open(`https://${getScanner(chainId)}/address/${currentOrder.loanToken.address}`, "_blank")}
                        style={{cursor: "pointer"}}>
                        <Col>
                            <NameOfField text="Loan Amount"/>
                        </Col>
                        <Col className="text-end text-truncate" style={{overflow: "unset"}}>
                            <ValueOfField
                                img={currentOrder.loanToken.img}
                                value={currentOrder.getFormatLoanAmount()
                                    ? makeHumanAmount(currentOrder.getFormatLoanAmount())
                                    : "0"}
                                symbol={currentOrder.loanToken.symbol}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <div style={{fontSize: 10, color: "#9597a1"}}>
                                {parseFloat(currentOrder.getFormatLoanUSDEquals()) === 0
                                    ? <Skeleton width="20%" highlightColor="#182539" baseColor="#243b55"/>
                                    : `eq. ${makeHumanAmount(currentOrder.getFormatLoanUSDEquals())} USD`}

                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row
                        onClick={() => window.open(`https://${getScanner(chainId)}/address/${currentOrder.collateralToken.address}`, "_blank")}
                        style={{cursor: "pointer"}}>
                        <Col style={{color: "#9597a1"}}>
                            <NameOfField text="Collateral Amount"/>
                        </Col>
                        <Col className="text-end text-truncate" style={{overflow: "unset"}}>
                            <ValueOfField
                                img={currentOrder.collateralToken.img}
                                value={currentOrder.getFormatCollateralAmount()
                                    ? makeHumanAmount(currentOrder.getFormatCollateralAmount())
                                    : "0"}
                                symbol={currentOrder.collateralToken.symbol}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <div style={{fontSize: 10, color: "#9597a1"}}>
                                {parseFloat(currentOrder.getFormatCollateralUSDEquals()) === 0
                                    ? <Skeleton width="20%" highlightColor="#182539" baseColor="#243b55"/>
                                    : `eq. ${makeHumanAmount(currentOrder.getFormatCollateralUSDEquals())} USD`}
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col xs={5} style={{color: "#9597a1"}}>
                            <NameOfField text="Lender Fee"/>
                        </Col>
                        <Col xs={7} className="text-end text-truncate">
                            <ValueOfField
                                value={`${makeHumanAmount(currentOrder.getFloatLenderFeePercent().toString())}% (${makeHumanAmount(currentOrder.getFormatLenderFeeAmount())} ${currentOrder.loanToken.symbol})`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <div style={{fontSize: 10, color: "#9597a1"}}>
                                {currentOrder.getFloatLenderFeeEquals() === 0
                                    ? <div style={{marginBottom: 15}}></div>
                                    : `eq. ${makeHumanAmount(currentOrder.getFloatLenderFeeEquals().toString())} USD`}
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    {account === currentOrder.lender || account === currentOrder.borrower
                        ? <></>
                        : <div>
                            <Row>
                                <Col style={{color: "#9597a1"}}>
                                    <NameOfField text="You'll Get"/>
                                </Col>
                                <Col className="text-end text-truncate"
                                     style={{color: "#40B842", overflow: "unset"}}>
                                    <ValueOfField
                                        img={currentOrder.loanToken.img}
                                        value={currentOrder.getFormatCollateralAmount()
                                            ? `${makeHumanAmount(calcBorrowerGet().toString())}`
                                            : "0"}
                                        symbol={currentOrder.loanToken.symbol}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end"
                                     style={{fontSize: 10, color: "#9597a1"}}>
                                    Loan Amount - (Lender Fee + Protocol Fee)
                                </Col>
                            </Row>
                            <br/>
                        </div>}
                    {parseFloat(currentOrder.getFloatRewardAmount()) !== 0 && chainId === MAINNET_CHAIN_ID
                        ? <div>
                            <Row>
                                <Col style={{color: "#9597a1"}}>
                                    <NameOfField text="Reward"/>
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{show: 250, hide: 500}}
                                        overlay={renderInfoTooltip}
                                    >
                                        <img src={info} width={23} height={23} alt="info"
                                             style={{paddingLeft: 5, marginTop: -5}}
                                        />
                                    </OverlayTrigger>
                                </Col>
                                <Col className="text-end text-truncate" style={{color: "#CAB22E"}}>
                                    {makeHumanAmount(currentOrder.getFloatRewardAmount().toString())} TWOPAW
                                </Col>
                            </Row>
                            <div style={{marginBottom: 15}}></div>
                            {/*<Row>*/}
                            {/*    <Col className="text-end"*/}
                            {/*         style={{fontSize: 10, color: "#9597a1"}}>*/}
                            {/*        {parseFloat(currentOrder.rewardAmount) === 0*/}
                            {/*            ? "price not found"*/}
                            {/*            : `eq: ${makeHumanAmount(rewardEQ.toString())} USD`}*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <br/>
                        </div>
                        : <></>}
                    {chainId !== MAINNET_CHAIN_ID && currentOrder && !currentOrder.lenderFee.gt(BigNumber.from("0"))
                        ?
                        <div>
                            <RewardInfo order={currentOrder}/>
                            <br/>
                        </div>
                        : <></>}
                    <div style={{lineHeight: "20px"}}><p className="text-center" style={{color: "#9597a1"}}>
                        You have from <span
                        style={{color: "#ECECEC"}}>{new Date(currentOrder.duration.getJSRepayStartsTimestamp()).toLocaleString(
                        ["en-GB"], {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false
                        }
                    )}</span> to repay your loan, with the deadline being <span
                        style={{color: "#ECECEC"}}>{new Date(currentOrder.duration.getJSRepayEndsTimestamp()).toLocaleString(
                        ["en-GB"], {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false
                        }
                    )}</span>
                    </p></div>
                    <p className="text-center"
                       style={{fontSize: 12, lineHeight: 0.5, paddingTop: 10, color: "#9597a1"}}>If the repayment is
                        not completed, </p>
                    <p className="text-center" style={{fontSize: 12, lineHeight: 0.5, color: "#9597a1"}}>all
                        collateral amount go to the lender.</p>
                </div>
            )
        }
    }
    const renderHeader = () => {
        return (
            <div>
                <div className="d-none d-lg-block" style={orderModalHeaderStyle}>
                    <Row>
                        <Col
                            className="text-start">{currentOrder && currentOrder.role === ROLE.SUPPLY ? "Supply " : "Borrow "}Order
                            Info</Col>
                        <Col className="text-end">
                            <img src={close} width={25} height={25} alt="close"
                                 style={{marginTop: 0, cursor: "pointer", position: "absolute", top: 10, right: 14}}
                                 onClick={() => setCurrentOrder(null)}/>
                        </Col>
                    </Row>
                </div>
                <div className="d-lg-none" style={{...orderModalHeaderStyle, fontSize: 15}}>
                    <Row>
                        <Col
                            className="text-start">{currentOrder && currentOrder.role === ROLE.SUPPLY ? "Supply " : "Borrow "}Order
                            Info</Col>
                        <Col className="text-end">
                            <img src={close} width={25} height={25} alt="close"
                                 style={{marginTop: 0, cursor: "pointer", position: "absolute", top: 10, right: 14}}
                                 onClick={() => setCurrentOrder(null)}/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
    return (
        <Modal
            variant="dark"
            size="md"
            show={!!currentOrder}
            onHide={() => setCurrentOrder(null)}
        >
            <Modal.Body style={{backgroundColor: "#1f304a", borderRadius: "20px"}}>
                {renderHeader()}
                <br/>
                {renderOrderInfo()}
                <br/>
                <Row>
                    <Col xs={2}></Col>
                    <Col className="text-center">
                        <OrderActionButton
                            currentOrder={currentOrder}
                            loanBalance={loanBalance}
                            collateralBalance={collateralBalance}
                        />
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default CurrentOrderModal;