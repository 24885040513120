import React from 'react';
import {useEthers} from "@usedapp/core";
import {Col, Row} from "react-bootstrap";
import StandardButton from "../StandardButton";
import {useRecoilState, useSetRecoilState} from "recoil";
import {chosenPageAtom, protocolSettingsAtom} from "../../../state/atoms/globalAtoms";
import {DASHBOARD, MAINNET_CHAIN_ID, MY_ACCOUNT, ORDERS_BOOK} from "../../../constants";
import {showNewLoanModalAtom} from "../../../state/atoms/contentAtoms";
import {
    getDAOFeePercent, getHolderNFTs,
    getMaxPartOfLoanAmount,
    getMaxRewardDays, getMinPartOfLoanAmount,
    getSupportedTokens,
    getSupportedTokenSettings, getSupportedTokensWithSettings
} from "../../../contracts/methods/protocolMethods";

const menuStyle = {
    width: "100%",
    minHeight: "70px",
    borderRadius: "20px",
    backgroundColor: "#1c2c42",
    marginBottom: "30px",
    paddingTop: "14px",
    paddingLeft: "14px",
}

const Menu = () => {
    const {account, chainId} = useEthers()
    const [chosenPage, setChosenPage] = useRecoilState(chosenPageAtom)
    const setShowNewLoanModal = useSetRecoilState(showNewLoanModalAtom)
    const [protocolSettings, setProtocolSettings] = useRecoilState(protocolSettingsAtom)

    const changePage = (button) => {
        switch (button) {
            case 1:
                setChosenPage(chosenPage === MY_ACCOUNT ? ORDERS_BOOK : MY_ACCOUNT)
                break
            case 2:
                setChosenPage(chosenPage === DASHBOARD ? ORDERS_BOOK : DASHBOARD)
                break
            default:
                break
        }
    }
    const getProtocolSettings = async () => {
        if (chainId){
            setProtocolSettings({
                ...protocolSettings,
                loading: true
            })
            let tokensSettings = {}
            let supportedTokens
            if (chainId === MAINNET_CHAIN_ID){
                const supportedTokens = await getSupportedTokens(chainId)
                for (let i = 0; i < supportedTokens.length; i++) {
                    const supportedToken = supportedTokens[i]
                    tokensSettings[supportedTokens[i]] = await getSupportedTokenSettings(supportedToken, chainId)
                }

            }else {
                supportedTokens = await getSupportedTokensWithSettings(chainId)
                for (let i = 0; i < supportedTokens.length; i++) {
                    const supportedToken = supportedTokens[i]
                    tokensSettings[supportedToken.tokenAddress] = supportedToken
                }
            }

            const DAOFeePercent = await getDAOFeePercent(chainId)
            setProtocolSettings({
                ...protocolSettings,
                supportedTokens: tokensSettings,
                DAOFeePercent,
                loading: false
            })
        }
    }
    const openNewLoanModal = async () => {
        setShowNewLoanModal(true)
        getProtocolSettings()
    }
    const renderMenuButtonsDesktop = () => {
        return (
            <Row className="text-center">
                <Col>
                    <StandardButton
                        text={chosenPage === MY_ACCOUNT ? ORDERS_BOOK : MY_ACCOUNT}
                        onClick={() => changePage(1)}
                        disabled={!account}
                    />
                </Col>
                <div className="market-br"></div>
                <Col>
                    <StandardButton
                        text={chosenPage === DASHBOARD ? ORDERS_BOOK : DASHBOARD}
                        onClick={() => changePage(2)}
                        disabled={!account}
                        // disabled={true}
                    />
                </Col>
                <Col>
                    <StandardButton
                        text="New Loan"
                        onClick={openNewLoanModal}
                        disabled={!account}
                    />
                </Col>
            </Row>
        )
    }

    const renderMenuButtonsPhone = () => {
        return (
            <>
                <Row className="text-center">
                    <Col>
                        <StandardButton
                            text={chosenPage === MY_ACCOUNT ? ORDERS_BOOK : MY_ACCOUNT}
                            onClick={() => changePage(1)}
                            disabled={!account}
                            class="menu-button"
                        />
                    </Col>
                    <Col>
                        <StandardButton
                            text={chosenPage === DASHBOARD ? ORDERS_BOOK : DASHBOARD}
                            onClick={() => changePage(2)}
                            class="menu-button"
                            disabled={!account}
                            // disabled={true}
                        />
                    </Col>
                </Row>
                <br/>
                <Row className="text-center">
                    <Col>
                        <StandardButton
                            text="New Loan"
                            onClick={openNewLoanModal}
                            // disabled={!account}
                        />
                    </Col>
                </Row>
            </>
        )
    }
    return (
        <div>
            <div className="d-none d-lg-block">
                <div style={menuStyle}>
                    {renderMenuButtonsDesktop()}
                </div>
            </div>
            <div className="d-lg-none">
                <div style={{...menuStyle, minHeight: "125px"}}>
                    {renderMenuButtonsPhone()}
                </div>
            </div>
        </div>

    );
};

export default Menu;