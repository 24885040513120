import {constants, ethers} from "ethers";
import {getProvider, getWETHAddress} from "../../utilities";
import {ERC20Interface, WETHInterface} from "../interfaces/interfaces";


export const getERC20Info = async (tokenAddress, chainId) => {
    const contract = new ethers.Contract(tokenAddress, ERC20Interface, getProvider(chainId))
    let symbol
    let name
    let decimals
    try {
        symbol = await contract.symbol()
    } catch (e) {
        symbol = null
    }
    try {
        name = await contract.name()
    } catch (e) {
        name = null
    }
    try {
        decimals = await contract.decimals()
    } catch (e) {
        decimals = null
    }
    return {
        symbol,
        name,
        decimals,
    }
}

export const getAllowance = async (tokenAddress, owner, spender, chainId) => {
    const contract = new ethers.Contract(tokenAddress, ERC20Interface, getProvider(chainId))
    return await contract.allowance(owner, spender)
}
export const depositWETH  = async (value, signer, chainId) => {
    const contract = new ethers.Contract(getWETHAddress(chainId), WETHInterface, signer)
    return await contract.deposit({value: value})
}
export const sendTokenApprove = async (owner, spender, tokenAddress, signer, amount) => {
    if (amount !== 0){
        amount = constants.MaxUint256
    }
    const contract = new ethers.Contract(tokenAddress, ERC20Interface, signer)
    return await contract.approve(spender, amount)
}
export const getBalanceOf = async (tokenAddress, account, chainId) => {
    const contract = new ethers.Contract(tokenAddress, ERC20Interface, getProvider(chainId))
    return await contract.balanceOf(account)
}