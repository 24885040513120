import React, {useState} from 'react';
import {useEthers, useTokenAllowance} from "@usedapp/core";
import ConnectButton from "../Connect/ConnectButton";
import {ROLE, STATUS, SUPPLY} from "../../../constants";
import {Button} from "react-bootstrap";
import StandardButton from "../StandardButton";
import {cancelOrder, getOrder, liquidateOrder, repayOrder} from "../../../contracts/methods/protocolMethods";
import {catchMetamaskErrors, getAddresses, processTransaction} from "../../../utilities";
import {useSetRecoilState} from "recoil";
import {currentOrderAtom} from "../../../state/atoms/contentAtoms";
import {confirmApproveNotify, confirmNotify} from "../../../toasts/toastsMessageses";
import {constants} from "ethers";
import {sendTokenApprove} from "../../../contracts/methods/ERC20Methods";

const APPROVE_N_TAKE_LOAN = "Approve & Take Loan"
const TAKE_LOAN = "Take Loan"
const APPROVE_N_GIVE_LOAN = "Approve & Give Loan"
const GIVE_LOAN = "Give Loan"

const buttonStyle = {
    width: "100%"
}
const OrderActionButton = ({currentOrder}) => {
    const [waiting, setWaiting] = useState(false)
    const setCurrentOrder = useSetRecoilState(currentOrderAtom)
    const {account, chainId, library} = useEthers()
    const loanAllowance = useTokenAllowance(
        currentOrder ? currentOrder.loanToken.address : constants.AddressZero,
        account ? account : constants.AddressZero,
        getAddresses(chainId).protocolAddress)
    const collateralAllowance = useTokenAllowance(
        currentOrder ? currentOrder.collateralToken.address : constants.AddressZero,
        account ? account : constants.AddressZero,
        getAddresses(chainId).protocolAddress)

    const liquidateOrderHandler = () => {
        confirmNotify()
        setWaiting(true)
        liquidateOrder(currentOrder.id, library.getSigner(account), chainId)
            .then(tx => {
                processTransaction(tx)
                    .then(res => {
                        setWaiting(false)
                        setCurrentOrder(null)
                    })
            })
            .catch(error => {
                console.log(error)
                catchMetamaskErrors(error)
                setWaiting(false)
            })
            .catch(error => {
                console.log(error)
                setWaiting(false)
            })
    }
    const repayOrderHandler = () => {
        confirmNotify()
        setWaiting(true)
        repayOrder(currentOrder.id, library.getSigner(account), chainId)
            .then(tx => {
                processTransaction(tx)
                    .then(res => {
                        setWaiting(false)
                        setCurrentOrder(null)
                    })
            })
            .catch(error => {
                console.log(error)
                catchMetamaskErrors(error)
                setWaiting(false)
            })
            .catch(error => {
                console.log(error)
                setWaiting(false)
            })
    }

    const cancelOrderHandler = () => {
        confirmNotify()
        setWaiting(true)
        cancelOrder(currentOrder.id, library.getSigner(account), chainId)
            .then(tx => {
                processTransaction(tx)
                    .then(res => {
                        setWaiting(false)
                        setCurrentOrder(null)
                    })
            })
            .catch(error => {
                console.log(error)
                catchMetamaskErrors(error)
                setWaiting(false)
            })
            .catch(error => {
                console.log(error)
                setWaiting(false)
            })
    }
    const getOrderHandler = async () => {
        setWaiting(true)
        confirmApproveNotify()
        await getOrder(currentOrder.id, library.getSigner(account), chainId)
            .then(tx => {
                processTransaction(tx)
                    .then(() => {
                        setWaiting(false)
                        setCurrentOrder(null)
                    })
            })
            .catch(error => {
                catchMetamaskErrors(error)
                setWaiting(false)
            })
    }
    const approveHandler = async (approveToken, approveAmount) => {
        setWaiting(true)
        confirmApproveNotify()
        await sendTokenApprove(
            account,
            getAddresses(chainId).protocolAddress,
            approveToken.address,
            library.getSigner(account),
            approveAmount
        )
            .then(tx => {
                processTransaction(tx)
                    .then(() => {
                        setWaiting(false)
                    })
            })
            .catch(error => {
                console.log(error)
                catchMetamaskErrors(error)
                setWaiting(false)
            })
    }

    const renderRepayOrLiquidateButton = () => {

        if (currentOrder.lender === account) {

            return <StandardButton text="Liquidate"
                                   loading={waiting}
                                   onClick={liquidateOrderHandler}
                                   disabled={currentOrder.duration.getJSRepayEndsTimestamp() >= new Date().getTime()}
            />
        } else if (currentOrder.borrower === account) {
            if (new Date().getTime() >= currentOrder.duration.getJSRepayEndsTimestamp()) {
                return <Button disabled variant="outline-danger" style={buttonStyle}>Expired</Button>
            }
            return <StandardButton text={loanAllowance && loanAllowance.gte(currentOrder.loanAmount) ? "Repay" : "Approve & Repay"}
                                   loading={waiting}
                                   onClick={loanAllowance && loanAllowance.gte(currentOrder.loanAmount)
                                       ? repayOrderHandler
                                       : () => approveHandler(currentOrder.loanToken, currentOrder.loanAmount)}
                                   disabled={currentOrder.duration.getJSRepayStartsTimestamp() >= new Date().getTime()}
            />
        }
    }

    const renderAccountOrderActionButton = () => {
        switch (currentOrder.status) {
            case STATUS.OPEN:
                return <StandardButton text="Cancel" loading={waiting} onClick={cancelOrderHandler}/>
            case STATUS.WORKING:
                return renderRepayOrLiquidateButton()
            default:
                return <ConnectButton/>
        }
    }
    const renderGetOrderButton = () => {
        if (currentOrder.status === STATUS.WORKING) {
            return <Button disabled variant="outline-danger" style={buttonStyle}>Working</Button>
        }
        let buttonText = ""
        let workToken
        let workAmount
        let allowance
        if (new Date().getTime() >= currentOrder.duration.getJSRepayEndsTimestamp()) {
            return <Button disabled variant="outline-danger" style={buttonStyle}>Expired</Button>
        } else {
            if (currentOrder.role === ROLE.SUPPLY) {
                workToken = currentOrder.collateralToken
                workAmount = currentOrder.collateralAmount
                allowance = collateralAllowance

                if (allowance && allowance.gte(workAmount)) {
                    buttonText = TAKE_LOAN
                } else {
                    buttonText = APPROVE_N_TAKE_LOAN
                }
            } else {
                workToken = currentOrder.loanToken
                workAmount = currentOrder.loanAmount
                allowance = loanAllowance
                if (allowance && allowance.gte(workAmount)) {
                    buttonText = GIVE_LOAN

                } else {
                    buttonText = APPROVE_N_GIVE_LOAN
                }
            }
        }
        return <StandardButton text={buttonText}
                               loading={waiting}
                               onClick={allowance && allowance.gte(workAmount)
                                   ? getOrderHandler
                                   : () => approveHandler(workToken, workAmount)}
                               disabled={new Date().getTime() >= currentOrder.duration.getJSRepayEndsTimestamp()}
        />
    }
    const renderOrderActionButton = () => {

        if (currentOrder.status === STATUS.CLOSED) {
            return <Button disabled variant="outline-danger" style={buttonStyle}>Closed</Button>
        }
        if (currentOrder.status === STATUS.CANCELED) {
            return <Button disabled variant="outline-danger" style={buttonStyle}>Canceled</Button>
        }
        if (currentOrder.lender === account || currentOrder.borrower === account) {
            return renderAccountOrderActionButton()
        } else {
            return renderGetOrderButton()
        }
    }

    return (
        <div>
            {account && currentOrder
                ? renderOrderActionButton()
                : <ConnectButton/>}
        </div>
    );
};

export default OrderActionButton;