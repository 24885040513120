import React, {useEffect, useState} from 'react';
import {useRecoilState} from "recoil";
import {useEthers} from "@usedapp/core";
import {MS_PER_DAY, STATUS} from "../../../constants";
import {Col, Row} from "react-bootstrap";
import StandardButton from "../../global/StandardButton";
import {cancelOrder, liquidateOrder, repayOrder} from "../../../contracts/methods/protocolMethods";
import {catchMetamaskErrors, getAddresses, processTransaction} from "../../../utilities";
import {getAllowance, sendTokenApprove} from "../../../contracts/methods/ERC20Methods";

const OrderAction = ({order}) => {
    const [disabledButton, setDisabledButton] = useState(true)
    const [intervalId, setIntervalId] = useState(null)
    const [showApproveModal, setShowApproveModal] = useState(false)
    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const [waiting, setWaiting] = useState(false)
    // const [lastOrderTimestamp, setLastOrderTimestamp] = useRecoilState(lastOrderIdAtom)


    const {account, library, chainId} = useEthers()

    const accountIsLender = order.lender === account
    const accountIsBorrower = order.borrower === account


    const now = new Date().getTime()
    const repayStarts = order.duration.getJSRepayStartsTimestamp()
    const repayEnds = order.duration.getJSRepayEndsTimestamp()
    const status = order.status
    const role = order.role

    const getDeadlineTime = () => {
        let time
        if (accountIsLender) {
            time = repayEnds - Date.now()
        } else if (accountIsBorrower) {
            if (repayStarts > Date.now()) {
                time = repayStarts - Date.now()
            } else {
                time = repayEnds - Date.now()
            }
        }
        return time
    }

    const getTime = () => {
        let time = getDeadlineTime()


        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    }
    useEffect(() => {
        const deadlineTime = getDeadlineTime()

        let interval
        if (intervalId === null) {
            if (deadlineTime > 0) {
                if (Math.floor(deadlineTime / (1000 * 60 * 60 * 24)) === 0) {
                    interval = setInterval(() => getTime(), 1000)
                    setIntervalId(interval)
                } else {
                    const timeout = deadlineTime - MS_PER_DAY

                    getTime()
                    setTimeout(() => {
                        interval = setInterval(() => getTime(), 1000)
                        setIntervalId(interval)
                    }, timeout)
                }

            } else {
                getTime()
            }

        }
        return () => {
            clearInterval(interval)
            setIntervalId(null)
        }
    }, [])

    const textRemain = () => {
        if (accountIsLender) {
            if (getDeadlineTime() >= 0) {
                if (order.status === STATUS.WORKING) {
                    if (!disabledButton) {
                        setDisabledButton(true)
                    }
                } else if (order.status === STATUS.OPEN) {
                    if (disabledButton) {
                        setDisabledButton(false)
                    }
                }

                if (days > 0) {
                    if (days === 1) {
                        return `repay ends in ${days} day`
                    }
                    return `repay ends in ${days} days`
                } else {

                    return `repay ends in ${hours > 9 ? hours : "0" + hours}:${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds}`
                }
            } else {
                if (order.status === STATUS.WORKING) {
                    if (disabledButton) {
                        setDisabledButton(false)
                    }
                } else if (order.status === STATUS.OPEN) {
                    if (disabledButton) {
                        setDisabledButton(false)
                    }
                }

                if (intervalId) {
                    clearInterval(intervalId)
                    setIntervalId(null)
                }

                return "expired"
            }
        } else if (accountIsBorrower) {
            if (getDeadlineTime() >= 0) {
                if (order.status === STATUS.OPEN) {
                    if (disabledButton) {
                        setDisabledButton(false)
                    }
                } else if (order.status === STATUS.WORKING) {
                    if (repayStarts < now && now < repayEnds) {
                        if (disabledButton) {
                            setDisabledButton(false)
                        }
                    } else {
                        if (!disabledButton) {
                            setDisabledButton(true)
                        }
                    }
                }


                if (repayStarts > now) {
                    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                        return "loading..."
                    }
                    if (days > 0) {
                        if (days === 1) {
                            return `repay starts in ${days} day`
                        }
                        return `repay starts in ${days} days`
                    } else {

                        return `repay starts in ${hours > 9 ? hours : "0" + hours}:${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds}`
                    }
                } else if (repayEnds >= now) {

                    if (days > 0) {
                        if (days === 1) {
                            return `repay ends in ${days} day`
                        }
                        return `repay ends in ${days} days`
                    } else {
                        return `repay ends in ${hours > 9 ? hours : "0" + hours}:${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds}`
                    }

                }
            } else {

                if (order.status === STATUS.WORKING) {

                    if (!disabledButton) {
                        setDisabledButton(true)
                    }
                } else if (order.status === STATUS.OPEN) {
                    if (disabledButton) {
                        setDisabledButton(false)
                    }
                }

                if (intervalId) {
                    clearInterval(intervalId)
                    setIntervalId(null)
                }

                return "expired"
            }


        }
    }



    const activeButtonHandler = async () => {
        setWaiting(true)
        if (order.status === STATUS.OPEN) {
            cancelOrder(order.id, library.getSigner(account), chainId)
                .then(tx => {
                    processTransaction(tx)
                        .then(res => {
                            if (res) {
                                setWaiting(false)

                            } else {
                                setWaiting(false)
                            }
                        })
                })
                .catch(error => {
                    console.log(error)
                    catchMetamaskErrors(error)
                    setWaiting(false)
                })
            .catch(error => {
                console.log(error)
            })
        }
        if (accountIsLender) {
            if (order.status === STATUS.WORKING && repayEnds < now) {
                liquidateOrder(order.id, library.getSigner(account), chainId)
                    .then(tx => {
                        processTransaction(tx)

                            .then(res => {
                                if (res) {
                                    setWaiting(false)

                                } else {
                                    setWaiting(false)
                                }
                            })
                    })
                    .catch(error => {
                        console.log(error)
                        catchMetamaskErrors(error)
                        setWaiting(false)
                    })
            }
        } else if (accountIsBorrower) {
            if (order.status === STATUS.WORKING && repayStarts < now < repayEnds) {

                const allowance  = await getAllowance(order.loanToken.address, account, getAddresses(chainId).protocolAddress, chainId)
                if (allowance.lt(order.loanAmount)){
                    setShowApproveModal(true)
                    await sendTokenApprove(
                        account,
                        getAddresses(chainId).protocolAddress,
                        order.loanToken.address,
                        library.getSigner(account),
                        order.loanAmount
                    )
                        .then(tx => {
                            processTransaction(tx)

                                .then(res => {
                                    if (res) {
                                        setWaiting(false)
                                        setShowApproveModal(false)
                                    } else {
                                        setWaiting(false)
                                        setShowApproveModal(false)
                                    }
                                })
                        })
                        .catch(error => {
                            console.log(error)
                            catchMetamaskErrors(error)
                            setWaiting(false)
                            setShowApproveModal(false)
                        })
                }else {
                    repayOrder(order.id, library.getSigner(account))
                        .then(tx => {
                            processTransaction(tx)

                                .then(res => {
                                    if (res) {
                                        setWaiting(false)

                                    } else {
                                        setWaiting(false)
                                    }
                                })
                        })
                        .catch(error => {
                            console.log(error)
                            catchMetamaskErrors(error)
                            setWaiting(false)
                        })
                }



            }
        }

    }
    const renderAction = () => {
        let buttonText
        if (accountIsLender) {

            if (order.status === STATUS.OPEN) {
                buttonText = "Cancel"
            } else if (order.status === STATUS.WORKING) {
                buttonText = "Liquidate"
            }
            return (
                <div>
                    <Row>
                        <Col>
                            <StandardButton loading={waiting} text={buttonText} disabled={disabledButton}
                            onclick={() => activeButtonHandler()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={days <= 0 && hours <= 0 && minutes < 10
                            ? {fontSize: 10, color: "#CE272F"}
                            : {fontSize: 10, color: "#9597a1"}}>
                            {textRemain()}
                        </Col>
                    </Row>
                </div>
            )
        } else if (accountIsBorrower) {
            if (order.status === STATUS.OPEN) {
                buttonText = "Cancel"
            } else if (order.status === STATUS.WORKING) {
                buttonText = "Repay"
            }

            return (
                <div>
                    <Row>
                        <Col>
                            <StandardButton loading={waiting} text={buttonText} disabled={disabledButton}
                                            onclick={() => activeButtonHandler()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={days <= 0 && hours <= 0 && minutes < 10
                            ? {fontSize: 10, color: "#CE272F"}
                            : {fontSize: 10, color: "#9597a1"}}>
                            {textRemain()}
                        </Col>
                    </Row>
                </div>
            )
        }

    }
    return (
        <div>
            {renderAction()}
            {/*<Modal*/}
            {/*    variant="dark"*/}
            {/*    size="md"*/}
            {/*    show={showApproveModal}*/}
            {/*    onHide={() => setShowApproveModal(false)}*/}
            {/*    enforceFocus={false}*/}
            {/*>*/}
            {/*    <Modal.Body>*/}
            {/*        <div style={orderModalHeaderStyle}>*/}
            {/*            <Row>*/}
            {/*                <Col className="text-start">Approve</Col>*/}
            {/*                <Col className="text-end">*/}
            {/*                    <FontAwesomeIcon*/}
            {/*                        style={modalCloseIconStyle}*/}
            {/*                        icon={faXmark}*/}
            {/*                        color="#9597a1" size="sm"*/}
            {/*                        onClick={() => setShowApproveModal(false)}*/}
            {/*                    />*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </div>*/}
            {/*        <br/>*/}
            {/*        <Row>*/}
            {/*            <Col className="text-center">*/}
            {/*                <Spinner*/}
            {/*                    as="span"*/}
            {/*                    animation="border"*/}
            {/*                    size="lg"*/}
            {/*                    role="status"*/}
            {/*                    aria-hidden="true"*/}
            {/*                />*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*        <br/>*/}
            {/*        <br/>*/}
            {/*        <Row>*/}
            {/*            <Col className="text-center"><h5>*/}
            {/*                Waiting for approve {makeHumanAmount(formatAmount(order.loanAmount.bigAmount, order.loanToken.decimals))} {order.loanToken.symbol}*/}
            {/*            </h5></Col>*/}
            {/*        </Row>*/}
            {/*        <br/>*/}
            {/*    </Modal.Body>*/}
            {/*</Modal>*/}
        </div>
    );
};

export default OrderAction;