import {toast} from "react-toastify";

export const successNotify = () => toast("Success.")
export const errorNotify = () => toast("Something went wrong.")
export const switchNetworkNotify = () => toast("Connect to wallet !")
export const insufficientBalanceNotify = () => toast("Insufficient balance.")
export const insufficientFundsNotify = () => toast("Insufficient funds.")
export const transactionRejectedNotify = () => toast("Transaction rejected.")
export const wrongNonceNotify = () => toast("Wrong nonce.")
export const minStartRepayNotify = () => toast("Minimum time: 10 minutes from now.")
export const confirmSwitchNotify = () => toast("Confirm the switch in the wallet.")
export const confirmApproveNotify = () => toast("Confirm approve in the wallet.")
export const confirmNotify = () => toast("Confirm in the wallet.")
export const insufficientNFTBalance = () => toast("Insufficient NFT balance.")