import React from 'react';

const NameOfField = (props) => {

    return (
        <div style={{display: "inline-block"}}>
            <div className="d-none d-lg-block">
                <div style={{color: "#9597a1"}}>
                    {props.text}
                </div>
            </div>
            <div className="d-lg-none">
                <div style={{color: "#9597a1", fontSize: 15}}>
                    {props.text}
                </div>
            </div>
        </div>
    )
};

export default NameOfField;