import React, {useEffect, useState} from 'react';
import SubHeader from "../../Header/SubHeader";
import {
    mainCardBodyStyle,
    marketsCardHeaderStyle,
    marketsCardStyle,
    marketsItemHoveredStyle, marketsItemStyle
} from "../../../styles/contentStyles";
import {Card, Col, Row} from "react-bootstrap";
import {useEthers} from "@usedapp/core";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {allOrdersSelector} from "../../../state/selectors/globalSelectors";
import {currentOrderAtom} from "../../../state/atoms/contentAtoms";
import Order from "../OrdersBook/Order";

const MyAccount = () => {
    const allOrders = useRecoilValue(allOrdersSelector)
    const [walletOrders, setWalletOrders] = useState([])
    const [orderIndex, setOrderIndex] = useState(null)
    const setCurrentOrder = useSetRecoilState(currentOrderAtom)


    const {account, library} = useEthers()

    useEffect(() => {
        if (account) {
            const orders = allOrders
                .filter(order => order.borrower === account || order.lender === account)
            setWalletOrders(orders)
        }
    }, [allOrders])

    return (
        <div>
            <SubHeader/>
            <Card style={marketsCardStyle}>
                <Card.Header style={marketsCardHeaderStyle}>
                    <Row>
                        <Col xl={9} lg={8} md={7} sm={6}
                             xs={12}>Orders</Col>
                        <Col xl={3} lg={4} md={5} sm={6} xs={12}>
                        </Col>

                    </Row>
                </Card.Header>
                <Card.Body
                    style={mainCardBodyStyle}
                >
                    <div className="d-none d-lg-block">
                        <Row style={{paddingBottom: 10}} className="text-center">
                            <Col xs={3}>Loan Amount</Col>
                            <Col xs={3}>Collateral Amount</Col>
                            <Col xs={2}>Lender Fee</Col>
                            <Col xs={4}></Col>
                        </Row>
                        {walletOrders && walletOrders
                            .filter(order => order.status < 2)
                            .map(order => {
                                return(
                                    <div
                                        key={order["id"]}
                                        style={orderIndex === order["id"]
                                            ? {...marketsItemHoveredStyle, cursor: "pointer", transform: "none"}
                                            : marketsItemStyle}
                                        onMouseEnter={() => setOrderIndex(order["id"])}
                                        onMouseLeave={() => setOrderIndex(null)}
                                        onClick={() => setCurrentOrder(order)}
                                    >
                                        <Order order={order}/>
                                    </div>
                                )
                            })}
                    </div>
                    <div className="d-lg-none" style={{minWidth: "1000px", whiteSpace: "nowrap"}}>
                        <Row style={{paddingBottom: 10}} className="text-center">
                            <Col xs={3}>Loan Amount</Col>
                            <Col xs={3}>Collateral Amount</Col>
                            <Col xs={2}>Lender Fee</Col>
                            <Col xs={4}></Col>
                        </Row>
                        {walletOrders && walletOrders
                            .filter(order => order.status < 2)
                            .map(order => {
                                return(
                                    <div
                                        key={order["id"]}
                                        style={orderIndex === order["id"]
                                            ? {...marketsItemHoveredStyle, cursor: "pointer", transform: "none"}
                                            : marketsItemStyle}
                                        onMouseEnter={() => setOrderIndex(order["id"])}
                                        onMouseLeave={() => setOrderIndex(null)}
                                        onClick={() => setCurrentOrder(order)}
                                    >
                                        <Order order={order}/>
                                    </div>
                                )
                            })}
                    </div>

                </Card.Body>
            </Card>
        </div>
    );
};

export default MyAccount;