import {selector} from "recoil";
import {allOrdersAtom, tokenPricesAtom, tokensAtom} from "../atoms/globalAtoms";
import {Duration, Order, Token} from "../../Classes"
import {BigNumber} from "ethers";

export const allOrdersSelector = selector({
    key: "allOrdersSelector",
    get: ({get}) => {
        const allOrdersData = get(allOrdersAtom)
        const tokensData = get(tokensAtom)
        const tokensPrices = get(tokenPricesAtom)
        if (allOrdersData && tokensData && allOrdersData.chainId === tokensData.chainId) {
            const additionalOrders = []
            allOrdersData.orders.forEach(order => {
                const loanToken = tokensData.tokens.find(token => token.address === order["loanToken"])
                const collateralToken = tokensData.tokens.find(token => token.address === order["collateralToken"])

                let loanTokenObj = undefined
                let collateralTokenObj = undefined

                if (loanToken && collateralToken){
                    let loanTokenPriceData = undefined
                    let collateralTokenPriceData = undefined
                    if (tokensPrices){
                        loanTokenPriceData = tokensPrices.prices.find(price => price.token === loanToken["address"])
                        collateralTokenPriceData = tokensPrices.prices.find(price => price.token === collateralToken["address"])
                    }
                    loanTokenObj = new Token(
                        loanToken["address"],
                        loanToken["name"],
                        loanToken["symbol"],
                        loanToken["decimals"],
                        loanToken["img"],
                        loanTokenPriceData ? loanTokenPriceData.price : BigNumber.from("0")
                    )
                    collateralTokenObj = new Token(
                        collateralToken["address"],
                        collateralToken["name"],
                        collateralToken["symbol"],
                        collateralToken["decimals"],
                        collateralToken["img"],
                        collateralTokenPriceData ? collateralTokenPriceData.price : BigNumber.from("0")
                    )
                    const durationObj = new Duration(
                        order["timestamps"][0],
                        order["timestamps"][1]
                    )
                    const orderObj = new Order(
                        order["id"],
                        order["lender"],
                        order["borrower"],
                        loanTokenObj,
                        order["loanAmount"],
                        collateralTokenObj,
                        order["collateralAmount"],
                        order["lenderFeeAmount"],
                        durationObj,
                        order["rewardAmount"],
                        order["NFTId"],
                        order["role"],
                        order["status"],
                        order["openTimestamp"],
                    )
                    additionalOrders.push(orderObj)
                } // else TODO check new address
            })
            return additionalOrders
        }else return []
    }
})