import React, {useState} from 'react';
import "./newLoan.css"
import DatePicker from 'react-mobile-datepicker';
import {Col, Row} from "react-bootstrap";
import StandardButton from "../StandardButton";

const MobileDatetimePicker = (props) => {
    const [time, setTime] = useState(new Date())
    const [showMobileDatetimePicker, setShowMobileDatetimePicker] = useState(false)


    const handleSelect = (time) => {
        setTime(time)
        props.setDate(time)
        setShowMobileDatetimePicker(false)
    }
    const monthMap = {
        '1': 'Jan',
        '2': 'Feb',
        '3': 'Mar',
        '4': 'Apr',
        '5': 'May',
        '6': 'Jun',
        '7': 'Jul',
        '8': 'Aug',
        '9': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
    };
    const config = {
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        },
        'month': {
            format: value => monthMap[value.getMonth() + 1],
            caption: 'Mon',
            step: 1,
        },
        'date': {
            format: 'D',
            caption: 'Day',
            step: 1,
        },
        'hour': {
            format: 'hh',
            caption: 'Hour',
            step: 1,
        },
        'minute': {
            format: 'mm',
            caption: 'Min',
            step: 1,
        },
    }
    return (
        <div>
            <Row style={{width: "100%", paddingLeft: 10}}>
                <Col className="text-start loan-repay-header-text">{props.text}</Col>
                {/*<Col xs={7} className="text-end loan-repay-header-date">*/}
                {/*    {new Date(props.initialDate).toLocaleString([], {*/}
                {/*        year: "numeric",*/}
                {/*        month: "2-digit",*/}
                {/*        day: "2-digit",*/}
                {/*        hour: "2-digit",*/}
                {/*        minute: "2-digit"*/}
                {/*    })}*/}
                {/*</Col>*/}
            </Row>
            <Row style={{border: "1px solid #454F5B", height: 60, borderRadius: 12, width: "100%", margin: "0 auto"}}>
                <Col>
                    <StandardButton
                        class="date-time-Button-text"
                        text={new Date(props.initialDate).toLocaleString([], {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit"
                        })}
                        onClick={() => setShowMobileDatetimePicker(true)}
                        style={{width: "70%", marginTop: 9}}
                    />
                </Col>
            </Row>
            <DatePicker
                headerFormat="YYYY/MM/DD hh:mm"
                dateConfig={config}
                confirmText="Confirm"
                cancelText="Cancel"
                theme="android-dark"
                value={time}
                isOpen={showMobileDatetimePicker}
                onSelect={handleSelect}
                onCancel={() => setShowMobileDatetimePicker(false)}
                min={props.min}
            />
        </div>
    );
};

export default MobileDatetimePicker;