import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Form} from "react-bootstrap";
import "./dashboard.css"
import {useContractFunction, useEtherBalance, useEthers, useTokenAllowance, useTokenBalance} from "@usedapp/core";
import {catchMetamaskErrors, getAddresses, makeHumanAmount, processTransaction} from "../../../utilities";
import {constants, utils} from "ethers";
import PAW from "../../../assets/svg/TWOPAW.svg"
import {ARBITRUM_CHAIN_ID, BSC_CHAIN_ID, MAINNET_CHAIN_ID, SUPPLY} from "../../../constants";
import StandardButton from "../../global/StandardButton";
import ConnectButton from "../../global/Connect/ConnectButton";
import {
    buyNFT, buyNFTForETH, getLowestNotLockedNFTId,
    getNFTContractWithProvider, getOwnerIds,
    isApprovedForAll, sellNFT,
    setApprovalForAll
} from "../../../contracts/methods/protocolMethods";
import {sendTokenApprove} from "../../../contracts/methods/ERC20Methods";
import {
    confirmApproveNotify,
    confirmNotify,
    errorNotify,
    insufficientNFTBalance
} from "../../../toasts/toastsMessageses";

const NftMarket = () => {
    const [NFTAmount, setNFTAmount] = useState("0")
    const [maxColor, setMaxColor] = useState("#3E6FE1")
    const [isNFTApproved, setIsNftApproved] = useState(false)
    const [buyWaiting, setBuyWaiting] = useState(false)
    const [sellWaiting, setSellWaiting] = useState(false)
    // const [disabledBuyButton, setDisabledBuyButton] = useState(false)
    // const [disabledSellButton, setDisabledSellButton] = useState(false)

    const {account, chainId, library} = useEthers()

    let buyPrice
    let sellPrice
    let symbol
    if (chainId === MAINNET_CHAIN_ID) {
        buyPrice = "40000"
        sellPrice = "29999"
        symbol = "TWOPAW"
    } else if (chainId === ARBITRUM_CHAIN_ID) {
        buyPrice = "40000"
        sellPrice = "5000"
        symbol = "TWOPAW"
    } else if (chainId === BSC_CHAIN_ID) {
        buyPrice = "1"
        sellPrice = "0"
        symbol = "BNB"
    }
    let protocolNFTBalance = useTokenBalance(getAddresses(chainId).NFTAddress, getAddresses(chainId).protocolAddress)
    let accountNFTBalance = useTokenBalance(getAddresses(chainId).NFTAddress, account)
    let accountTokenBalance = useTokenBalance(getAddresses(chainId).tokenAddress, account)
    let accountBNBBalance = useEtherBalance(account)
    let tokenAllowance = useTokenAllowance(getAddresses(chainId).tokenAddress, account, getAddresses(chainId).protocolAddress)
    protocolNFTBalance = protocolNFTBalance ? utils.formatUnits(protocolNFTBalance, 0) : "0"
    accountNFTBalance = accountNFTBalance ? utils.formatUnits(accountNFTBalance, 0) : "0"
    accountTokenBalance = accountTokenBalance ? utils.formatUnits(accountTokenBalance, 18) : "0"
    accountBNBBalance = accountBNBBalance ? utils.formatEther(accountBNBBalance) : "0"
    tokenAllowance = tokenAllowance ? utils.formatUnits(tokenAllowance, 18) : "0"
    let buyAmount = NFTAmount === "0" || NFTAmount === "" ? buyPrice : (Number(NFTAmount) * Number(buyPrice)).toString()
    let sellAmount = NFTAmount === "0" || NFTAmount === "" ? sellPrice : (Number(NFTAmount) * Number(sellPrice)).toString()
    let disabledBuyButton
    let disabledSellButton
    if (chainId === BSC_CHAIN_ID){
        if (NFTAmount === "0" || NFTAmount === "") {
            disabledSellButton = true
            disabledBuyButton = true
        }else {
            if (Number(NFTAmount) > Number(accountNFTBalance)) {
                disabledSellButton = true
            }
            if (Number(NFTAmount) > Number(accountBNBBalance)){
                disabledBuyButton = true
            }
        }
    }else {
        if (NFTAmount === "0" || NFTAmount === "") {
            disabledSellButton = true
            disabledBuyButton = true
        } else {
            if (Number(NFTAmount) > Number(accountNFTBalance)) {
                disabledSellButton = true
            }
            if (Number(buyAmount) > Number(accountTokenBalance)) {
                disabledBuyButton = true
            }
        }
    }



    useEffect(() => {
        if (chainId) {
            isApprovedForAll(account, getAddresses(chainId).protocolAddress, chainId)
                .then(res => {
                    setIsNftApproved(res)
                })
                .catch(error => {
                    catchMetamaskErrors(error)
                })
        }

    }, [chainId, sellWaiting])

    let buyButtonText
    let sellButtonText
    if (chainId === MAINNET_CHAIN_ID || chainId === ARBITRUM_CHAIN_ID) {
        buyButtonText = parseFloat(tokenAllowance) >= parseFloat(buyAmount) ? "Buy" : "Approve & Buy"
        sellButtonText = isNFTApproved ? "Sell" : "Approve & Sell"
    } else {
        buyButtonText = "Buy"
        sellButtonText = "Sell"
    }

    const tokenApproveHandler = async () => {
        setBuyWaiting(true)
        confirmApproveNotify()
        await sendTokenApprove(
            account,
            getAddresses(chainId).protocolAddress,
            getAddresses(chainId).tokenAddress,
            library.getSigner(account),
            constants.MaxUint256
        )
            .then(tx => {
                processTransaction(tx)
                    .then(() => {
                        setBuyWaiting(false)
                    })
            })
            .catch(error => {
                catchMetamaskErrors(error)
                setBuyWaiting(false)
            })
    }
    const buyNFTForETHHandler = async () => { // TODO test afler
        try {
            const protocolIds = await getOwnerIds(parseInt(NFTAmount), getAddresses(chainId).protocolAddress, chainId)
            console.log(protocolIds)
            if (parseInt(NFTAmount) === protocolIds.length) {
                console.log("if")
                setBuyWaiting(true)
                confirmNotify()
                const value = utils.parseEther(NFTAmount)

                await buyNFTForETH(protocolIds, library.getSigner(account), chainId, value)
                    .then(tx => {
                        console.log(tx)
                        processTransaction(tx)
                            .then(() => {
                                setBuyWaiting(false)
                            })
                    })
                    .catch(error => {
                        console.log(error)
                        catchMetamaskErrors(error)
                        setBuyWaiting(false)
                    })
            } else {
                insufficientNFTBalance()
                setBuyWaiting(false)
            }
        } catch (e) {
            console.log(e)
            errorNotify()
            setBuyWaiting(false)
        }
    }
    const buyNFTHandler = async () => {
        if (chainId === BSC_CHAIN_ID){
            console.log("bsc")
            await buyNFTForETHHandler()
        }else {
            try {
                const protocolIds = await getOwnerIds(parseInt(NFTAmount), getAddresses(chainId).protocolAddress, chainId)
                if (parseInt(NFTAmount) === protocolIds.length) {
                    setBuyWaiting(true)
                    confirmNotify()
                    await buyNFT(protocolIds, library.getSigner(account), chainId)
                        .then(tx => {
                            console.log(tx)
                            processTransaction(tx)
                                .then(() => {
                                    setBuyWaiting(false)
                                })
                        })
                        .catch(error => {
                            catchMetamaskErrors(error)
                            setBuyWaiting(false)
                        })
                } else {
                    insufficientNFTBalance()
                    setBuyWaiting(false)
                }
            } catch (e) {
                errorNotify()
                setBuyWaiting(false)
            }
        }


    }
    const NFTApproveHandler = async () => {
        setSellWaiting(true)
        confirmApproveNotify()
        await setApprovalForAll(getAddresses(chainId).protocolAddress, true, library.getSigner(account), chainId)
            .then(tx => {
                processTransaction(tx)
                    .then(() => {
                        setSellWaiting(false)
                    })
            })
            .catch(error => {
                catchMetamaskErrors(error)
                setSellWaiting(false)
            })
    }
    const sellNFTHandler = async () => {
        try {
            const accountIds = await getOwnerIds(parseInt(NFTAmount), account, chainId)
            console.log(utils.formatUnits(accountIds[0], 0))
            setSellWaiting(true)
            confirmApproveNotify()
            await sellNFT(accountIds, library.getSigner(account), chainId)
                .then(tx => {
                    console.log(tx)
                    processTransaction(tx)
                        .then(() => {
                            setSellWaiting(false)
                        })
                })
                .catch(error => {
                    catchMetamaskErrors(error)
                    setSellWaiting(false)
                })
        } catch (e) {
            console.log(e)
            errorNotify()
            setSellWaiting(false)
        }
    }
    return (
        <div style={{
            borderRadius: 15,
            height: "100%",
            padding: 8,
            backgroundColor: "#182539"
        }}>
            <Row>
                <Col xs={5} style={{paddingLeft: 25}} className="header-text">
                    PAWDAO Trade
                </Col>
                <Col xs={7} style={{color: "#9597a1", paddingRight: 20, paddingTop: 5}}
                     className="text-end balance-text">
                    Protocol Balance: {protocolNFTBalance} PAWDAO
                </Col>
            </Row>
            <div style={{position: "relative"}}>
                <Row>
                    <div style={{
                        backgroundColor: "rgba(255, 255, 255, 0.05)",
                        width: "95%",
                        margin: "0 auto",
                        height: 90,
                        borderRadius: 16,
                        marginBottom: 5
                    }}>
                        <div style={{padding: 18}}>
                            <Row style={{fontSize: 20, marginBottom: 3}}>
                                <Col style={{height: 40, padding: 0}} xs={9}>
                                    <Form.Control
                                        autoComplete="off"
                                        id="swap-amount"
                                        size="sm"
                                        type="text"
                                        className="new-amount"
                                        value={NFTAmount}
                                        onFocus={() => setNFTAmount("")}
                                        onChange={(event) => {
                                            if (event.target.value.match("^[0-9]*$")) {
                                                if (Number(event.target.value) <= Number(protocolNFTBalance)) {
                                                    setNFTAmount(event.target.value)
                                                }
                                            }
                                        }}

                                    />
                                </Col>
                                <Col className="text-end header-text" xs={3} style={{padding: "10px 8px 0 0"}}>
                                    PAWDAO
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-end" style={{fontSize: 12, color: "#9597a1"}}>
                                    Balance: {accountNFTBalance} PAWDAO <span
                                    style={{color: maxColor, cursor: "pointer"}}
                                    onMouseEnter={() => setMaxColor("#2B51A9")}
                                    onMouseLeave={() => setMaxColor("#3E6FE1")}
                                    onClick={() => setNFTAmount("12")}
                                >Max</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Row>
                <Row style={{fontSize: 12, color: "#9597a1", paddingRight: 10}}>
                    {chainId === BSC_CHAIN_ID
                        ? <Col className="text-end">
                            Your BNB Balance: {makeHumanAmount(accountBNBBalance)} BNB
                        </Col>
                        : <Col className="text-end">
                            Your Token Balance: {makeHumanAmount(accountTokenBalance)} TWOPAW
                        </Col>}

                </Row>
                <br/>
                <Row style={{paddingLeft: 10, paddingRight: 10, marginBottom: 16}} className="header-text">
                    <Col style={{color: "#9597a1", paddingTop: 6}} xs={5}>
                        {NFTAmount === "0" || NFTAmount === "" ? "Buy Price" : "Buy " + NFTAmount + " items"}
                    </Col>
                    <Col className="text-end text-truncate" xs={7}>
                        <img src={PAW} alt="PAW" width="30px" height="30px"/> {buyAmount} {symbol}
                    </Col>
                </Row>
                {chainId !== BSC_CHAIN_ID
                    ? <Row style={{paddingLeft: 10, paddingRight: 10}} className="header-text">
                        <Col style={{color: "#9597a1", paddingTop: 6}} xs={5}>
                            {NFTAmount === "0" || NFTAmount === "" ? "Sell Price" : "Sell " + NFTAmount + " items"}
                        </Col>
                        <Col className="text-end text-truncate" xs={7}>
                            <img src={PAW} alt="PAW" width="30px" height="30px"/> {sellAmount} TWOPAW
                        </Col>
                    </Row>
                    : <></>}

                <br/>

            </div>
            {account ?
                <Row>
                    <Col className="text-center">
                        <StandardButton
                            text={buyButtonText}
                            loading={buyWaiting}
                            onClick={buyButtonText === "Approve & Buy" ? tokenApproveHandler : buyNFTHandler}
                            disabled={disabledBuyButton}
                        />
                    </Col>
                    {chainId !== BSC_CHAIN_ID
                    && <Col className="text-center">
                            <StandardButton
                                text={sellButtonText}
                                loading={sellWaiting}
                                onClick={sellButtonText === "Approve & Sell" ? NFTApproveHandler : sellNFTHandler}
                                disabled={disabledSellButton}
                            />
                        </Col>}

                </Row>
                : <Row>
                    <Col xs={2}></Col>
                    <Col xs={8} className="text-center">
                        <ConnectButton/>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            }

        </div>
    );
};

export default NftMarket;