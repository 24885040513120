import React from 'react';
import {useRecoilState, useSetRecoilState} from "recoil";
import {currentOrderAtom, marketItemIndexAtom} from "../../../state/atoms/contentAtoms";
import {marketsItemHoveredStyle, marketsItemStyle} from "../../../styles/contentStyles";
import {ROLE} from "../../../constants";
import {useEthers} from "@usedapp/core";
import Order from "./Order";

const Orders = (props) => {

    const [marketItemIndex, setMarketItemIndex] = useRecoilState(marketItemIndexAtom)
    const setCurrentOrder = useSetRecoilState(currentOrderAtom)

    const {account} = useEthers()

    const openOrderInfo = (order) => {
        setCurrentOrder(order)
    }

    const renderSortedOrders = () => {
        if (props.sortedOrders && props.sortedOrders.length > 0) {
            return (props.sortedOrders
                .map(order => {
                    const orderRole = order.role === ROLE.SUPPLY ? "Supply order" : "Borrow order"
                    return (
                        <div
                            key={order.id}
                            id={"order-" + order.id}
                            style={marketItemIndex === order.id
                                ? marketsItemHoveredStyle
                                : marketsItemStyle}
                            onMouseEnter={() => {
                                setMarketItemIndex(order.id)
                            }}
                            onMouseLeave={() => {
                                setMarketItemIndex(null)
                            }}
                            onClick={() => openOrderInfo(order)}
                        >
                            <div style={{
                                width: 90,
                                position: "absolute",
                                top: -1,
                                left: -1,
                                border: "1px solid #383944",
                                fontSize: 11,
                                color: "#9597a1",
                                borderRadius: "10px 0px",
                                paddingLeft: "0px 10px 0px 10px",
                                textAlign: "center",
                                zIndex: 20
                            }}>
                                {orderRole}
                            </div>
                            {order.getFloatRewardAmount() > 0
                                ? <div
                                    style={{
                                        width: 90,
                                        position: "absolute",
                                        bottom: -1,
                                        right: -1,
                                        border: "1px solid #383944",
                                        fontSize: 11,
                                        color: "#4D9F33",
                                        borderRadius: "10px 0px",
                                        paddingLeft: "0px 10px 0px 10px",
                                        textAlign: "center",
                                    }}
                                >+Reward</div>
                                : <></>}
                            {order.lender === account || order.borrower === account
                                ? <div style={{
                                    width: 90,
                                    position: "absolute",
                                    top: -1,
                                    right: -1,
                                    border: "1px solid #383944",
                                    fontSize: 11,
                                    color: "#9597a1",
                                    borderRadius: "0px 10px",
                                    paddingLeft: "0px 10px 0px 10px",
                                    textAlign: "center",
                                }}>Your order</div>
                                : <></>
                            }
                            <Order order={order}/>
                        </div>
                    )
                }))
        }
    }
    return (
        <div>{renderSortedOrders()}</div>
    );
};

export default Orders;