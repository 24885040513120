import React, {useState} from 'react';
import {useSetRecoilState} from "recoil";
import {connectModalAtom} from "../../../state/atoms/globalAtoms";
import ConnectModal from "./ConnectModal";
import {Button} from "react-bootstrap";
import {useEthers, shortenAddress} from "@usedapp/core";

const ConnectButton = () => {
    const [hoveredButton, setHoveredButton] = useState(false)
    const setConnectModal = useSetRecoilState(connectModalAtom)
    const {account, deactivate} = useEthers()
    return (
        <div>
            {account
                ?
                <Button className="text-truncate"
                    onClick={() => {
                    deactivate()
                    setHoveredButton(false)
                }} variant={hoveredButton ? "danger" : "outline-primary"} size="lg" style={{width: "80%"}}
                        onMouseEnter={() => setHoveredButton(true)}
                        onMouseLeave={() => setHoveredButton(false)}
                >
                    {hoveredButton ? "Disconnect" : shortenAddress(account)}
                </Button>
                :
                <Button onClick={() => setConnectModal(true)} style={{width: "90%"}} variant="outline-primary" size="lg">
                    Connect
                </Button>
            }

            <ConnectModal/>
        </div>
    );
};

export default ConnectButton;