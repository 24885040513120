import {atom} from "recoil";
import {ORDERS_BOOK} from "../../constants";

export const chosenPageAtom = atom({
    key: "chosenPageAtom",
    default: ORDERS_BOOK
})
export const connectModalAtom = atom({
    key: "connectModalAtom",
    default: false
})
export const tokensAtom = atom({
    key: "tokensAtom",
    default: {
        tokens: [
            {
                "id": 0,
                "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                "name": "Tether USD",
                "symbol": "USDT",
                "decimals": 6,
                "img": "https://s3.amazonaws.com/token-icons/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
                "defaultLoanToken": true,
                "defaultCollateralToken": false,
                "chainId": 0
            },
            {
                "id": 1,
                "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
                "name": "Wrapped Ether",
                "symbol": "WETH",
                "decimals": 18,
                "img": "https://cdn.redstone.finance/symbols/eth.svg",
                "defaultLoanToken": false,
                "defaultCollateralToken": true,
                "chainId": 0
            }
        ]
    }
})
export const addressesAtom = atom({
    key: "addressesAtom",
    default: undefined
})
export const allOrdersAtom = atom({
    key: "allOrdersAtom",
    default: undefined
})
export const appInFocusAtom = atom({
    key: "appInFocusAtom",
    default: true
})
export const tokenPricesAtom = atom({
    key: "tokenPricesAtom",
    default: undefined
})
export const protocolSettingsAtom = atom({
    key: "protocolSettingsAtom",
    default: {
        supportedTokens: {},
        loading: false
    }
})
export const lastOrderTimestampAtom = atom({
    key: "lastOrderTimestampAtom",
    default: 1
})
