export const supportedTokensBSC = {
    "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56": {
        address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        denominator: 400,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82": {
        address: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
        denominator: 200,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    }
}