import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    newLoanChooseAtom, ordersInfoModalAtom,
    showNewLoanModalAtom,
} from "../../../state/atoms/contentAtoms";
import {Col, Modal, Row, Form, OverlayTrigger, Popover} from "react-bootstrap";
import {orderModalHeaderStyle} from "../../../styles/contentStyles";
import close from "../../../assets/svg/close.svg";
import info from "../../../assets/svg/info-circle.svg";
import edit from "../../../assets/svg/edit.svg";
import {
    calculateRewardAmountArbitrum,
    calculateRewardAmountMainnet,
    catchMetamaskErrors,
    getAddresses, getLockedCountHolderNFT, getUnlockedHolderNFTId,
    makeHumanAmount,
    processTransaction,
    toUpperFirstLetter
} from "../../../utilities";
import StandardButton from "../StandardButton";
import {
    ARBITRUM_CHAIN_ID,
    BORROW,
    DASHBOARD,
    MAINNET_CHAIN_ID,
    MS_PER_DAY,
    MS_PER_HOUR,
    SUPPLY
} from "../../../constants";
import AmountSettings from "./AmountSettings";
import {chosenPageAtom, protocolSettingsAtom, tokenPricesAtom, tokensAtom} from "../../../state/atoms/globalAtoms";
import {useEthers, useTokenBalance} from "@usedapp/core";
import DesktopDatetimePicker from "./DesktopDatetimePicker";
import MobileDatetimePicker from "./MobileDatetimePicker";
import {confirmApproveNotify, confirmNotify, minStartRepayNotify} from "../../../toasts/toastsMessageses";
import {getTokenPriceQuote, getTokensPrices} from "../../../contracts/methods/oracle";
import {BigNumber, constants, utils} from "ethers";
import ConnectButton from "../Connect/ConnectButton";
import ActionButton from "./ActionButton";
import {sendTokenApprove} from "../../../contracts/methods/ERC20Methods";
import {getLowestNotLockedNFTId, newOrder} from "../../../contracts/methods/protocolMethods";

const NewLoanModal = () => {
    const now = new Date()
    const tokens = useRecoilValue(tokensAtom)
    const protocolSettings = useRecoilValue(protocolSettingsAtom)
    const [showNewLoanModal, setShowNewLoanModal] = useRecoilState(showNewLoanModalAtom)
    const setChosenPage = useSetRecoilState(chosenPageAtom)
    const [showInfoModal, setShowInfoModal] = useRecoilState(ordersInfoModalAtom)

    const [newLoanChoose, setNewLoanChoose] = useRecoilState(newLoanChooseAtom)
    const [loanToken, setLoanToken] = useState(tokens.tokens.find(item => item.defaultLoanToken))
    const [collateralToken, setCollateralToken] = useState(tokens.tokens.find(item => item.defaultCollateralToken))
    const [loanAmount, setLoanAmount] = useState("0")
    const [collateralAmount, setCollateralAmount] = useState("0")
    const [loanTokenPrice, setLoanTokenPrice] = useState("0")
    const [collateralTokenPrice, setCollateralTokenPrice] = useState("0")

    const [lenderFeePercent, setLenderFeePercent] = useState("0")

    const [isReward, setIsReward] = useState(false)
    const [rewardAmount, setRewardAmount] = useState("0")

    const [isLenderFeePopover, setIsLenderFeePopover] = useState(false)

    const [startRepayDate, setRepayStartDate] = useState(new Date(now.getTime() + 10 * 60 * 1000));
    const [endRepayDate, setEndRepayDate] = useState(new Date(now.getTime() + 60 * 60 * 1000));

    const [disabledActionButton, setDisabledActionButton] = useState(false)
    const [waiting, setWaiting] = useState(false)


    const {account, chainId, library} = useEthers()
    const NFTBalance = useTokenBalance(getAddresses(chainId).NFTAddress, account)

    const getRewardAmount = () => {
        const loanTokenSettings = protocolSettings.supportedTokens[loanToken.address]
        if (loanTokenSettings && loanTokenSettings.isRewarded) {

            if (chainId === MAINNET_CHAIN_ID) {
                console.log("protocolSettings", protocolSettings)
                return "0"
                // calculateRewardAmountMainnet(
                //     startRepayDate.getTime(),
                //     loanTokenSettings.denominator,
                //     loanToken.decimals,
                //     utils.parseUnits(loanAmount, loanToken.decimals),
                //     protocolSettings.maxRewardDays
                // )
            }
            // else if (chainId === ARBITRUM_CHAIN_ID) {
            //     const feeAmount = (parseFloat(loanAmount) * parseFloat(lenderFeePercent) / 100).toFixed(5)
            //     const bigFeeAmount = isNaN(feeAmount) ? BigNumber.from("0") : utils.parseUnits(feeAmount, loanToken.decimals)
            //     return calculateRewardAmountArbitrum(
            //         startRepayDate.getTime(),
            //         loanTokenSettings,
            //         utils.parseUnits(loanAmount, loanToken.decimals),
            //         bigFeeAmount,
            //         protocolSettings
            //     )
            // }
        else return "0"

        } else return "0"
    }
    useEffect(() => {
        setLoanToken(tokens.tokens.find(item => item.defaultLoanToken))
        setCollateralToken(tokens.tokens.find(item => item.defaultCollateralToken))
    }, [tokens])
    useEffect(() => {
        if (chainId){
            const tokensArray = [loanToken.address, collateralToken.address]
            getTokensPrices(tokensArray, chainId)
                .then(prices => {
                    setLoanTokenPrice(utils.formatUnits(prices[0].price, 8))
                    setCollateralTokenPrice(utils.formatUnits(prices[1].price, 8))
                })
        }

    }, [loanToken, collateralToken, newLoanChoose, chainId])

    useEffect(() => {
        if (loanAmount === "0" || loanAmount === "" || collateralAmount === "0" || collateralAmount === "") {
            setDisabledActionButton(true)
        } else {
            setDisabledActionButton(false)
        }
    }, [loanAmount, collateralAmount])
    useEffect(() => {
        if (loanAmount === "0" || loanAmount === "") {
            setRewardAmount("0")
        } else {
            setRewardAmount(getRewardAmount())
        }
    }, [loanAmount])
    useEffect(() => {
        setRewardAmount(getRewardAmount())
    }, [startRepayDate])
    useEffect(() => {
        setRewardAmount(getRewardAmount())
    }, [lenderFeePercent])
    useEffect(() => {
        if (startRepayDate.getTime() + MS_PER_HOUR > endRepayDate.getTime()) {
            setEndRepayDate(new Date(startRepayDate.getTime() + MS_PER_HOUR))
        }
    }, [startRepayDate])
    const getPercentOfAmounts = () => {
        if (parseFloat(loanTokenPrice) * parseFloat(loanAmount) > 0) {
            return (parseFloat(collateralTokenPrice) * parseFloat(collateralAmount)) / (parseFloat(loanTokenPrice) * parseFloat(loanAmount)) * 100
        }
    }
    const isTokensRewarded = () => {
        const isLoanTokenRewarded = protocolSettings.supportedTokens[loanToken.address]
            ? protocolSettings.supportedTokens[loanToken.address].isRewarded
            : false
        const isCollateralTokenRewarded = protocolSettings.supportedTokens[collateralToken.address]
            ? protocolSettings.supportedTokens[collateralToken.address].isRewarded
            : false
        return {
            isLoanTokenRewarded,
            isCollateralTokenRewarded,
            isBothTokensRewarded: isLoanTokenRewarded && isCollateralTokenRewarded
        }
    }
    const renderHeader = () => {
        return (
            <div>
                <div className="d-none d-lg-block" style={orderModalHeaderStyle}>
                    <Row>
                        <Col
                            className="text-start">{"Create " + toUpperFirstLetter(newLoanChoose) + " Loan"}</Col>
                        <Col className="text-end">
                            <img src={close} width={25} height={25} alt="close"
                                 style={{marginTop: 0, cursor: "pointer", position: "absolute", top: 10, right: 14}}
                                 onClick={() => setShowNewLoanModal(false)}/>
                        </Col>
                    </Row>
                </div>
                <div className="d-lg-none" style={{...orderModalHeaderStyle, fontSize: 15}}>
                    <Row>
                        <Col
                            className="text-start header-text">{"Create " + toUpperFirstLetter(newLoanChoose) + " Loan"}</Col>
                        <Col className="text-end">
                            <img src={close} width={25} height={25} alt="close"
                                 style={{marginTop: 0, cursor: "pointer", position: "absolute", top: 10, right: 14}}
                                 onClick={() => setShowNewLoanModal(false)}/>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
    const renderLoanChoose = () => {
        return (
            <div>
                <div className="d-none d-lg-block">
                    <Row style={{paddingBottom: 35}}>
                        <Col className="text-center" style={{position: "relative"}}>
                            <Row>
                                <Col xs={2}>
                                    <img src={info} width={25} height={25} alt="info"
                                         style={{position: "absolute", left: 20, top: 3, cursor: "pointer"}}
                                         onClick={() => setShowInfoModal(1)}
                                    />
                                </Col>
                                <Col xs={10}>
                                    <StandardButton
                                        onClick={() => setNewLoanChoose(SUPPLY)}
                                        text="Supply"
                                        active={newLoanChoose === SUPPLY}
                                    />
                                </Col>
                            </Row>

                        </Col>
                        <Col className="text-center" style={{position: "relative"}}>
                            <Row>
                                <Col xs={10}>
                                    <StandardButton
                                        onClick={() => setNewLoanChoose(BORROW)}
                                        text="Borrow"
                                        active={newLoanChoose === BORROW}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <img src={info} width={25} height={25} alt="info"
                                         style={{position: "absolute", right: 17, top: 3, cursor: "pointer"}}
                                         onClick={() => setShowInfoModal(2)}
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
                <div className="d-lg-none">
                    <Row style={{paddingBottom: 35}}>
                        <Col className="text-center" style={{position: "relative"}}>
                            <Row>
                                <Col xs={2}>
                                    <img src={info} width={25} height={25} alt="info"
                                         style={{position: "absolute", left: 20, top: 3, cursor: "pointer"}}
                                         onClick={() => setShowInfoModal(1)}
                                    />
                                </Col>
                                <Col xs={10} style={{paddingRight: 0}}>
                                    <StandardButton
                                        onClick={() => setNewLoanChoose(SUPPLY)}
                                        text="Supply"
                                        active={newLoanChoose === SUPPLY}
                                    />
                                </Col>
                            </Row>

                        </Col>
                        <Col className="text-center" style={{position: "relative"}}>
                            <Row>
                                <Col xs={10} style={{paddingLeft: 0}}>
                                    <StandardButton
                                        onClick={() => setNewLoanChoose(BORROW)}
                                        text="Borrow"
                                        active={newLoanChoose === BORROW}
                                    />
                                </Col>
                                <Col xs={2}>
                                    <img src={info} width={25} height={25} alt="info"
                                         style={{position: "absolute", right: 17, top: 3, cursor: "pointer"}}
                                         onClick={() => setShowInfoModal(2)}
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
    const renderSettings = () => {
        return (
            <div>
                {newLoanChoose === SUPPLY
                    ? <AmountSettings tokens={tokens.tokens} text="Loan Amount"
                                      token={loanToken} setToken={setLoanToken}
                                      amount={loanAmount} setAmount={setLoanAmount}
                                      isCollateral={false}
                                      tokenPrice={loanTokenPrice}
                                      percent={getPercentOfAmounts()}
                                      isTokenRewarded={isTokensRewarded().isLoanTokenRewarded}
                    />
                    : <AmountSettings tokens={tokens.tokens} text="Collateral Amount"
                                      token={collateralToken} setToken={setCollateralToken}
                                      amount={collateralAmount} setAmount={setCollateralAmount}
                                      isCollateral={true}
                                      tokenPrice={collateralTokenPrice}
                                      percent={getPercentOfAmounts()}
                                      isTokenRewarded={isTokensRewarded().isCollateralTokenRewarded}
                    />}
                {newLoanChoose === BORROW
                    ? <AmountSettings tokens={tokens.tokens} text="Loan Amount"
                                      token={loanToken} setToken={setLoanToken}
                                      amount={loanAmount} setAmount={setLoanAmount}
                                      isCollateral={false}
                                      tokenPrice={loanTokenPrice}
                                      percent={getPercentOfAmounts()}
                                      isTokenRewarded={isTokensRewarded().isLoanTokenRewarded}
                    />
                    : <AmountSettings tokens={tokens.tokens} text="Collateral Amount"
                                      token={collateralToken} setToken={setCollateralToken}
                                      amount={collateralAmount} setAmount={setCollateralAmount}
                                      isCollateral={true}
                                      tokenPrice={collateralTokenPrice}
                                      percent={getPercentOfAmounts()}
                                      isTokenRewarded={isTokensRewarded().isCollateralTokenRewarded}
                    />}
                <div className="d-none d-lg-block">
                    <DesktopDatetimePicker
                        initialDate={startRepayDate}
                        text="The repayment period for this credit starts on:"
                        setDate={setRepayStartDate}
                    />
                    <br/>
                    <DesktopDatetimePicker
                        initialDate={endRepayDate}
                        text="Ends on:"
                        setDate={setEndRepayDate}
                    />
                    <br/>
                </div>

                <div className="d-lg-none text-center">
                    <MobileDatetimePicker
                        text="The repayment period for this credit starts on:"
                        initialDate={startRepayDate}
                        setDate={setRepayStartDate}
                        min={startRepayDate}
                    />
                    <br/>
                    <MobileDatetimePicker
                        text="Ends on:"
                        initialDate={endRepayDate}
                        setDate={setEndRepayDate}
                        min={startRepayDate}
                    />
                </div>
                <br/>
            </div>
        )
    }
    const renderLoanAmountInfo = () => {
        return (
            <div>
                <Row>
                    <Col className="new-loan-info-text">
                        Loan Amount
                    </Col>
                    <Col className="text-end text-truncate new-loan-info-value" style={{overflow: "unset"}}>
                        <img
                            style={{marginTop: -5, marginRight: 10}}
                            src={loanToken.img}
                            alt="img"
                            width={20}
                            height={20}
                        />
                        {makeHumanAmount(loanAmount)} {loanToken.symbol}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end">
                        <div style={{fontSize: 10, color: "#9597a1"}}>
                            {loanTokenPrice && loanTokenPrice === "0"
                                ? "price not found"
                                : `eq. ${makeHumanAmount((parseFloat(loanAmount) * parseFloat(loanTokenPrice)).toString())} USD`}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    const renderCollateralAmountInfo = () => {
        return (
            <div>
                <Row>
                    <Col className="new-loan-info-text">
                        Collateral Amount
                    </Col>
                    <Col className="text-end text-truncate new-loan-info-value" style={{overflow: "unset"}}>
                        <img
                            style={{marginTop: -5, marginRight: 10}}
                            src={collateralToken.img}
                            alt="img"
                            width={20}
                            height={20}
                        />
                        {collateralAmount
                            ? makeHumanAmount(collateralAmount)
                            : "0"} {collateralToken.symbol}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end">
                        <div style={{fontSize: 10, color: "#9597a1"}}>
                            {collateralTokenPrice && collateralTokenPrice === "0"
                                ? "price not found"
                                : `eq. ${makeHumanAmount((parseFloat(collateralAmount) * parseFloat(collateralTokenPrice)).toString())} USD`}

                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    const calcLenderFeeAmount = () => {
        if (lenderFeePercent === "") {
            return "0"
        }
        const amount = parseFloat(loanAmount) * parseFloat(lenderFeePercent) / 100
        return !isNaN(amount) ? amount.toFixed(5) : "0"
    }
    const lenderFeePopover = (
        <Popover id="popover-basic" style={{backgroundColor: "#1c2c42", color: "white"}}>
            <Popover.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Control
                                autoComplete="off"
                                id="lender-fee"
                                size="sm"
                                type="text"
                                className="new-amount"
                                value={lenderFeePercent}
                                onFocus={() => setLenderFeePercent("")}
                                onChange={(event) => setLenderFeePercent(event.target.value)}
                                onKeyDown={(event) => {

                                    if (event.key === "Enter") {
                                        event.preventDefault()
                                        setIsLenderFeePopover(false)
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="text-center">
                            <StandardButton
                                text="Ok"
                                onClick={() => setIsLenderFeePopover(false)}
                            />
                            {/*<Button*/}
                            {/*    style={hoveredCommissionOkButton ? {*/}
                            {/*        ...activeButtonStyle,*/}
                            {/*        width: "70%"*/}
                            {/*    } : {...inactiveButtonStyle, width: "70%"}}*/}
                            {/*    onMouseEnter={() => setHoveredCommissionOkButton(true)}*/}
                            {/*    onMouseLeave={() => setHoveredCommissionOkButton(false)}*/}
                            {/*    onClick={percentOkButtonHandler}*/}
                            {/*>Ok</Button>*/}
                        </Col>
                    </Row>
                </Form>
            </Popover.Body>
        </Popover>
    )
    const renderLenderFee = () => {
        return (
            <div>
                <Row>
                    <Col xs={5} className="new-loan-info-text">
                        {newLoanChoose === SUPPLY ? "Your Fee" : "Lender Fee"}
                    </Col>
                    <Col xs={7} className="text-end text-truncate new-loan-info-value" style={{overflow: "unset"}}>
                        {lenderFeePercent !== ""
                            ? lenderFeePercent
                            : "0"}%
                        <div style={{
                            display: "inline-block",
                            paddingLeft: 10
                        }}>({makeHumanAmount(calcLenderFeeAmount())} {loanToken.symbol})
                        </div>
                        <OverlayTrigger trigger="click" placement="left"
                                        overlay={lenderFeePopover}
                                        show={isLenderFeePopover}
                        >
                            {/*<FontAwesomeIcon*/}
                            {/*    icon={faPenToSquare}*/}
                            {/*    style={{paddingLeft: 10, cursor: "pointer"}}*/}
                            {/*    color="#39ADF8"*/}
                            {/*    onClick={oneTimeCommissionPopoverClick}*/}
                            {/*/>*/}
                            <img src={edit} alt="edit" width={22} height={22}
                                 style={{marginTop: -5, cursor: "pointer"}}
                                 onClick={() => setIsLenderFeePopover(!isLenderFeePopover)}
                            />
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end">
                        <div style={{fontSize: 10, color: "#9597a1"}}>
                            {loanTokenPrice === "0"
                                ? "price not found"
                                : `eq. ${makeHumanAmount((parseFloat(calcLenderFeeAmount()) * parseFloat(loanTokenPrice)).toString())} USD`}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    const renderBorrowerGetInfo = () => {
        const DaoFeeAmount = parseFloat(loanAmount) * parseFloat(utils.formatUnits(protocolSettings.DAOFeePercent, 2)) / 100
        const loanAmountWithoutFee = (parseFloat(loanAmount) - parseFloat(calcLenderFeeAmount()) - DaoFeeAmount).toString()
        return (
            <div>
                <Row>
                    <Col className="new-loan-info-text">
                        You'll Get
                    </Col>
                    <Col className="text-end text-truncate" style={{overflow: "unset"}}>
                        <img
                            style={{marginTop: -5, marginRight: 10}}
                            src={loanToken.img}
                            alt="img"
                            width={20}
                            height={20}
                        />
                        {makeHumanAmount(loanAmountWithoutFee)} {loanToken.symbol}
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end"
                         style={{fontSize: 10, color: "#9597a1"}}>
                        Loan Amount - Fee
                    </Col>
                </Row>
            </div>
        )
    }
    const renderInfo = () => {
        const tokensRewarded = isTokensRewarded()
        return (
            <div>
                {newLoanChoose === SUPPLY
                    ? <div>
                        {tokensRewarded.isBothTokensRewarded && chainId === MAINNET_CHAIN_ID
                            ? <Row>
                                <Col xs={7}>
                                    <Form.Check
                                        style={{display: "inline-block"}}
                                        type="checkbox"
                                        id="reward-switch"
                                        onChange={() => setIsReward(!isReward)}
                                        checked={isReward}
                                    /><span className="new-loan-info-text"> Protocol Reward </span>
                                    <img src={info} alt="info" width={23} height={23}
                                         style={{paddingLeft: 5, marginTop: -5, cursor: "pointer"}}
                                        // onClick={() => setShowRewardedOrderInfoModal(true)} // TODO
                                    />
                                </Col>
                                <Col xs={5} className="text-end text-truncate new-loan-info-value"
                                     style={isReward && startRepayDate.getTime() > now.getTime() + MS_PER_DAY * 20 ? {color: "white"} : {color: "#9597a1"}}>
                                    {makeHumanAmount(rewardAmount)} TWOPAW
                                </Col>
                            </Row>
                            : <></>}
                        {tokensRewarded.isBothTokensRewarded && chainId === MAINNET_CHAIN_ID
                            ? <Row>
                                <Col style={{fontSize: 10, color: "#9597a1"}}></Col>
                                <Col xs={10} className="text-end text-truncate"
                                     style={{fontSize: 10, color: "#9597a1"}}>NFT
                                    Balance: {NFTBalance ? utils.formatUnits(NFTBalance, 0) + " " : "0 "}{protocolSettings && protocolSettings.holderNFTs ? `/ Locked: ${getLockedCountHolderNFT(protocolSettings.holderNFTs)}`: "2"}
                                </Col>
                            </Row>
                            : <></>}
                        {tokensRewarded.isBothTokensRewarded && isReward && chainId === MAINNET_CHAIN_ID
                            ? <div>
                                {NFTBalance && parseFloat(utils.formatUnits(NFTBalance, 0)) === 0
                                    ? <div style={{fontSize: 14, color: "#AD2D2D"}}>
                                        You dont have NFT. You can buy NFT in the <u
                                        style={{cursor: "pointer"}}
                                        onClick={() => {
                                            setShowNewLoanModal(false)
                                            setChosenPage(DASHBOARD)
                                        }}
                                    >
                                        dashboard
                                    </u>.
                                    </div>
                                    : <></>}
                            </div>
                            : <></>}
                    </div>
                    : <></>}
                <br/>
                {newLoanChoose === SUPPLY
                    ? renderLoanAmountInfo()
                    : renderCollateralAmountInfo()}
                <br/>
                {newLoanChoose === BORROW
                    ? renderLoanAmountInfo()
                    : renderCollateralAmountInfo()}
                <br/>
                {renderLenderFee()}
                <br/>
                {newLoanChoose === BORROW
                    ? renderBorrowerGetInfo()
                    : <></>}
                <br/>
                <p className="text-center"
                   style={{fontSize: 12, lineHeight: 0.5, paddingTop: 15, color: "#9597a1"}}>If the repayment is
                    not completed, </p>
                <p className="text-center" style={{fontSize: 12, lineHeight: 0.5, color: "#9597a1"}}>all
                    collateral amount go to the lender.</p>
            </div>
        )

    }
    const getBigAmounts = () => {
        const bigLoanAmount = utils.parseUnits(loanAmount, loanToken.decimals)
        const bigCollateralAmount = utils.parseUnits(collateralAmount, collateralToken.decimals)
        const bigRewardAmount = utils.parseUnits(rewardAmount, 18)
        return {
            bigLoanAmount,
            bigCollateralAmount,
            bigRewardAmount
        }
    }
    const approveHandler = async () => {
        setWaiting(true)
        confirmApproveNotify()
        await sendTokenApprove(
            account,
            getAddresses(chainId).protocolAddress,
            loanToken.address,
            library.getSigner(account),
            newLoanChoose === SUPPLY ? getBigAmounts().bigLoanAmount : getBigAmounts().bigCollateralAmount
        )
            .then(tx => {
                processTransaction(tx)
                    .then(() => {
                        setWaiting(false)
                    })
            })
            .catch(error => {
                console.log(error)
                catchMetamaskErrors(error)
                setWaiting(false)
            })
    }
    const createOrderHandler = async () => {
        setWaiting(true)
        confirmNotify()
        const _loanToken = loanToken.address
        const _loanAmount = utils.parseUnits(loanAmount, loanToken.decimals)
        const _collateralToken = collateralToken.address
        const _collateralAmount = utils.parseUnits(collateralAmount, collateralToken.decimals)
        const _lenderCommissionAmount = utils.parseUnits(calcLenderFeeAmount(), loanToken.decimals)
        const _repayStartsTimestamp = Math.floor(startRepayDate.getTime() / 1000)
        const _repayEndsTimestamp = Math.floor(endRepayDate.getTime() / 1000)
        const _role = newLoanChoose === SUPPLY ? 0 : 1
        let NFTId
        if (chainId === MAINNET_CHAIN_ID){
            NFTId = isReward ? await getLowestNotLockedNFTId(account, library.getSigner(account), chainId) : constants.MaxUint256
        }else {
            NFTId = isReward ? getUnlockedHolderNFTId(protocolSettings.holderNFTs) : constants.MaxUint256
        }
        // console.log("_loanToken", _loanToken)
        // console.log("_loanAmount", _loanAmount)
        // console.log("_collateralToken", _collateralToken)
        // console.log("_collateralAmount", _collateralAmount)
        // console.log("_lenderCommissionAmount", _lenderCommissionAmount)
        // console.log("[_repayStartsTimestamp, _repayEndsTimestamp]", [_repayStartsTimestamp, _repayEndsTimestamp])
        // console.log("_role", _role)
        // console.log("NFTId", NFTId)
        const args = [
            _loanToken,
            _loanAmount,
            _collateralToken,
            _collateralAmount,
            _lenderCommissionAmount,
            [_repayStartsTimestamp, _repayEndsTimestamp],
            _role,
            NFTId
        ]
        await newOrder(args, library.getSigner(account), chainId)
            .then(tx => {
                processTransaction(tx)
                    .then(() => {
                        setWaiting(false)
                        setShowNewLoanModal(false)
                    })
            })
            .catch(error => {
                catchMetamaskErrors(error)
                setWaiting(false)
                setShowNewLoanModal(false)
            })
    }
    const renderActionButton = () => {
        if (account) {
            return (
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10} className="text-center">
                        <ActionButton
                            token={newLoanChoose === SUPPLY ? loanToken : collateralToken}
                            amount={newLoanChoose === SUPPLY ? loanAmount : collateralAmount}
                            disabled={disabledActionButton}
                            waiting={waiting}
                            approveHandler={approveHandler}
                            createOrderHandler={createOrderHandler}
                        />
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10} className="text-center">
                        <ConnectButton/>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            )
        }

    }
    return (
        <Modal
            variant="dark"
            size="md"
            show={showNewLoanModal}
            onHide={() => setShowNewLoanModal(false)}
            enforceFocus={false}
        >
            <Modal.Body style={{backgroundColor: "#1f304a", borderRadius: "20px"}}>
                <div style={orderModalHeaderStyle}>
                    <Row>
                        {renderHeader()}
                    </Row>
                </div>
                {renderLoanChoose()}
                {renderSettings()}
                {renderInfo()}
                <br/>
                {renderActionButton()}
            </Modal.Body>
        </Modal>
    );
};

export default NewLoanModal;