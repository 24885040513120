import React from 'react';
import {useEthers, useTokenAllowance} from "@usedapp/core";
import {getAddresses} from "../../../utilities";
import PawLoading from "../PawLoading";
import StandardButton from "../StandardButton";
import {BigNumber, utils} from "ethers";

const CREATE_ORDER = "Create Order"
const APPROVE_N_CREATE_ORDER = "Approve & Create Order"

const ActionButton = (props) => {
    const {account, chainId} = useEthers()

    const allowance = useTokenAllowance(props.token.address, account, getAddresses(chainId).protocolAddress)

    let buttonText
    let bigAmount
    if (props.amount === "0" || props.amount === "") {
        bigAmount = BigNumber.from("0")

    } else {
        bigAmount = utils.parseUnits(props.amount, props.token.decimals)
    }

    if (allowance) {
        if (allowance.gte(bigAmount)) {
            buttonText = CREATE_ORDER
        } else {
            buttonText = APPROVE_N_CREATE_ORDER
        }
    } else {
        buttonText = <PawLoading/>
    }

    return (
        <StandardButton
            text={props.waiting ? "" : buttonText}
            disabled={props.disabled}
            loading={props.waiting}
            onClick={buttonText === APPROVE_N_CREATE_ORDER ? props.approveHandler: props.createOrderHandler}
        />
    );
};

export default ActionButton;