import React, {useEffect, useState} from 'react';
import {TableHeaderStyle, headerTextStyle, logoStyle, mobileHeaderStyle} from "./headerStyles";
import {Dropdown, Col, DropdownButton, Row, Container} from "react-bootstrap";
import logoText from "../../assets/TwoPawsText.svg"
import logo from "../../assets/twoPaws.svg"
import {useRecoilValue} from "recoil";
import {chosenPageAtom} from "../../state/atoms/globalAtoms";
import ConnectButton from "../global/Connect/ConnectButton";
import {useEthers} from "@usedapp/core";
import {getChainIdText} from "../../utilities";
import {ARBITRUM_CHAIN_ID, BSC_CHAIN_ID, MAINNET_CHAIN_ID} from "../../constants";
import {confirmSwitchNotify, switchNetworkNotify} from '../../toasts/toastsMessageses'
import AppInfo from "./AppInfo/AppInfo";
import InfoModal from "./AppInfo/InfoModal";
import StandardButton from "../global/StandardButton";
import "./claimStyles.css"
import {isDroppedAddress} from "../../contracts/methods/protocolMethods";

const claimStyleDesktop = {
    width: "100%",
    height: "100px",
    borderRadius: "20px",
    backgroundColor: "#1c2c42",
    marginBottom: "30px",
    paddingTop: "14px",
    paddingLeft: "14px"
}
const claimStyleMobile = {
    width: "100%",
    mixHeight: "190px",
    borderRadius: "20px",
    backgroundColor: "#1c2c42",
    marginBottom: "30px",
    paddingTop: "14px",
    paddingLeft: "14px"
}

const Header = () => {
    const chosenPage = useRecoilValue(chosenPageAtom)
    const [isClaim, setIsClaim] = useState(false)
    const {account, chainId, switchNetwork} = useEthers()

    useEffect(() => {
        if (account && chainId === BSC_CHAIN_ID){
            isDroppedAddress(account, chainId)
                .then(is => {
                    setIsClaim(is)
                })

        } else {
            setIsClaim(false)
        }
    }, [account, chainId])

    const changeNetwork = (networkId) => {
        confirmSwitchNotify()
        if (account) {
            switchNetwork(networkId)
                .then(() => {
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            switchNetworkNotify()
        }
    }

    const renderDesktop = () => {
        return (
            <div style={TableHeaderStyle}>
                <Row>
                    <Col xl={3} lg={2}>
                        <div style={logoStyle}
                        ><img src={logoText} alt="logoText" style={{marginTop: -15}}/>
                            <a href="https://twopaws.io"><img width={logoStyle.width} height={logoStyle.height}
                                                              style={{marginTop: -48, marginLeft: 60}} src={logo}
                                                              alt="logo"/></a>
                        </div>
                    </Col >
                    <Col xl={4} lg={3} className="text-center">
                        <div
                            style={headerTextStyle}>{chosenPage}</div>
                    </Col>
                    <Col xl={5} lg={6}>
                        <Row>
                            <Col>
                                <AppInfo/>
                            </Col>
                            <Col className="text-center">
                                <DropdownButton
                                    id="network-dropdown"
                                    variant="dark"
                                    size="lg"
                                    title={getChainIdText(chainId)}
                                >
                                    <Dropdown.Item>
                                        <div className="text-center"
                                             onClick={() => changeNetwork(MAINNET_CHAIN_ID)}
                                        >
                                            Ethereum
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <div className="text-center"
                                             onClick={() => changeNetwork(ARBITRUM_CHAIN_ID)}
                                        >
                                            Arbitrum
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                    >
                                        <div className="text-center"
                                             onClick={() => changeNetwork(BSC_CHAIN_ID)}
                                        >
                                            BSC
                                        </div>
                                    </Dropdown.Item>
                                </DropdownButton>
                            </Col>
                            <Col className="text-center">
                                <ConnectButton/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
    const renderMobile = () => {
        return (
            <div style={mobileHeaderStyle}>
                <Row>
                    <Col xs={1} sm={4}>
                        <a href="https://twopaws.io"><img width={65} height={65}
                                                          style={{marginTop: -10, marginLeft: 0}} src={logo}
                                                          alt="logo"/></a>
                    </Col>
                    <Col xs={11} sm={8}>
                        <Row>
                        {/*<Col>*/}
                        {/*    <AppInfo/>*/}
                        {/*</Col>*/}
                        <Col style={{paddingRight: 0}} className="text-center">
                            <DropdownButton
                                id="network-dropdown"
                                variant="dark"
                                size="lg"
                                title={getChainIdText(chainId)}
                            >
                                <Dropdown.Item>
                                    <div className="text-center"
                                         onClick={() => changeNetwork(MAINNET_CHAIN_ID)}
                                    >
                                        Ethereum
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <div className="text-center"
                                         onClick={() => changeNetwork(ARBITRUM_CHAIN_ID)}
                                    >
                                        Arbitrum
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                >
                                    <div className="text-center"
                                         onClick={() => changeNetwork(BSC_CHAIN_ID)}
                                    >
                                        BSC
                                    </div>
                                </Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col className="text-center">
                            <ConnectButton/>
                        </Col>
                    </Row>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className="text-center">
                        <div style={headerTextStyle}>{chosenPage}</div>
                    </Col>
                </Row>
                <br/>
            </div>
        )
    }

    const claimHandler = () => {
        console.log("claimHandler")
    }

    const renderClaimDesktop = () => {
        return(
            <Row>
                <Col xl={8} lg={9}><p>A total of 210,000,000,000,000,000 TWOPAW BSC tokens are now available to be claimed by those who have holder (Venus Protocol && Stargate) token eq 10$.</p></Col>
                <Col xl={4} lg={3} style={{paddingTop: "15px"}} className="text-center">
                    <StandardButton
                        text="Claim"
                        style={{width: "70%"}}
                        onClick={claimHandler}
                    />
                </Col>
            </Row>
        )
    }
    const renderClaimMobile = () => {
        return(
            <div>
                <Row>
                <Col >
                    <p className="claim">A total of 210,000,000,000,000,000 TWOPAW BSC tokens are now available to be claimed by those who have holder (Venus Protocol && Stargate) token eq 10$.</p>
                </Col>
            </Row>
                <Row>
                    <Col className="text-center" style={{marginBottom: 10}}>
                        <StandardButton
                            text="Claim"
                            style={{width: "70%"}}
                            onClick={claimHandler}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    const renderClaim = () => {
        return(
            <Container>
                <div className="d-none d-lg-block" style={claimStyleDesktop}>
                    {renderClaimDesktop()}
                </div>
                <div className="d-lg-none" style={claimStyleMobile}>
                    {renderClaimMobile()}
                </div>
            </Container>
        )
    }

    return (
        <div>
            <div className="d-none d-lg-block">
                {renderDesktop()}
            </div>
            <div className="d-lg-none">
                {renderMobile()}
            </div>
            {
                isClaim
                ? <div>{renderClaim()}</div>
                    : <></>
            }
            <InfoModal/>
        </div>
    );
};

export default Header;