import React from 'react';
import {DropdownButton, Dropdown} from "react-bootstrap";
import {useSetRecoilState} from "recoil";
import {
    ordersInfoModalAtom,
    rewardedOrderInfoModalAtom,
    tokenomicsInfoModalAtom
} from "../../../state/atoms/contentAtoms";

const AppInfo = () => {
    const setShowInfoModal = useSetRecoilState(ordersInfoModalAtom)
    const setShowTokenomicInfoModal = useSetRecoilState(tokenomicsInfoModalAtom)
    const setShowRewardedOrderInfoModal = useSetRecoilState(rewardedOrderInfoModalAtom)

    return (
        <div>
            <DropdownButton
                id="info-dropdown"
                variant="dark"
                size="lg"
                title="Info"
            >
                <Dropdown.Item>
                    <div className="text-center"
                         onClick={() => setShowTokenomicInfoModal(1)}
                    > Tokenomic
                    </div>
                </Dropdown.Item>

                <Dropdown.Item>
                    <div className="text-center"
                         onClick={() => setShowInfoModal(1)}
                    > Lender Order
                    </div>
                </Dropdown.Item>

                <Dropdown.Item>
                    <div className="text-center"
                         onClick={() => setShowInfoModal(2)}
                    > Borrower Order
                    </div>
                </Dropdown.Item>

                {/*<Dropdown.Item>*/}
                {/*    <div className="text-center"*/}
                {/*         onClick={() => setShowRewardedOrderInfoModal(true)}*/}
                {/*    > Rewarded Order*/}
                {/*    </div>*/}
                {/*</Dropdown.Item>*/}
            </DropdownButton>
        </div>
    );
};

export default AppInfo;