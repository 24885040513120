import React, {useEffect, useState} from 'react';
import {getAddresses, getScanner, makeHumanAmount} from "../../../utilities";
import {BigNumber, utils} from "ethers";
import {supportedTokensArbitrum} from "../../../supportedTokensArbitrum"
import {BSC_CHAIN_ID, STATUS} from "../../../constants";
import {Col, Row} from "react-bootstrap";
import twoPawIcon from "../../../assets/svg/TWOPAW.svg";
import {useRecoilValue} from "recoil";
import {tokenPricesAtom} from "../../../state/atoms/globalAtoms";
import Skeleton from "react-loading-skeleton";
import {useEthers} from "@usedapp/core";
import {supportedTokensBSC} from "../../../supportedTokensBSC";

const RewardInterval = (props) => {
    const [rewardAmount, setRewardAmount] = useState("0")
    const tokensPrices = useRecoilValue(tokenPricesAtom)
    const {chainId} = useEthers()
    useEffect(() => {
        if (props.order.status === STATUS.OPEN) {
            const interval = setInterval(() => {
                const supportedLoanToken = chainId === BSC_CHAIN_ID ? supportedTokensBSC[props.order.loanToken.address] : supportedTokensArbitrum[props.order.loanToken.address] // rewardDenominator
                const denominator = supportedLoanToken.denominator
                const timeDelta = Math.floor(Date.now() / 1000 - props.order.openTimestamp)
                const denominatorCount = parseFloat(utils.formatUnits(props.order.loanAmount, supportedLoanToken.decimals)) / denominator
                const rewardAmountPerDay = denominatorCount * supportedLoanToken.rewardAmountPerDayForDenominator
                const rewardAmountPerSecond = rewardAmountPerDay / 86400
                let reward = timeDelta * rewardAmountPerSecond
                reward = reward <= 40000 ? reward : 40000
                setRewardAmount(reward.toString())
            }, 1000)

            return () => clearInterval(interval);
        } else {
            setRewardAmount(utils.formatUnits(props.order.rewardAmount, 18))
        }

    }, [])

    const calcPriceEq = () => {
        if (tokensPrices && tokensPrices.prices) {
            const twopawPrice = tokensPrices.prices.find(item => item.token === getAddresses(props.chainId).tokenAddress)
            if (twopawPrice) {
                if (!twopawPrice.price.eq(BigNumber.from("0"))) {
                    const price = utils.formatUnits(twopawPrice.price, 8)
                    const priceEq = parseFloat(rewardAmount) * parseFloat(price)
                    return priceEq.toString()
                } else return undefined
            } else return undefined
        } else {
            return undefined
        }
    }
    calcPriceEq()
    return (
        <Col xs={2} style={{marginTop: -5}}>
            <Row style={{paddingLeft: 25}}>
                <Col xs={3}
                     style={{position: "relative", paddingTop: 5}}>
                    <img className="table-img"
                         src={twoPawIcon} alt="img"
                         width={30}
                         height={30}
                         onClick={() => window.open(`https://${getScanner(props.chainId)}/address/${getAddresses(props.chainId).tokenAddress}`, "_blank")}
                    />
                </Col>
                <Col xs={9} style={{color: "#00F7A7", paddingTop: 6}} className="text-start">
                    <Row>
                        <Col className="text-truncate text-start">
                            <div>{props.order.duration.getJSRepayEndsTimestamp() > Date.now() ? makeHumanAmount(rewardAmount) : "0"}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{
                            fontSize: 10,
                            color: "#9597a1"
                        }}>{calcPriceEq()
                            ? <div>eq: {makeHumanAmount(calcPriceEq())} USD</div>
                            : <Skeleton width="40%" highlightColor="#182539" baseColor="#243b55"/>}</Col>
                    </Row>
                    <Row>
                    </Row>
                </Col>
            </Row>

        </Col>

    );
};

export default RewardInterval;