import protocolArbitrumJSON from "./arbitrum/TwoPaws.json"
import NFTArbitrumJSON from "./arbitrum/PAWDAO.json"
import tokenArbitrumJSON from "./arbitrum/PawToken.json"
import oracleArbitrumJSON from "./arbitrum/Oracle3.json"
import protocolMainnetJSON from "./mainnet/TwoPaws.json"
import NFTMainnetJSON from "./mainnet/PAWDAO.json"
import tokenMainnetJSON from "./mainnet/PawToken.json"
import oracleMainnetJSON from "./mainnet/Oracle3.json"
import protocolBSCJSON from "./bsc/TwoPaws.json"
import NFTBSCJSON from "./bsc/PAWDAO.json"
import tokenBSCJSON from "./bsc/PawToken.json"
import oracleBSCJSON from "./bsc/Oracle3.json"
import ERC20JSON from "./IERC20.json"
import WETHJSON from "./IWETH.json"
import {ARBITRUM_CHAIN_ID, MAINNET_CHAIN_ID, BSC_CHAIN_ID} from "../../constants";

export const ERC20Interface = ERC20JSON.abi
export const WETHInterface = WETHJSON.abi

export const getProtocolInterface = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return protocolMainnetJSON.abi
        case ARBITRUM_CHAIN_ID:
            return protocolArbitrumJSON.abi
        case BSC_CHAIN_ID:
            return protocolBSCJSON.abi
        default:
            return undefined
    }
}
export const getNFTInterface = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return NFTMainnetJSON.abi
        case ARBITRUM_CHAIN_ID:
            return NFTArbitrumJSON.abi
        case BSC_CHAIN_ID:
            return NFTBSCJSON.abi
        default:
            return undefined
    }
}
export const getTokenInterface = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return tokenMainnetJSON.abi
        case ARBITRUM_CHAIN_ID:
            return tokenArbitrumJSON.abi
        case BSC_CHAIN_ID:
            return tokenBSCJSON.abi
        default:
            return undefined
    }
}
export const getOracleInterface = (chainId) => {
    switch (chainId) {
        case MAINNET_CHAIN_ID:
            return oracleMainnetJSON.abi
        case ARBITRUM_CHAIN_ID:
            return oracleArbitrumJSON.abi
        case BSC_CHAIN_ID:
            return oracleBSCJSON.abi
        default:
            return undefined
    }
}