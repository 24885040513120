export const supportedTokensArbitrum = {
    "0x82af49447d8a07e3bd95bd0d56f35241523fbab1": {
        address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
        denominator: 0.3,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8": {
        address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
        denominator: 500,
        decimals: 6,
        rewardAmountPerDayForDenominator: 1000
    },
    "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9": {
        address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        denominator: 500,
        decimals: 6,
        rewardAmountPerDayForDenominator: 1000
    },
    "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a": {
        address: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
        denominator: 6,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978": {
        address: "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978",
        denominator: 300,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x539bdE0d7Dbd336b79148AA742883198BBF60342": {
        address: "0x539bdE0d7Dbd336b79148AA742883198BBF60342",
        denominator: 250,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x088cd8f5eF3652623c22D48b1605DCfE860Cd704": {
        address: "0x088cd8f5eF3652623c22D48b1605DCfE860Cd704",
        denominator: 100,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x10393c20975cF177a3513071bC110f7962CD67da": {
        address: "0x10393c20975cF177a3513071bC110f7962CD67da",
        denominator: 150,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x3d9907F9a368ad0a51Be60f7Da3b97cf940982D8": {
        address: "0x3d9907F9a368ad0a51Be60f7Da3b97cf940982D8",
        denominator: 0.2,
        decimals: 18,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f": {
        address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
        denominator: 0.01,
        decimals: 8,
        rewardAmountPerDayForDenominator: 1000
    },
    "0x912CE59144191C1204E64559FE8253a0e49E6548": {
        address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
        denominator: 400,
        decimals: 18,
        rewardAmountPerDayForDenominator: 2000
    },
}