import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {STATUS} from "../../../constants";


const Duration = (props) => {


    const [daysEnds, setDaysEnds] = useState(0)
    const [hoursEnds, setHoursEnds] = useState(0)
    const [minutesEnds, setMinutesEnds] = useState(0)
    const [secondsEnds, setSecondsEnds] = useState(0)
    const [daysStarts, setDaysStarts] = useState(0)
    const [hoursStarts, setHoursStarts] = useState(0)
    const [minutesStarts, setMinutesStarts] = useState(0)
    const [secondsStarts, setSecondsStarts] = useState(0)

    const repayStarts = props.duration.getJSRepayStartsTimestamp()
    const repayEnds = props.duration.getJSRepayEndsTimestamp()

    const getDeadlineTime = () => {
        const repayStartsDeadLine = repayStarts - Date.now()
        const repayEndsDeadline = repayEnds - Date.now()

        return [
            repayStartsDeadLine,
            repayEndsDeadline
        ]
    }

    const getTime = () => {
        const [repayStartsTime, repayEndsTime] = getDeadlineTime()
        setDaysEnds(Math.floor(repayEndsTime / (1000 * 60 * 60 * 24)))
        setHoursEnds(Math.floor((repayEndsTime / (1000 * 60 * 60)) % 24))
        setMinutesEnds(Math.floor((repayEndsTime / 1000 / 60) % 60))
        setSecondsEnds(Math.floor((repayEndsTime / 1000) % 60))
        setDaysStarts(Math.floor(repayStartsTime / (1000 * 60 * 60 * 24)))
        setHoursStarts(Math.floor((repayStartsTime / (1000 * 60 * 60)) % 24))
        setMinutesStarts(Math.floor((repayStartsTime / 1000 / 60) % 60))
        setSecondsStarts(Math.floor((repayStartsTime / 1000) % 60))
    }

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000)
        return () => clearInterval(interval)
    }, [])


    const getDurationInfo = () => {
        const repayStartsLeft = {
            text: ""
        }
        const repayEndsLeft = {
            text: ""
        }
        if (Date.now() < repayStarts){
            if (getDeadlineTime()[0] > 0){
                if (daysStarts > 1){
                    repayStartsLeft.text = `repay starts in ${daysStarts} days`
                }else if (daysStarts === 1){
                    repayStartsLeft.text = `repay starts in 1 day`
                }else if(daysStarts < 1){
                    repayStartsLeft.text = `repay starts in ${hoursStarts > 9
                        ? hoursStarts : "0" + hoursStarts}:${minutesStarts > 9
                        ? minutesStarts : "0" + minutesStarts}:${secondsStarts > 9
                        ? secondsStarts : "0" + secondsStarts}`
                }
            }
        }else if (Date.now() > repayStarts && Date.now() <= repayEnds){
            if (getDeadlineTime()[1] > 0){
                if (daysStarts > 1){
                    repayStartsLeft.text = `repay ends in ${daysEnds} days`
                }else if (daysEnds === 1){
                    repayStartsLeft.text = `repay ends in 1 day`
                }else if(daysEnds < 1){
                    repayStartsLeft.text = `repay ends in ${hoursEnds > 9
                        ? hoursEnds : "0" + hoursEnds}:${minutesEnds > 9
                        ? minutesEnds : "0" + minutesEnds}:${secondsEnds > 9
                        ? secondsEnds : "0" + secondsEnds}`
                }
            }
        }else if (daysStarts === 0 && hoursStarts === 0 && minutesStarts === 0 && secondsStarts === 0){
            repayStartsLeft.text = "loading..."
        }else {
            repayStartsLeft.text = `expired`
        }

        if (daysEnds === 0 && hoursEnds === 0 && minutesEnds === 0 && secondsEnds === 0) {
            repayEndsLeft.text = "loading..."
        } else if (getDeadlineTime()[1] > 0) {
            if (daysEnds > 1){
                repayEndsLeft.text = `${daysEnds} days`
            }else if (daysEnds === 1){
                repayEndsLeft.text = `1 day`
            }else if(daysEnds < 1){
                repayEndsLeft.text = `${hoursEnds > 9
                    ? hoursEnds : "0" + hoursEnds}:${minutesEnds > 9
                    ? minutesEnds : "0" + minutesEnds}:${secondsEnds > 9
                    ? secondsEnds : "0" + secondsEnds}`
            }
        }else {
            repayEndsLeft.text = `expired`
        }

        return {
            repayStartsLeft,
            repayEndsLeft
        }
    }
    const renderByStatus = () => {
        if (props.status === STATUS.CANCELED){
            return{
                repayStartsLeft: {text: ""},
                repayEndsLeft: {text: "Canceled"}
            }
        }else if (props.status === STATUS.CLOSED) {
            return {
                repayStartsLeft: {text: ""},
                repayEndsLeft: {text: "Closed"}
            }
        }else if (props.status === STATUS.WORKING){
            return {
                repayStartsLeft: {text: ""},
                repayEndsLeft: {text: "Working"}
            }
        }else {
            return getDurationInfo()
        }
    }
    return (
        <div>
            <Row>
                <Col>
                    {renderByStatus().repayEndsLeft.text}
                </Col>
            </Row>
            <Row>
                <Col style={{fontSize: 10, color: "#9597a1"}}>
                    {renderByStatus().repayStartsLeft.text}
                </Col>
            </Row>
        </div>
    );
};

export default Duration;