import React from 'react';
import {Col, Row} from "react-bootstrap";
import Filters from "../global/Filters/Filters";
import Menu from "../global/Menu/Menu";
import {chosenPageAtom} from "../../state/atoms/globalAtoms";
import {DASHBOARD, MY_ACCOUNT} from "../../constants";
import DashboardInfo from "../global/DashboardInfo";
import {useRecoilValue} from "recoil";

const SubHeader = () => {
    const chosenPage = useRecoilValue(chosenPageAtom)
    const renderDesktop = () => {
        return (
            <div>
                <Row>
                    <Col>{chosenPage === DASHBOARD || chosenPage === MY_ACCOUNT ? <DashboardInfo/> : <Filters/>}</Col>
                    <Col><Menu/></Col>
                </Row>
            </div>
        )
    }
    const renderPhone = () => {
        return (
            <div>
                <Row>
                    <Col><Menu/></Col>
                </Row>
                <Row>
                    <Col>{chosenPage === DASHBOARD || chosenPage === MY_ACCOUNT ? <DashboardInfo/> : <Filters/>}</Col>
                </Row>
            </div>
        )
    }

    return (
        <div>
            <div className="d-none d-lg-block">
                {renderDesktop()}
            </div>
            <div className="d-lg-none">
                {renderPhone()}
            </div>
        </div>
    );
};

export default SubHeader;