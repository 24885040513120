import React from 'react';
import {Col, Row} from "react-bootstrap";
import {
    getAddresses,
    getScanner,
    isTWOPAWTokenAddress,
    makeHumanAmount,
    makeTWOPAWHumanAmount
} from "../../../utilities";
import {BigNumber, constants} from "ethers";
import {useEthers} from "@usedapp/core";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {percentStyle} from "../../../styles/contentStyles";
import Duration from "./Duration";
import twoPawIcon from "../../../assets/svg/TWOPAW.svg"
import {useRecoilValue} from "recoil";
import {chosenPageAtom} from "../../../state/atoms/globalAtoms";
import {MAINNET_CHAIN_ID, MY_ACCOUNT, ORDERS_BOOK} from "../../../constants";
import OrderAction from "../MyAccount/OrderAction";
import RewardInterval from "./RewardInterval";

const Order = (props) => {
    const chosenPage = useRecoilValue(chosenPageAtom)
    const {chainId} = useEthers()
    const renderLoanAmount = (order) => {
        return (
            <Col xs={3} style={{marginTop: 0}}>
                <Row>
                    <Col xs={3}
                         style={{position: "relative"}}>
                        <img className="table-img"
                             src={isTWOPAWTokenAddress(order.loanToken.address, chainId) ? "https://twopaws.io/token-icons/twopaws512x512.svg" : order.loanToken.img}
                             alt="img"
                             width={30}
                             height={30}
                             onClick={() => window.open(`https://${getScanner(chainId)}/address/${order.loanToken.address}`, "_blank")}
                        />
                    </Col>
                    <Col xs={9}>
                        <Row>
                            <Col
                                className="text-truncate text-start">{makeHumanAmount(order.getFormatLoanAmount())} {order.loanToken.symbol}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-truncate"
                                 style={{fontSize: 10, color: "#9597a1"}}>
                                {order.getLoanUSDEquals().eq(BigNumber.from("0"))
                                    ? <Skeleton width="40%" highlightColor="#182539" baseColor="#243b55"/>
                                    : `eq: ${makeHumanAmount(order.getFormatLoanUSDEquals())} USD`}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        )
    }
    const renderCollateralAmount = (order) => {
        let percentColor
        if (order.getCollateralPercent() < 120) {
            percentColor = {border: "2px solid #dc3545"}
        } else if (order.getCollateralPercent() < 160) {
            percentColor = {border: "2px solid #fdc54b"}
        } else {
            percentColor = {border: "2px solid #00F7A7"}
        }
        return (
            <Col className="text-center" xs={3} style={{marginTop: 0}}>
                <Row>
                    <Col xs={2}
                         style={{position: "relative", marginTop: 0}}>
                        <img className="table-img"
                             src={isTWOPAWTokenAddress(order.collateralToken.address, chainId) ? "https://twopaws.io/token-icons/twopaws512x512.svg" : order.collateralToken.img}
                             alt="img"
                             width={30}
                             height={30}
                             onClick={() => window.open(`https://${getScanner(chainId)}/address/${order.collateralToken.address}`, "_blank")}

                        />
                    </Col>
                    <Col className="text-start" xs={8}>
                        <Row>
                            <Col
                                className="text-truncate">{makeHumanAmount(order.getFormatCollateralAmount())} {order.collateralToken.symbol}</Col>
                        </Row>
                        <Row>
                            <Col className="text-truncate"
                                 style={{fontSize: 10, color: "#9597a1"}}>
                                {order.getCollateralUSDEquals().eq(BigNumber.from("0"))
                                    ? <Skeleton width="40%" highlightColor="#182539" baseColor="#243b55"/>
                                    : `eq: ${makeHumanAmount(order.getFormatCollateralUSDEquals())} USD`}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2} style={{marginTop: -5}}>
                        {order.getLoanUSDEquals().eq(BigNumber.from("0")) || order.getCollateralUSDEquals().eq(BigNumber.from("0"))
                            ? <Skeleton circle={true} width="40px" height="40px" highlightColor="#182539"
                                        baseColor="#243b55"/>
                            : <div className="text-truncate"
                                   style={{...percentStyle, ...percentColor}}>
                                {order.getCollateralPercent()}%
                            </div>}


                    </Col>
                </Row>

            </Col>
        )
    }
    const renderLenderFee = (order) => {
        return (
            <Col className="text-center" xs={2} style={{marginTop: 0}}>
                <Row>
                    <Col className="text-truncate"
                         style={{color: "#00F7A7"}}>{makeHumanAmount(order.getFloatLenderFeePercent().toFixed(2))}%
                        fee</Col>
                </Row>
                <Row>
                    <Col style={{
                        fontSize: 10,
                        color: "#9597a1"
                    }}>{makeHumanAmount(order.getFloatLenderFee().toString())} {order.loanToken.symbol}</Col>
                </Row>
            </Col>
        )

    }
    const renderAlreadyRewarded = (order) => {
        if (order.getFloatRewardAmount() > 0) {
            return (
                <Col xs={2} style={{marginTop: 0}}>
                    <Row style={{paddingLeft: 25}}>
                        <Col xs={3}
                             style={{position: "relative"}}>
                            <img className="table-img"
                                 src={twoPawIcon} alt="img"
                                 width={30}
                                 height={30}
                                 onClick={() => window.open(`https://${getScanner(chainId)}/address/${getAddresses(chainId).tokenAddress}`, "_blank")}
                            />
                        </Col>
                        <Col xs={9} style={{color: "#00F7A7"}} className="text-start">
                            <Row>
                                <Col
                                    className="text-truncate text-start">{makeTWOPAWHumanAmount(order.getFloatRewardAmount())}

                                </Col>
                            </Row>
                            <Row>
                                {/*<Col className="text-truncate"*/}
                                {/*     style={{fontSize: 10, color: "#9597a1"}}>*/}
                                {/*    {parseFloat(order.rewardAmount) === 0*/}
                                {/*        ? "price not found"*/}
                                {/*        : `eq: ${makeHumanAmount(rewardEQ.toString())} USD`}*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                    </Row>

                </Col>
            )
        } else return (
            <Col xs={2}></Col>
        )
    }
    const renderSoonRewarded = (order) => {
        return (
            <RewardInterval order={order} chainId={chainId}/>
        )

    }
    const renderReward = (order) => {
        if (chainId === MAINNET_CHAIN_ID) {
            return renderAlreadyRewarded(order)
        } else {
            if (!order.lenderFee.gt(BigNumber.from("0"))) {
                return renderSoonRewarded(order)
            } else return <></>

        }


    }
    const renderOrder = () => {
        if (chosenPage === ORDERS_BOOK) {
            return (
                <Row>
                    {renderLoanAmount(props.order)}
                    {renderCollateralAmount(props.order)}
                    {renderLenderFee(props.order)}
                    <Col className="text-center" xs={2} style={{marginTop: 0}}>
                        <Duration duration={props.order.duration} status={props.order.status}/>
                    </Col>
                    {renderReward(props.order)}
                </Row>
            )
        } else if (chosenPage === MY_ACCOUNT) {
            return (
                <Row>
                    {renderLoanAmount(props.order)}
                    {renderCollateralAmount(props.order)}
                    {renderLenderFee(props.order)}
                    <Col className="text-center" xs={2} style={{marginTop: -5}}>
                        <OrderAction order={props.order}/>
                    </Col>
                </Row>
            )
        }
    }
    return (
        <div>
            {renderOrder()}
        </div>

    );
};

export default Order;