import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useEthers, useGasPrice} from "@usedapp/core";
import gas from "../../assets/svg/fuel.svg"
import TWOPAW from "../../assets/svg/TWOPAW.svg"
import {getAddresses, getWETHAddress, makeHumanAmount} from "../../utilities";
import {utils} from "ethers";
import {useRecoilValue} from "recoil";
import {tokenPricesAtom} from "../../state/atoms/globalAtoms";
import {BSC_CHAIN_ID} from "../../constants";

export const dashboardInfoStyle = {
    width: "100%",
    minHeight: "70px",
    borderRadius: "20px",
    backgroundColor: "#1c2c42",
    marginBottom: "30px",
    paddingTop: "25px",
    paddingLeft: "20px",
}

const DashboardInfo = () => {
    const gasPrice = useGasPrice()
    const tokenPrices = useRecoilValue(tokenPricesAtom)
    const {chainId} = useEthers()

    let WETHPrice = "0"
    let TWOPAWPrice = "0"
    if (tokenPrices && tokenPrices.prices) {
        WETHPrice = tokenPrices.prices.find(item => item.token === getWETHAddress(chainId))
        TWOPAWPrice = tokenPrices.prices.find(item => item.token === getAddresses(chainId).tokenAddress)
        if (WETHPrice) {
            WETHPrice = utils.formatUnits(WETHPrice.price, 8)
        }
        if (TWOPAWPrice) {
            TWOPAWPrice = utils.formatUnits(TWOPAWPrice.price, 8)
        }
    }
    const renderDesktop = () => {
        return(
            <div style={dashboardInfoStyle}>
                <Row>
                    <Col className="text-truncate">
                        <img src={gas} width={30} alt="gas"
                             style={{paddingRight: 10, marginTop: -2}}
                        />
                        <span style={{marginTop: 10}}>
                        {gasPrice ? makeHumanAmount(utils.formatUnits(gasPrice, 9)) : 0} Gwei
                    </span>
                    </Col>
                    <Col className="text-truncate">
                        <img
                            src={chainId === BSC_CHAIN_ID ? "https://bscscan.com/token/images/binance_32.png" : "https://cdn.redstone.finance/symbols/eth.svg"}
                             width={25} alt="gas"
                             style={{paddingRight: 10, marginTop: -2}}
                        />
                        <span style={{marginTop: 10}}>
                        {WETHPrice ? makeHumanAmount(WETHPrice) : "0"} USD
                    </span>
                    </Col>
                    <Col className="text-truncate" style={{overflow: "unset"}}>
                        <img src={TWOPAW} width={37} alt="gas"
                             style={{paddingRight: 10, marginTop: -5}}
                        />
                        <span style={{marginTop: 10}}>
                        {TWOPAWPrice ? makeHumanAmount(TWOPAWPrice): "0"} USD
                    </span>
                    </Col>
                </Row>
            </div>
        )
    }
    const renderMobile = () => {
        return(
            <div style={{...dashboardInfoStyle, fontSize: 10}}>
                <Row>
                    <Col className="text-truncate">
                        <img src={gas} width={25} alt="gas"
                             style={{paddingRight: 10, marginTop: -2}}
                        />
                        <span style={{marginTop: 10}}>
                        {gasPrice ? makeHumanAmount(utils.formatUnits(gasPrice, 9)) : 0} Gwei
                    </span>
                    </Col>
                    <Col className="text-truncate">
                        <img
                            src={chainId === BSC_CHAIN_ID ? "https://bscscan.com/token/images/binance_32.png" : "https://cdn.redstone.finance/symbols/eth.svg"}
                             width={20} alt="gas"
                             style={{paddingRight: 10, marginTop: -2}}
                        />
                        <span style={{marginTop: 10}}>
                        {WETHPrice ? makeHumanAmount(WETHPrice) : "0"} USD
                    </span>
                    </Col>
                    <Col className="text-truncate" style={{overflow: "unset", paddingRight: 18}}>
                        <img src={TWOPAW} width={32} alt="gas"
                             style={{paddingRight: 10, marginTop: -5}}
                        />
                        <span style={{marginTop: 10}}>
                        {TWOPAWPrice ? makeHumanAmount(TWOPAWPrice): "0"} USD
                    </span>
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <div>
            <div className="d-none d-lg-block">
                    {renderDesktop()}
            </div>
            <div className="d-lg-none">
                    {renderMobile()}
            </div>
        </div>
    );
};

export default DashboardInfo;