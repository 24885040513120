import {ethers} from "ethers";

export const MS_PER_DAY = 1000 * 60 * 60 * 24
export const MS_PER_HOUR = 1000 * 60 * 60

export const DASHBOARD = "Dashboard"
export const ORDERS_BOOK = "Orders Book"
export const MY_ACCOUNT = "My Account"


export const SUPPLY = "supply"
export const BORROW = "borrow"
export const ALL_ORDERS = "all"


export const MAINNET_PROVIDER_URL = "https://eth.drpc.org"
// export const MAINNET_PROVIDER_URL = "https://rpc.ankr.com/eth/709ed46cfa73f4def46d75a198bd5bc78fafa7dff95a4dc8c40d1af6660a4681"
// export const MAINNET_PROVIDER_URL = "https://rpc.ankr.com/eth"
export const MAINNET_PROVIDER = new ethers.providers.JsonRpcProvider(MAINNET_PROVIDER_URL)
// export const ARBITRUM_PROVIDER_URL = "https://twopaws.io/arbirpc"
// export const ARBITRUM_PROVIDER_URL = "https://arb1.arbitrum.io/rpc"
// export const ARBITRUM_PROVIDER_URL = "https://rpc.ankr.com/arbitrum/709ed46cfa73f4def46d75a198bd5bc78fafa7dff95a4dc8c40d1af6660a4681"
export const ARBITRUM_PROVIDER_URL = "https://rpc.ankr.com/arbitrum"
export const ARBITRUM_PROVIDER = new ethers.providers.JsonRpcProvider(ARBITRUM_PROVIDER_URL)
// export const BSC_PROVIDER_URL = "https://bsc-dataseed1.binance.org/"
export const BSC_PROVIDER_URL = "https://rpc.ankr.com/bsc/709ed46cfa73f4def46d75a198bd5bc78fafa7dff95a4dc8c40d1af6660a4681"
export const BSC_PROVIDER = new ethers.providers.JsonRpcProvider(BSC_PROVIDER_URL)



export const MAINNET_CHAIN_ID = 1
export const ARBITRUM_CHAIN_ID = 42161
export const BSC_CHAIN_ID = 56
// 1124.20 lk_59335765 278015130147

export const TWOPAW_DECIMALS = 18

export const ROLE = {
    SUPPLY: 0,
    BORROW: 1
}
export const STATUS = {
    OPEN: 0,
    WORKING: 1,
    CLOSED: 2,
    CANCELED: 3,
}
export const DEFAULT_VIEW_ORDERS_COUNT = 25
