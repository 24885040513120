import {Container} from "react-bootstrap";
import React, {useEffect} from "react";
import Header from "./components/Header/Header";
import Content from "./components/Content/Content";
import {useRecoilState, useSetRecoilState} from "recoil";
import {
    addressesAtom,
    allOrdersAtom,
    appInFocusAtom, lastOrderTimestampAtom,
    tokenPricesAtom,
    tokensAtom
} from "./state/atoms/globalAtoms";
import {useEthers} from "@usedapp/core";
import {getAddresses, getTokens, getTokensArray, getWETHAddress} from "./utilities";
import {
    getAllOrders,
    getProtocolContractWithProvider
} from "./contracts/methods/protocolMethods";
import {getTokensPrices} from "./contracts/methods/oracle";
import {chosenCollateralFilterTokenAtom, chosenLoanFilterTokenAtom} from "./state/atoms/contentAtoms";
import CurrentOrderModal from "./components/global/CurrentOrder/CurrentOrderModal";
import NewLoanModal from "./components/global/NewLoan/NewLoanModal";
import {MAINNET_CHAIN_ID} from "./constants";
import TokenomicInfoModal from "./components/global/Info/TokenomicInfoModal";
import {BigNumber, utils} from "ethers";


function App() {
    const [appInFocus, setAppInFocus] = useRecoilState(appInFocusAtom)
    const setTokens = useSetRecoilState(tokensAtom)
    const setAddresses = useSetRecoilState(addressesAtom)
    // const [protocolSettings, setProtocolSettings] = useRecoilState(protocolSettingsAtom)
    const [allOrders, setAllOrders] = useRecoilState(allOrdersAtom)
    const setTokenPrices = useSetRecoilState(tokenPricesAtom)
    const setChosenLoanToken = useSetRecoilState(chosenLoanFilterTokenAtom)
    const setChosenCollateralToken = useSetRecoilState(chosenCollateralFilterTokenAtom)
    const [lastOrderTimestamp, setLastOrderTimestamp] = useRecoilState(lastOrderTimestampAtom)


    const {chainId} = useEthers()
    const onFocus = () => {
        setAppInFocus(true)
    };
    const onBlur = () => {
        setAppInFocus(false)
    };
    const updateAllOrders = (currentChainId) => {
        if (currentChainId && currentChainId === chainId) {
            setTokens({
                tokens: getTokens(currentChainId),
                chainId: chainId
            })
            getAllOrders(currentChainId)
                .then(rawOrders => {
                    const ordersData = {
                        orders: rawOrders,
                        chainId: currentChainId
                    }
                    setAllOrders(ordersData)
                    // console.log(ordersData)
                })
        }
    }
    // const updateProtocolSettings = async () => {
    //     if (chainId) {
    //         // console.log("first", chainId)
    //         const supportedTokensArbitrum = await getSupportedTokens(chainId)
    //         const tokensSettings = {}
    //         for (let i = 0; i < supportedTokensArbitrum.length; i++) {
    //             const supportedToken = supportedTokensArbitrum[i]
    //             tokensSettings[supportedTokensArbitrum[i]] = await getSupportedTokenSettings(supportedToken, chainId)
    //         }
    //
    //         const maxRewardDays = await getMaxRewardDays(chainId)
    //         const minPartOfLoanAmount = await getMinPartOfLoanAmount(chainId)
    //         const maxPartOfLoanAmount = await getMaxPartOfLoanAmount(chainId)
    //         const DAOFeePercent = await getDAOFeePercent(chainId)
    //         setProtocolSettings({
    //             ...protocolSettings,
    //             supportedTokensArbitrum: tokensSettings,
    //             maxRewardDays,
    //             minPartOfLoanAmount,
    //             maxPartOfLoanAmount,
    //             DAOFeePercent,
    //         })
    //
    //         // console.log("second", chainId)
    //     }
    // }
    useEffect(() => {
        window.addEventListener("focus", onFocus)
        window.addEventListener("blur", onBlur)
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        }
    }, [])
    // useEffect(() => {
    //     if (chainId) {
    //         const contractEvents = getProtocolContractWithProvider(chainId)
    //         contractEvents.on("NewOrder", (orderId, order) => {
    //             setLastOrderTimestamp(Date.now())
    //         })
    //         contractEvents.on("OrderStatusChange", (orderId, status) => {
    //             setLastOrderTimestamp(Date.now())
    //         })
    //         return () => contractEvents.removeAllListeners()
    //     }
    //
    // }, [])
    useEffect(() => {
        setAddresses(getAddresses(chainId))
        updateAllOrders(chainId)
        // updateProtocolSettings()
        setChosenLoanToken(null)
        setChosenCollateralToken(null)
    }, [chainId])
    useEffect(() => {
        if (lastOrderTimestamp) {
            updateAllOrders(chainId)
        }
    }, [lastOrderTimestamp])
    // if (allOrders){
    //     console.log(allOrders.orders)
    // }
    useEffect(() => {
        if (allOrders) {
            // const TWOPAWAddress = getAddresses(allOrders.chainId).tokenAddress
            const WETHAddress = getWETHAddress(allOrders.chainId)
            const tokensArray = getTokensArray(allOrders.orders)
            // tokensArray.push(TWOPAWAddress)
            tokensArray.push(WETHAddress)
            getTokensPrices(tokensArray, allOrders.chainId)
                .then(prices => {
                    // console.log(prices)
                    setTokenPrices({prices: prices, chainId: chainId})
                })
            const interval = setInterval(() => {
                if (appInFocus) {
                        getTokensPrices(tokensArray, allOrders.chainId)
                            .then(prices => {
                                setTokenPrices({prices: prices, chainId: chainId})
                            })
                    }
            }, 1000 * 10);
            return () => clearInterval(interval);
        }

    }, [allOrders])

    return (
        <div>
            <Header/>
            <Container>
                <Content/>
            </Container>
            <CurrentOrderModal/>
            <NewLoanModal/>
            <TokenomicInfoModal/>
        </div>
    );
}

export default App;
