import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useEthers, useTokenBalance} from "@usedapp/core";
import {getAddresses, makeHumanAmount} from "../../../utilities";
import {utils} from "ethers";
import {supportedTokensArbitrum} from "../../../supportedTokensArbitrum";
import {BSC_CHAIN_ID, STATUS} from "../../../constants";
import {getNecessaryBalanceForReward} from "../../../contracts/methods/protocolMethods";
import {supportedTokensBSC} from "../../../supportedTokensBSC";

const RewardInfo = (props) => {
    const [rewardAmount, setRewardAmount] = useState("0")
    const [necessaryBalance, setNecessaryBalance] = useState("0")

    const {account, chainId} = useEthers()
    const TWOPAWBalance = useTokenBalance(getAddresses(chainId).tokenAddress, account)
    const humanBalance = TWOPAWBalance ? utils.formatUnits(TWOPAWBalance, 18) : "0"
    let balanceColor
    let rewardColor
    const b = "20"
    if (props.order.status === STATUS.OPEN) {
        if (parseFloat(rewardAmount) > parseFloat(humanBalance)) {
            balanceColor = "#AD2D2D"
            rewardColor = "#AD2D2D"
        } else {
            balanceColor = "#9597a1"
            rewardColor = "#40B842"
        }
    } else {
        balanceColor = "#9597a1"
        rewardColor = "#40B842"
    }
    if (props.order.borrower === account && props.order.status === STATUS.WORKING && chainId) {
        getNecessaryBalanceForReward(account, chainId)
            .then(balance => {
                const necessaryBalanceForReward = utils.formatUnits(balance, 18)

                setNecessaryBalance(necessaryBalanceForReward)
            })
    }
    if (parseFloat(necessaryBalance) > parseFloat(b)) {
        balanceColor = "#AD2D2D"
        rewardColor = "#AD2D2D"
    }

    useEffect(() => {
        if (props.order) {
            if (props.order.status === STATUS.OPEN) {
                const interval = setInterval(() => {
                    // const supportedLoanToken = supportedTokensArbitrum[props.order.loanToken.address] // rewardDenominator
                    const supportedLoanToken = chainId === BSC_CHAIN_ID ? supportedTokensBSC[props.order.loanToken.address] : supportedTokensArbitrum[props.order.loanToken.address] // rewardDenominator

                    const denominator = supportedLoanToken.denominator
                    const timeDelta = Math.floor(Date.now() / 1000 - props.order.openTimestamp)
                    const denominatorCount = parseFloat(utils.formatUnits(props.order.loanAmount, supportedLoanToken.decimals)) / denominator
                    const rewardAmountPerDay = denominatorCount * supportedLoanToken.rewardAmountPerDayForDenominator
                    const rewardAmountPerSecond = rewardAmountPerDay / 86400
                    let reward = timeDelta * rewardAmountPerSecond
                    reward = reward <= 40000 ? reward : 40000
                    setRewardAmount(reward.toString())
                }, 1000)
                return () => clearInterval(interval);
            } else {
                setRewardAmount(utils.formatUnits(props.order.rewardAmount, 18))
            }

        }

    }, [])
    return (
        <div>
            {props.order.lender !== account
                ? <div>
                    <Row>
                        <Col className="text-end" style={{fontSize: 10, color: balanceColor}}>
                            TWOPAW Balance: {makeHumanAmount(humanBalance)} TWOPAW
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} style={{color: "#9597a1"}}>
                            Reward
                        </Col>
                        <Col className="text-end" style={{color: rewardColor}}>
                            {makeHumanAmount(rewardAmount)} TWOPAW
                        </Col>
                    </Row>
                </div>
                : <></>}

            {parseFloat(rewardAmount) > parseFloat(humanBalance) && props.order.status === STATUS.OPEN && props.order.lender !== account
                ? <Row>
                    <Col className="text-center" style={{color: "#AD2D2D", paddingTop: 5}}>In order to receive rewards,
                        the amount of TWOPAW you have in your account needs to be higher than the amount of
                        rewards.</Col>
                </Row>
                : <></>}
            {parseFloat(necessaryBalance) > parseFloat(b)
                ? <Row>
                    <Col className="text-center" style={{color: "#AD2D2D", paddingTop: 5}}>In order to receive a reward,
                        you need to have {makeHumanAmount(necessaryBalance)} TWOPAW.</Col>
                </Row>
                : <></>}
            <br/>
        </div>
    );
};

export default RewardInfo;