import {Contract} from "ethers";
import {getAddresses, getProvider} from "../../utilities";
import {getOracleInterface} from "../interfaces/interfaces";

const getOracleContract = (chainId) => {
    return new Contract(getAddresses(chainId).oracleAddress, getOracleInterface(chainId), getProvider(chainId))
}

export const getTokensPrices = async (tokensArray, chainId) => {
    if (tokensArray){
        return await getOracleContract(chainId).getPrices(tokensArray)
    }else {
        return []
    }
}// TODO Undefined
export const getTokenPriceQuote = async (base, quote, decimals, chainId) => {
    return await  getOracleContract(chainId).getQuote(base, quote, decimals)
}