import React from 'react';

const ValueOfField = (props) => {
    return (
        <div>
            <div className="d-none d-lg-block">
                <div>
                    {props.img
                        ? <img
                            style={{marginTop: -5, marginRight: 10}}
                            src={props.img}
                            alt="img"
                            width={20}
                            height={20}
                        />
                        : <></>}
                    {props.value} {props.symbol ? props.symbol: <></>}
                </div>
            </div>
            <div className="d-lg-none">
                <div style={{fontSize: 15}}>
                    {props.img
                        ? <img
                            style={{marginTop: -5, marginRight: 10}}
                            src={props.img}
                            alt="img"
                            width={20}
                            height={20}
                        />
                        : <></>}
                    {props.value} {props.symbol ? props.symbol: <></>}
                </div>
            </div>
        </div>
    );
};

export default ValueOfField;