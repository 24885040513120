import React, {useState} from 'react';
import "./newLoan.css"
import {Col, DropdownButton, Row, Dropdown, Form} from "react-bootstrap";
import {useEthers, useTokenBalance} from "@usedapp/core";
import info from "../../../assets/svg/info-circle.svg"
import reward from "../../../assets/svg/reward.svg"
import {
    catchMetamaskErrors,
    formatAmount,
    getScanner,
    getWETHAddress,
    makeHumanAmount,
    processTransaction
} from "../../../utilities";
import Max from "../Max";
import StandardButton from "../StandardButton";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {newLoanChooseAtom, tokenomicsInfoModalAtom} from "../../../state/atoms/contentAtoms";
import {BORROW, SUPPLY} from "../../../constants";
import {percentStyle} from "../../../styles/contentStyles";
import {depositWETH} from "../../../contracts/methods/ERC20Methods";
import {utils} from "ethers";
import {protocolSettingsAtom} from "../../../state/atoms/globalAtoms";
import PawLoading from "../PawLoading";

const AmountSettings = (props) => {
    const [depositWaiting, setDepositWaiting] = useState(false)
    const newLoanChoose = useRecoilValue(newLoanChooseAtom)
    const protocolSettings = useRecoilValue(protocolSettingsAtom)
    const setShowTokenomicInfoModal = useSetRecoilState(tokenomicsInfoModalAtom)

    const {account, chainId, library} = useEthers()
    const balance = useTokenBalance(props.token.address, account)

    const maxButtonHandler = () => {
        props.setAmount(formatAmount(balance, props.token.decimals))
    }
    const setAmount = (payload) => {
        if (payload === "") {
            props.setAmount(payload)
        } else if (payload.match("^[0-9]*[.]?[0-9]*$")) {
            props.setAmount(payload)
        }
    }
    const renderBalance = () => {
        return (
            <Row>
                <Col xs={11} className="text-truncate">Balance: {balance
                    ? " " + makeHumanAmount(formatAmount(balance, props.token.decimals)) + " " + props.token.symbol
                    : " 0 " + props.token.symbol}
                </Col>
                <Col xs={1} style={{padding: 0}}>
                    <Max maxButtonHandler={maxButtonHandler}/>
                </Col>
            </Row>
        )
    }
    const renderPrice = () => {
        if (parseFloat(props.tokenPrice) === 0) {
            return (
                <Row>
                    <Col>Price Not Found</Col>
                </Row>
            )
        }
        return (
            <Row>
                <Col className="text-truncate">1 {props.token.symbol} = {makeHumanAmount(props.tokenPrice)} $</Col>
            </Row>
        )
    }
    const renderBalanceOrPrice = () => {
        if (newLoanChoose === SUPPLY) {
            if (props.isCollateral) {
                return (
                    renderPrice()
                )
            } else {
                return (
                    renderBalance()
                )
            }
        } else {
            if (!props.isCollateral) {
                return (
                    renderPrice()
                )
            } else {
                return (
                    renderBalance()
                )
            }
        }
    }
    const depositHandler = async () => {
        setDepositWaiting(true)
        const bigDepositAmount = utils.parseUnits(props.amount, props.token.decimals)
        await depositWETH(bigDepositAmount, library.getSigner(account), chainId)
            .then(tx => {
                processTransaction(tx)
                    .then((res) => {
                        setDepositWaiting(false)
                    })
            })
            .catch(error => {
                catchMetamaskErrors(error)
                setDepositWaiting(false)
            })
    }
    const renderDepositButton = (text) => {
        return (
            <StandardButton
                text={text}
                style={text === "Deposit" ? {marginTop: 9, width: "100%"} : {}}
                loading={depositWaiting}
                onClick={depositHandler}
                disabled={parseFloat(props.amount) === 0 || props.amount === ""}
            />
        )
    }
    const renderDepositOrPercent = () => {

        if (newLoanChoose === SUPPLY) {
            if (props.isCollateral) {
                return (
                    <div className="text-truncate text-center"
                         style={{...percentStyle, ...percentColor, margin: "9px auto 0"}}>
                        {props.percent ? Math.round(props.percent) : 0}%
                    </div>
                )
            } else {
                if (props.token.address === getWETHAddress(chainId)) {
                    return (renderDepositButton("Deposit"))
                } else return <></>
            }
        } else {
            if (!props.isCollateral) {
                return (
                    <></>
                )
            } else {
                if (props.token.address === getWETHAddress(chainId)) {
                    return (renderDepositButton("Deposit"))
                } else return <div className="text-truncate text-center"
                                        style={{...percentStyle, ...percentColor, margin: "9px auto 0", marginTop: 9}}>
                    {props.percent ? Math.round(props.percent) : 0}%
                </div>

            }
        }
    }
    const renderDepositWETH = () => {
        if (newLoanChoose === SUPPLY) {
            if (!props.isCollateral && props.token.address === getWETHAddress(chainId)) {
                return (
                    <div className="d-sm-none">
                        <Row>
                            <Col xs={1}></Col>
                            <Col xs={10} className="text-center">
                                {renderDepositButton("Deposit WETH")}
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <br/>
                    </div>
                )
            } else {
                return <></>
            }
        } else {
            if (props.isCollateral && props.token.address === getWETHAddress(chainId)) {
                return (
                    <div className="d-sm-none">
                        <Row>
                            <Col xs={1}></Col>
                            <Col xs={10} className="text-center">
                                {renderDepositButton("Deposit WETH")}
                            </Col>
                            <Col xs={1}></Col>
                        </Row>
                        <br/>
                    </div>
                )
            } else {
                return <></>
            }
        }
    }
    let percentColor
    if (props.percent < 120) {
        percentColor = {border: "2px solid #dc3545"}
    } else if (props.percent < 160) {
        percentColor = {border: "2px solid #fdc54b"}
    } else {
        percentColor = {border: "2px solid #00F7A7"}
    }
    return (
        <div>
            {renderDepositWETH()}
            <Row style={{width: "100%", paddingLeft: 10}}>
                <Col xs={6} className="loan-settings-header-text">{props.text}
                    {protocolSettings.loading
                    ?<PawLoading width={20}/>
                    : props.isTokenRewarded
                        ? <img src={reward} alt="reward"
                        className="loan-settings-header-reward-icon"
                        // onClick={() => setShowTokenomicInfoModal(2)}
                        />
                        : <img src={info} alt="info"
                        style={{paddingLeft: 5, marginTop: -5, cursor: "pointer", width: 20, height: 20}}
                        // onClick={() => setShowTokenomicInfoModal(2)}
                        />}

                </Col>
                <Col xs={6} className="text-end loan-settings-header-balance">
                    {renderBalanceOrPrice()}
                </Col>
            </Row>
            <Row style={{border: "1px solid #454F5B", height: 60, borderRadius: 12, width: "100%", margin: "0 auto"}}>
                <Col xs={2} sm={1} className="d-none d-sm-block">
                    <img
                        onClick={() => window.open(`https://${getScanner(chainId)}/address/${props.token.address}`, "_blank")}

                        className="loan-settings-img"
                        src={props.token.img}
                        alt="img"
                        width={30}
                        height={30}
                    />
                </Col>
                <Col xs={5} sm={3}>
                    <DropdownButton
                        style={{marginTop: 9}}
                        menuVariant="dark" variant="dark"
                        title={props.token.symbol}
                    >
                        {props.tokens
                            .map((token, index) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => props.setToken(token)}
                                    >
                                        <img
                                            style={{marginRight: "10px"}}
                                            src={token.img} alt="img"
                                            width={25}
                                            height={25}
                                        />
                                        {token.symbol}</Dropdown.Item>
                                )
                            })}
                    </DropdownButton>
                </Col>
                <Col xs={2} sm={1} className="d-xs-block d-sm-none" style={{paddingLeft: 0}}>
                    {props.isCollateral
                        ? <div className="text-truncate text-center"
                               style={{...percentStyle, ...percentColor, marginTop: 10}}>
                            {props.percent ? Math.round(props.percent) : 0}%
                        </div>
                        : <></>}

                </Col>
                <Col xs={5} sm={4}>
                    <Form.Control
                        autoComplete="off"
                        id="new-loan-amount"
                        size="sm"
                        style={{marginTop: 9, width: "100%"}}
                        type="text"
                        className="new-amount"
                        value={props.amount}
                        //TODO disabled={loanInputDisabled}
                        onFocus={() => setAmount("")}
                        onChange={(event) => setAmount(event.target.value)}
                    />
                </Col>
                <Col sm={4} className="d-none d-sm-block">
                    {renderDepositOrPercent()}
                </Col>


            </Row>
            <br/>

        </div>

    );
};

export default AmountSettings;