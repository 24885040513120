import React from 'react';
import {useRecoilValue} from "recoil";
import {chosenPageAtom} from "../../state/atoms/globalAtoms";
import {DASHBOARD, MY_ACCOUNT, ORDERS_BOOK} from "../../constants";
import Dashboard from "./Dashboard/Dashboard";
import OrdersBook from "./OrdersBook/OrdersBook";
import MyAccount from "./MyAccount/MyAccount";

const Content = () => {
    const chosenPage = useRecoilValue(chosenPageAtom)
    const renderContent = () => {
        switch (chosenPage) {
            case DASHBOARD:
                return(
                    <Dashboard />
                )
            case ORDERS_BOOK:
                return(
                    <OrdersBook/>
                )
            case MY_ACCOUNT:
                return(
                    <MyAccount/>
                )
            default:
                break
        }
    }
    return (
        <div>
            {renderContent()}
        </div>
    );
};

export default Content;