import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import PawLoading from "./PawLoading";
const activeStyle = {
    backgroundColor: "#182539",
    border: "1px solid #383944",
    width: "80%"
}
const inactiveStyle = {
    backgroundColor: "#203349",
    border: "1px solid #454F5B",
    width: "80%"
}

const StandardButton = (props) => {

    const [isHoveredButton, setIsHoveredButton] = useState(false)
    return (
        <Button
            style={isHoveredButton || props.active
                ? {...activeStyle, width: props.width ? props.width : activeStyle.width, ...props.style}
                : {...inactiveStyle, width: props.width ? props.width : inactiveStyle.width, ...props.style}}
            onMouseEnter={() => setIsHoveredButton(true)}
            onMouseLeave={() => setIsHoveredButton(false)}
            onClick={props.onClick}
            disabled={props.disabled}
            className={`text-truncate ${props.class}`}
        >
            {props.loading ? <PawLoading/> : props.text}
        </Button>
    );
};

export default StandardButton;