import "./NewLoan/newLoan.css"

const Max = (props) => {

    return (
        <span
            className="loan-settings-header-max"
            onClick={props.maxButtonHandler}
        >Max</span>
    );
};

export default Max;