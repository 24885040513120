import React, {useState} from 'react';
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {marketsSorterDesktopStyle} from "../../../styles/contentStyles";
import info from "../../../assets/svg/info-circle.svg"
import doubleArrows from "../../../assets/svg/arrow-double.svg"
import arrowUp from "../../../assets/svg/mini-arrow-up.svg"
import arrowDown from "../../../assets/svg/mini-arrow-down.svg"
import Orders from "./Orders";
import {formatAmount} from "../../../utilities";
import {
    chosenCollateralFilterTokenAtom,
    chosenLoanFilterTokenAtom,
    viewOrdersCountAtom
} from "../../../state/atoms/contentAtoms";
import {useRecoilState, useRecoilValue} from "recoil";
import StandardButton from "../../global/StandardButton";
import {DEFAULT_VIEW_ORDERS_COUNT} from "../../../constants";

const doubleArrowsRender = () => {
    return (
        <img src={doubleArrows} alt="doubleArrows" width={26} height={26}
             style={{paddingRight: 5, marginTop: -3, overflow: "unset"}}
        />
    )
}
const arrowUpRender = () => {
    return (
        <img src={arrowDown} alt="arrowsDown" width={26} height={26}
             style={{paddingRight: 5, marginTop: -3, overflow: "unset"}}
        />
    )
}
const arrowDownRender = () => {
    return (
        <img src={arrowUp} alt="arrowsDown" width={26} height={26}
             style={{paddingRight: 5, marginTop: -3, overflow: "unset"}}
        />
    )
}

const OrdersMarket = (props) => {
    const [loanAmountSort, setLoanAmountSort] = useState(0)
    const [collateralAmountSort, setCollateralAmountSort] = useState(0)
    const [feeSort, setFeeSort] = useState(0)
    const [expiredSort, setExpiredSort] = useState(0)
    const [rewardSort, setRewardSort] = useState(0)
    const [viewOrdersCount, setViewOrdersCount] = useRecoilState(viewOrdersCountAtom)
    const chosenLoanFilterToken = useRecoilValue(chosenLoanFilterTokenAtom)
    const chosenCollateralFilterToken = useRecoilValue(chosenCollateralFilterTokenAtom)
    const [fullOrders, setFulOrders] = useState(false)

    const loanAmountSortHandler = () => {
        if (loanAmountSort === 2) {
            setLoanAmountSort(1)
        } else {
            setLoanAmountSort(prev => {
                return prev + 1
            })
        }
        setCollateralAmountSort(0)
        setFeeSort(0)
        setExpiredSort(0)
        setRewardSort(0)
    }
    const collateralAmountSortHandler = () => {
        if (collateralAmountSort === 2) {
            setCollateralAmountSort(1)
        } else {
            setCollateralAmountSort(prev => {
                return prev + 1
            })
        }
        setLoanAmountSort(0)
        setFeeSort(0)
        setExpiredSort(0)
        setRewardSort(0)
    }
    const feeSortHandler = () => {
        if (feeSort === 2) {
            setFeeSort(1)
        } else {
            setFeeSort(prev => {
                return prev + 1
            })
        }
        setLoanAmountSort(0)
        setCollateralAmountSort(0)
        setExpiredSort(0)
        setRewardSort(0)
    }
    const expiredSortHandler = () => {
        if (expiredSort === 2) {
            setExpiredSort(1)
        } else {
            setExpiredSort(prev => {
                return prev + 1
            })
        }
        setLoanAmountSort(0)
        setCollateralAmountSort(0)
        setFeeSort(0)
        setRewardSort(0)
    }
    const rewardSortHandler = () => {
        if (rewardSort === 2) {
            setRewardSort(1)
        } else {
            setRewardSort(prev => {
                return prev + 1
            })
        }
        setLoanAmountSort(0)
        setCollateralAmountSort(0)
        setFeeSort(0)
        setExpiredSort(0)
    }

    const renderLoanAmountSort = () => {
        switch (loanAmountSort) {
            case 0:
                return (doubleArrowsRender())
            case 1:
                return (arrowUpRender())
            case 2:
                return (arrowDownRender())
            default:
                break
        }
    }
    const renderCollateralAmountSort = () => {
        switch (collateralAmountSort) {
            case 0:
                return (doubleArrowsRender())
            case 1:
                return (arrowUpRender())
            case 2:
                return (arrowDownRender())
            default:
                break
        }
    }
    const renderFeeSortAmountSort = () => {
        switch (feeSort) {
            case 0:
                return (doubleArrowsRender())
            case 1:
                return (arrowUpRender())
            case 2:
                return (arrowDownRender())
            default:
                break
        }
    }
    const renderExpiredSort = () => {
        switch (expiredSort) {
            case 0:
                return (doubleArrowsRender())
            case 1:
                return (arrowUpRender())
            case 2:
                return (arrowDownRender())
            default:
                break
        }
    }
    const renderRewardSort = () => {
        switch (rewardSort) {
            case 0:
                return (doubleArrowsRender())
            case 1:
                return (arrowUpRender())
            case 2:
                return (arrowDownRender())
            default:
                break
        }
    }

    const renderInfoTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            This commission is charged from the loan amount once.
        </Tooltip>
    )
    const renderSorter = () => {
        return (
            <Row>
                <Col
                    xs={3}
                    className="text-center text-truncate select-none"
                    onClick={loanAmountSortHandler}
                    style={{cursor: "pointer", paddingRight: 40}}
                >Loan Amount
                    {renderLoanAmountSort()}
                </Col>
                <Col
                    xs={3}
                    className="text-center text-truncate select-none"
                    onClick={collateralAmountSortHandler}
                    style={{cursor: "pointer", paddingRight: 40}}
                >Collateral Amount
                    {renderCollateralAmountSort()}
                </Col>
                <Col
                    xs={2}
                    className="text-center text-truncate select-none"
                    onClick={feeSortHandler}
                    style={{cursor: "pointer", paddingLeft: 20}}
                ><OverlayTrigger
                    placement="top"
                    delay={{show: 250, hide: 500}}
                    overlay={renderInfoTooltip}
                ><img src={info} alt="info" width={23} height={23}
                      style={{paddingRight: 5, marginTop: -3, overflow: "unset"}}
                /></OverlayTrigger>
                    Lender Fee
                    {renderFeeSortAmountSort()}
                </Col>
                <Col
                    xs={2}
                    className="text-center text-truncate select-none"
                    onClick={expiredSortHandler}
                    style={{cursor: "pointer", paddingLeft: 45}}
                >Duration
                    {renderExpiredSort()}
                </Col>
                <Col xs={2}
                     className="text-center text-truncate select-none"
                     onClick={rewardSortHandler}
                     style={{cursor: "pointer", paddingLeft: 45}}
                >
                    Reward
                    {renderRewardSort()}
                </Col>
            </Row>
        )
    }

    const getSortedOrders = () => {
        const filterOrders = [...props.filterOrders]
        let sortedOrders = []
        if (props.filterOrders.length > 0){
            if (loanAmountSort !== 0) {
                if (loanAmountSort === 1) {
                    sortedOrders = filterOrders.sort((a, b) => formatAmount(b.getLoanUSDEquals(), b.loanToken.decimals) - formatAmount(a.getLoanUSDEquals(), a.loanToken.decimals))
                } else if (loanAmountSort === 2) {
                    sortedOrders = filterOrders.sort((a, b) => formatAmount(a.getLoanUSDEquals(), a.loanToken.decimals) - formatAmount(b.getLoanUSDEquals(), b.loanToken.decimals))
                } else {
                    sortedOrders = filterOrders.sort((a, b) => b.id - a.id)
                }
            } else if (collateralAmountSort !== 0) {
                if (collateralAmountSort === 1) {
                    sortedOrders = filterOrders.sort((a, b) => formatAmount(b.getCollateralUSDEquals(), b.collateralToken.decimals) - formatAmount(a.getCollateralUSDEquals(), a.collateralToken.decimals))
                } else if (collateralAmountSort === 2) {
                    sortedOrders = filterOrders.sort((a, b) => formatAmount(a.getCollateralUSDEquals(), a.collateralToken.decimals) - formatAmount(b.getCollateralUSDEquals(), b.collateralToken.decimals))
                } else {
                    sortedOrders = filterOrders.sort((a, b) => b.id - a.id)
                }
            } else if (feeSort !== 0) {
                if (feeSort === 1) {
                    sortedOrders = filterOrders.sort((a, b) => b.getFloatLenderFeePercent() - a.getFloatLenderFeePercent())
                } else if (feeSort === 2) {
                    sortedOrders = filterOrders.sort((a, b) => a.getFloatLenderFeePercent() - b.getFloatLenderFeePercent())
                } else {
                    sortedOrders = filterOrders.sort((a, b) => b.id - a.id)
                }
            } else if (expiredSort !== 0) {
                if (expiredSort === 1) {
                    sortedOrders = filterOrders.sort((a, b) => b.duration.getJSRepayEndsTimestamp() - a.duration.getJSRepayEndsTimestamp())
                } else if (expiredSort === 2) {
                    sortedOrders = filterOrders.sort((a, b) => a.duration.getJSRepayEndsTimestamp() - b.duration.getJSRepayEndsTimestamp())
                } else {
                    sortedOrders = filterOrders.sort((a, b) => b.id - a.id)
                }
            } else if (rewardSort !== 0) {
                if (rewardSort === 1) {
                    sortedOrders = filterOrders.sort((a, b) => b.getFloatRewardAmount - a.getFloatRewardAmount)
                } else if (rewardSort === 2) {
                    sortedOrders = filterOrders.sort((a, b) => a.getFloatRewardAmount - b.getFloatRewardAmount)
                } else {
                    sortedOrders = filterOrders.sort((a, b) => b.id - a.id)
                }
            } else sortedOrders = filterOrders.sort((a, b) => b.id - a.id)
            sortedOrders = sortedOrders.filter(item => item.duration.getJSRepayEndsTimestamp() >= item.duration.getJSRepayStartsTimestamp() + 600)

            sortedOrders =  sortedOrders
                .filter(item => {
                    if (chosenLoanFilterToken) {
                        return item.loanToken.address === chosenLoanFilterToken.address
                    } else return item
                })
                .filter(item => {
                    if (chosenCollateralFilterToken) {
                        return item.collateralToken.address === chosenCollateralFilterToken.address
                    } else return item
                })
            if (sortedOrders.length >= viewOrdersCount){
                if (fullOrders){
                    setFulOrders(false)
                }
                return sortedOrders.slice(0, viewOrdersCount)
            }else {
                if (!fullOrders){
                    setFulOrders(true)
                }
                return sortedOrders
            }

        }else return []

    }

    return (
        <div>
            <div className="d-none d-lg-block">
                <div style={marketsSorterDesktopStyle}>
                    {renderSorter()}
                </div>
                <div>
                    <Orders sortedOrders={getSortedOrders()}/>
                    <br/>
                    {!fullOrders
                        ?<div className="text-center"><StandardButton
                            width="40%"
                            text="See More"
                            onClick={() => setViewOrdersCount(viewOrdersCount + DEFAULT_VIEW_ORDERS_COUNT)}
                        /></div>
                        :<div></div>}
                </div>
            </div>
            <div className="d-lg-none">
                <div style={{...marketsSorterDesktopStyle, minWidth: "1000px", whiteSpace: "nowrap"}}>
                    {renderSorter()}
                </div>
                <div
                    style={{minWidth: "1000px", whiteSpace: "nowrap"}}>
                    <Orders sortedOrders={getSortedOrders()}/>
                    <br/>
                    {!fullOrders
                    ?<div className="text-center"><StandardButton
                            width="40%"
                            text="See More"
                            onClick={() => setViewOrdersCount(viewOrdersCount + DEFAULT_VIEW_ORDERS_COUNT)}
                        /></div>
                    :<div></div>}

                </div>

            </div>
        </div>
    );
};

export default OrdersMarket;