import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {DAppProvider, Mainnet, Arbitrum, BSC} from '@usedapp/core'
import {RecoilRoot} from "recoil";
import {ARBITRUM_PROVIDER_URL, MAINNET_PROVIDER_URL, BSC_PROVIDER_URL} from "./constants";
import {ToastContainer} from "react-toastify";

const config = {
    readOnlyChainId: Arbitrum.chainId,
    readOnlyUrls: {
        [Arbitrum.chainId]: ARBITRUM_PROVIDER_URL,
        [Mainnet.chainId]: MAINNET_PROVIDER_URL,
        // [BSC.chainId]: BSC_PROVIDER_URL
    },
    networks: [Arbitrum, Mainnet],
    autoConnect: true
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <RecoilRoot>
//     <DAppProvider config={config}>
//         <App/>
//         <ToastContainer
//             autoClose={3000}
//             theme={"dark"}
//         />
//     </DAppProvider>
//     </RecoilRoot>
// );
ReactDOM.render(
    <RecoilRoot>
        <DAppProvider config={config}>
            <App/>
            <ToastContainer
                autoClose={3000}
                theme={"dark"}
            />
        </DAppProvider>
    </RecoilRoot>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
