import React from 'react';
import {Col, DropdownButton, Row, Dropdown} from "react-bootstrap";
import {useRecoilState, useRecoilValue} from "recoil";
import {chosenCollateralFilterTokenAtom, chosenLoanFilterTokenAtom} from "../../../state/atoms/contentAtoms";
import {tokensAtom} from "../../../state/atoms/globalAtoms";
const filterStyle = {
    width: "100%",
    minHeight: "70px",
    borderRadius: "20px",
    backgroundColor: "#1c2c42",
    marginBottom: "30px",
    paddingTop: "14px",
    paddingLeft: "14px"
}
const Filters = () => {
    const [chosenLoanToken, setChosenLoanToken] = useRecoilState(chosenLoanFilterTokenAtom)
    const [chosenCollateralToken, setChosenCollateralToken] = useRecoilState(chosenCollateralFilterTokenAtom)
    const tokens = useRecoilValue(tokensAtom)

    const renderLoanFilters = () => {
        return(
            <Col>
                <Row>
                    <Col xs={3} sm={4} style={{position: "relative"}} className="text-center">
                        {chosenLoanToken
                            ?<img className="table-img"
                                  src={chosenLoanToken.img} alt="img"
                                  width={30}
                                  height={30}
                            />
                            :<div style={{paddingTop: 3}}>
                                <img className="table-img"
                                     src="https://twopaws.io/token-icons/default.png" alt="img"
                                     width={30}
                                     height={30}
                                />
                            </div>}
                    </Col>
                    <Col xs={9} sm={8} className="justify-content-center">
                        <DropdownButton
                            id="new-loan-dropdown"
                            style={{border: "0px solid", width: "80%"}}
                            menuVariant="dark" variant="dark"
                            title={chosenLoanToken ? chosenLoanToken.symbol : "Filter"}
                        >
                            {chosenLoanToken
                                ? <><Dropdown.Item>
                                    <div className="text-center"
                                         onClick={() => setChosenLoanToken(null)}
                                    >Reset
                                    </div>
                                </Dropdown.Item>
                                    <Dropdown.Divider/></>
                                :<></>
                            }
                            {tokens && tokens.tokens
                                .map((token, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => setChosenLoanToken(token)}
                                        >
                                            <img
                                                style={{marginRight: "10px"}}
                                                src={token.img} alt="img"
                                                width={25}
                                                height={25}
                                            />
                                            {token.symbol}</Dropdown.Item>
                                    )
                                })}
                        </DropdownButton>
                    </Col>
                </Row>
            </Col>
        )
    }

    const renderCollateralFilters = () => {
        return(
            <Col>
                <Row>
                    <Col xs={3} sm={4} style={{position: "relative"}} className="text-center">
                        {chosenCollateralToken
                            ?<img className="table-img"
                                  src={chosenCollateralToken.img} alt="img"
                                  width={30}
                                  height={30}
                            />
                            : <div style={{paddingTop: 3}}>
                                <img className="table-img"
                                     src="https://twopaws.io/token-icons/default.png" alt="img"
                                     width={30}
                                     height={30}
                                />
                            </div>}
                    </Col>
                    <Col xs={9} sm={8} className="justify-content-center">
                        <DropdownButton
                            id="new-loan-dropdown"
                            style={{border: "0px solid", width: "80%"}}
                            menuVariant="dark" variant="dark"
                            title={chosenCollateralToken ? chosenCollateralToken.symbol : "Filter"}
                        >
                            {chosenCollateralToken
                                ? <><Dropdown.Item>
                                    <div className="text-center"
                                         onClick={() => setChosenCollateralToken(null)}
                                    >Reset
                                    </div>
                                </Dropdown.Item>
                                    <Dropdown.Divider/></>
                                :<></>
                            }

                            {tokens && tokens.tokens
                                .map((token, index) => {
                                    return (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => setChosenCollateralToken(token)}
                                        >
                                            <img
                                                style={{marginRight: "10px"}}
                                                src={token.img} alt="img"
                                                width={25}
                                                height={25}
                                            />
                                            {token.symbol}</Dropdown.Item>
                                    )
                                })}
                        </DropdownButton>
                    </Col>
                </Row>
            </Col>
        )
    }
    const renderFilters = () => {
        return (
            <div style={filterStyle}>
                <Row>
                    <Row>
                        {renderLoanFilters()}
                        {renderCollateralFilters()}
                    </Row>
                </Row>
            </div>
        )

    }

    return (
        <div>
            {renderFilters()}
        </div>
    );
};

export default Filters;