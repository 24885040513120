import React from 'react';
import SubHeader from "../../Header/SubHeader";
import {Card, Col, Row} from "react-bootstrap";
import {mainCardBodyStyle, marketsCardHeaderStyle, marketsCardStyle} from "../../../styles/contentStyles";
import NftMarket from "./NftMarket";
import TokenomicGraph from "./TokenomicGraph";

const Dashboard = () => {
    return (
        <div>
            <SubHeader/>
            <Row>
                <Col xxl={3} xl={2} lg={1}></Col>
                <Col xxl={6} xl={8} lg={10}>
                    <Card style={{...marketsCardStyle, minHeight: 200, height: 450}}>
                        <Card.Header style={marketsCardHeaderStyle}>
                            <Row>
                                <Col xl={4}>PAWDAO NFT</Col>
                            </Row>
                        </Card.Header>
                        <Card.Body style={mainCardBodyStyle}>
                            <Row>
                                <Col lg={1}></Col>
                                <Col style={{height: 300}}>
                                    <NftMarket/>
                                </Col>
                                <Col lg={1}></Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xxl={3} xl={2} lg={1}></Col>
            </Row>
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<Row>*/}
            {/*    <Col xxl={3} xl={2} lg={1}></Col>*/}
            {/*    <Col xxl={6} xl={8} lg={10}>*/}
            {/*        <Card style={{...marketsCardStyle, minHeight: 200, height: 450}}>*/}
            {/*            <Card.Header style={marketsCardHeaderStyle}>*/}
            {/*                <Row>*/}
            {/*                    <Col xl={4}>Tokenomic</Col>*/}
            {/*                </Row>*/}
            {/*            </Card.Header>*/}
            {/*            <Card.Body style={mainCardBodyStyle}>*/}
            {/*                <Row>*/}
            {/*                    <Col lg={1}></Col>*/}
            {/*                    <Col style={{height: 300}}>*/}
            {/*                        <TokenomicGraph/>*/}
            {/*                    </Col>*/}
            {/*                    <Col lg={1}></Col>*/}
            {/*                </Row>*/}
            {/*            </Card.Body>*/}
            {/*        </Card>*/}
            {/*    </Col>*/}
            {/*    <Col xxl={3} xl={2} lg={1}></Col>*/}
            {/*</Row>*/}
        </div>
    );
};

export default Dashboard;